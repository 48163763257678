import {DemoStep} from './demoStep';
import {DemoSet} from './demoSet';

export class DemoExperience {
    id: string;
    labels: {[lang: string]: string};
    preGameSteps: {[lang: string]: DemoStep[]};
    demoSets: DemoSet[];

    constructor(id: string, labels: { [p: string]: string }, preGameSteps: {[p: string]: DemoStep[]}, demoSets: DemoSet[]) {
        this.id = id;
        this.labels = labels;
        this.preGameSteps = preGameSteps;
        this.demoSets = demoSets;
    }
}
