import {Injectable} from '@angular/core';
import { Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {DemoContent} from '../../models/demo/demoContent';
import CONTENT from './demoContentMock';
import {distinctUntilChanged, map, mergeMap, take} from 'rxjs/operators';
import {DemoCreationDescriptor} from '../../models/demo/dtos/demoCreationDescriptor';
import {unsubscribe} from '../../handler/subscription-handler';
import {OrganizationService} from '../organization/organization.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {DemoMetadata} from '../../models/demo/demoMetadata';
import {DemoContext} from '../../models/demo/demoContext';
import {DemoState} from '../../models/demo/demoState';
import {DemoGameDescriptor} from '../../models/demo/dtos/demoGameDescriptor';
import {DemoStep} from '../../models/demo/demoStep';
import {DemoSummary} from '../../models/demo/demoSummary';
import {DemoExperience} from '../../models/demo/demoExperience';
import {Database, object, ref} from '@angular/fire/database';

@Injectable({
    providedIn: 'root'
})
export class DemoService {

    private organization: string;
    private hasCurrentDemo: boolean;
    private demoMetadataSubscription: Subscription;
    private demoStateSubject: Subject<DemoState> = new ReplaySubject(1);
    private demoStateSnapshot: DemoState;
    private demoMetadataSubject: Subject<DemoMetadata> = new ReplaySubject(1);
    private demoMetadataSnapshot: DemoMetadata;
    private demoStateSubscription: Subscription;

    constructor(private organizationService: OrganizationService,
                private http: HttpClient,
                private database: Database,
                private errorHandler: ErrorHandler) {
        this.organizationService.organization().subscribe(o => {
            this.organization = o?.organization;
            this.hasCurrentDemo = o?.getBlindingHasCurrentGame();
            unsubscribe(this.demoMetadataSubscription, this.demoStateSubscription);
            this.demoMetadataSubscription = object(ref(this.database, `/frog-manager/demos/${this.organization}/demo/metadata`))
                .subscribe(sn => {
                    const demo = sn.snapshot.val();
                    this.demoMetadataSnapshot = demo;
                    this.demoMetadataSubject.next(demo);
                });
            this.demoStateSubscription = object(ref(this.database, `/frog-manager/demos/${this.organization}/demo/state`))
                .subscribe(sn => {
                    const demo = sn.snapshot.val();
                    this.demoStateSnapshot = demo;
                    this.demoStateSubject.next(demo);
                });
        });
    }

    getAvailableDemoContentSingle(): Observable<DemoContent> {
        return of(CONTENT).pipe(take(1));
    }

    currentDemoMetadata(): Observable<DemoMetadata> {
        return this.demoMetadataSubject.pipe(distinctUntilChanged());
    }

    activateDemo(descriptor: DemoCreationDescriptor): Observable<void> {
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo`, descriptor)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    deactivateDemo() {
        return this.http.delete<void>(`manager://manager/org/${this.organization}/demo`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    getDemoExperience(id: string): Observable<DemoExperience> {
        return of(CONTENT)
            .pipe(map(dc => dc.experiences.find(e => e.id === id)))
            .pipe(take(1));
    }

    currentDemoContext(): Observable<DemoContext> {
        return this.currentDemoMetadata()
            .pipe(mergeMap(d => {
                return d ? of(CONTENT).pipe(map(dc => new DemoContext(d, dc.experiences.find(e => e.id === d.experienceId)))) : of(null);
            }));
    }

    currentDemoState(): Observable<DemoState> {
        return this.demoStateSubject.pipe(distinctUntilChanged());
    }

    goToNextStep(): Observable<void> {
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/steps/next`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    activateGame(descriptor: DemoGameDescriptor): Observable<void> {
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/game/launch`, descriptor)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    getLatest(): Observable<DemoSummary[]> {
        return this.http.get<DemoSummary[]>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/demo/latest`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    currentDemoSetStep(): Observable<DemoStep> {
        return this.currentDemoMetadata()
            .pipe(mergeMap(dm => {
                return this.currentDemoState().pipe(map(ds => {
                    return {ds, dm};
                }));
            }))
            .pipe(mergeMap((demo) => {
                return this.getAvailableDemoContentSingle().pipe(map(dc => {
                    if (!demo.dm || !demo.ds) {
                        return null;
                    }
                    const xp = dc.experiences.find(e => e.id === demo.dm.experienceId);
                    if (demo.ds.currentStep < xp.preGameSteps[demo.dm.lang].length) {
                        return xp.preGameSteps[demo.dm.lang][demo.ds.currentStep];
                    }
                    return xp.demoSets.find(set => set.tag === demo.ds.demoSetTag)
                        .steps[demo.ds.currentStep - xp.preGameSteps[demo.dm.lang].length];
                }));
            }));
    }

    getDemoSummary(demoSummaryId: string): Observable<DemoSummary> {
        return this.http.get<DemoSummary>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/demo/${demoSummaryId}`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }
}
