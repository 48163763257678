import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {connectDatabaseEmulator, getDatabase, provideDatabase} from '@angular/fire/database';
import 'hammerjs';

import {environment} from '../../environments/environment';
import {AppComponent} from './app.component';

import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';

import {SetupModule} from './pages/setup/setup.module';
import {AppRoutingModule} from './app-routing.module';
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from '@angular/material/core';
import {HomeModule} from './pages/home/home.module';
import {DemoModule} from './pages/demo/demo.module';
import {MarkdownModule} from 'ngx-markdown';
import {DashboardModule} from './pages/dashboard/dashboard.module';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {connectStorageEmulator, getStorage, provideStorage} from '@angular/fire/storage';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';

registerLocaleData(localeFr, 'fr');

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        CoreModule,
        MarkdownModule.forRoot(),
        BrowserAnimationsModule,
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            const auth = getAuth();
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
            }
            return auth;
        }),
        provideDatabase(() => {
            const database = getDatabase();
            if (environment.useEmulators) {
                connectDatabaseEmulator(database, 'localhost', 9000);
            }
            return database;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
        }),
        AppRoutingModule,
        HomeModule,
        DemoModule,
        SetupModule,
        DashboardModule,
    ],
    providers: [
        {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
