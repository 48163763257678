import {Injectable} from '@angular/core';
import {from, Observable, ReplaySubject, Subject} from 'rxjs';
import {filter, take, timeout} from 'rxjs/operators';
import {
    MSG_EXT_DOWNLOAD_MEDIA_ASYNC,
    MSG_EXT_LOAD_MEDIA_ASYNC,
    MSG_EXT_PING_SHOWTIME,
    MSG_PAGE_MEDIA_CONTENT_LOADED, MSG_PAGE_MEDIA_DOWNLOADED
} from './messaging/messages';
import {MediaContentLoaded} from './messaging/MediaContentLoaded';
import {GameService} from '../../../../core/services/game/game.service';
import {LoggerService} from '../../../../core/services/logger/logger.service';
import {BlobContentConfig} from '../../models/BlobContentConfig';
import {environment} from '../../../../../../environments/environment';
import {BlobMediaLoading} from '../../models/BlobMediaLoading';
import {MediaDownloaded} from './messaging/MediaDownloaded';
import {BlobMediaDownloading} from '../../models/BlobMediaDownloading';

const SHOWTIME_MEDIA_LOADER_EVENT_ID = 'showtime-media-loader';

@Injectable({
    providedIn: 'root'
})
export class MediaLoaderService {

    private mediaContentLoadedSubject: Subject<MediaContentLoaded> = new ReplaySubject(1);
    private mediaDownloadedSubject: Subject<MediaDownloaded> = new ReplaySubject(1);
    private l;
    constructor(private gameService: GameService,
                private logger: LoggerService) {
        if (this.l) {
            window.removeEventListener(SHOWTIME_MEDIA_LOADER_EVENT_ID, this.l);
        }
        this.l = (evt) => {
            // @ts-ignore
            const msg: Message = evt.detail;
            this.logger.debug(`[page] Received Message [${msg.type}]`, msg.payload);
            switch (msg.type) {
                case MSG_PAGE_MEDIA_CONTENT_LOADED:
                    this.mediaContentLoadedSubject.next(msg.payload);
                    break;
                case MSG_PAGE_MEDIA_DOWNLOADED:
                    this.mediaDownloadedSubject.next(msg.payload);
                    break;
            }
        };
        window.addEventListener(SHOWTIME_MEDIA_LOADER_EVENT_ID, this.l);
    }

    checkShowtimePresent(): Observable<boolean> {
        return from(this.tryToReachExtension(5)).pipe(timeout(10000));
    }

    private onMediaContentLoaded(): Observable<MediaContentLoaded> {
        return this.mediaContentLoadedSubject;
    }

    private onMediaDownloaded(): Observable<MediaDownloaded> {
        return this.mediaDownloadedSubject;
    }

    downloadMedia(config: BlobContentConfig): Observable<BlobMediaDownloading> {
        const resultSubject = new ReplaySubject<BlobMediaDownloading>(1);
        const result = new BlobMediaDownloading();
        from(this.downloadMediaAsync(config, 4)).subscribe();
        config.blobs.forEach(b => {
            result.state[b.key] = {loaded: false, payload: null};
            this.onMediaDownloaded()
                .pipe(filter(m => m.key === b.key))
                .pipe(take(1))
                .subscribe(r => {
                    result.state[r.key] = {loaded: true, payload: r};
                    resultSubject.next(Object.assign(new BlobMediaDownloading, result));
                });
        });
        resultSubject.next(Object.assign(new BlobMediaDownloading, result));
        return resultSubject;
    }

    loadMedia(config: BlobContentConfig): Observable<BlobMediaLoading> {
        const resultSubject = new ReplaySubject<BlobMediaLoading>(1);
        const result = new BlobMediaLoading();
        from(this.loadMediaAsync(config, 4)).subscribe();
        config.blobs.forEach(b => {
            result.state[b.key] = {loaded: false, payload: null};
            this.onMediaContentLoaded()
                .pipe(filter(m => m.key === b.key))
                .pipe(take(1))
                .subscribe(r => {
                    result.state[r.key] = {loaded: true, payload: r};
                    resultSubject.next(Object.assign(new BlobMediaLoading, result));
                });
        });
        resultSubject.next(Object.assign(new BlobMediaLoading, result));
        return resultSubject;
    }

    private tryToReachExtension(maxRetries: number): Promise<boolean> {
        return this.reachExtensionOnce()
            .then(() => {
                return true;
            })
            .catch(() => {
                if (maxRetries > 0) {
                    return new Promise(
                        resolve => setTimeout(() => resolve(true), (500)))
                        .then(() => this.tryToReachExtension(maxRetries - 1));
                } else {
                    throw Error('cannot.reach.extension');
                }
            });
    }

    private reachExtensionOnce(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                // @ts-ignore
                chrome.runtime.sendMessage(environment.showtime.extensionId, {
                    type: MSG_EXT_PING_SHOWTIME
                }, (response) => {
                    if (response && response === 'pong') {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                });
            } catch (e) {
                reject(false);
            }
        });

    }

    private loadMediaAsync(showtimeConfig: BlobContentConfig, maxRetries: number): Promise<boolean> {
        return this.loadMediaAsyncOnce(showtimeConfig)
            .then(() => {
                return true;
            })
            .catch(() => {
                if (maxRetries > 0) {
                    return new Promise(resolve => setTimeout(() => resolve(true), (900)))
                        .then(() => this.loadMediaAsync(showtimeConfig, maxRetries - 1));
                } else {
                    throw Error('cannot.reach.extension');
                }
            });
    }

    private loadMediaAsyncOnce(mediaToLoad: BlobContentConfig): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                // @ts-ignore
                chrome.runtime.sendMessage(environment.showtime.extensionId, {
                    type: MSG_EXT_LOAD_MEDIA_ASYNC,
                    payload: mediaToLoad
                }, (response) => {
                    if (!!response) {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                });
            } catch (e) {
                reject(false);
            }
        });
    }

    private downloadMediaAsync(showtimeConfig: BlobContentConfig, maxRetries: number): Promise<boolean> {
        return this.downloadMediaAsyncOnce(showtimeConfig)
            .then(() => {
                return true;
            })
            .catch(() => {
                if (maxRetries > 0) {
                    return new Promise(resolve => setTimeout(() => resolve(true), (900)))
                        .then(() => this.downloadMediaAsync(showtimeConfig, maxRetries - 1));
                } else {
                    throw Error('cannot.reach.extension');
                }
            });
    }

    private downloadMediaAsyncOnce(mediaToLoad: BlobContentConfig): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                // @ts-ignore
                chrome.runtime.sendMessage(environment.showtime.extensionId, {
                    type: MSG_EXT_DOWNLOAD_MEDIA_ASYNC,
                    payload: mediaToLoad
                }, (response) => {
                    if (!!response) {
                        resolve(true);
                    } else {
                        reject(false);
                    }
                });
            } catch (e) {
                reject(false);
            }
        });
    }
}
