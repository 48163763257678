import {Component, Input, OnInit} from '@angular/core';
import {Player, SongResult} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-player-mention-widget',
  templateUrl: './player-mention-widget.component.html',
  styleUrls: ['./player-mention-widget.component.scss']
})
export class PlayerMentionWidgetComponent implements OnInit {
  @Input()
  player: Player;
  @Input()
  result: SongResult;
  @Input()
  mention: string;

  constructor() { }

  ngOnInit(): void {
  }

  isCorrect() {
    return this.result?.result === 2;
  }

  isPartiallyCorrect() {
    return this.result?.result === 1;
  }

  isIncorrect() {
    return this.result?.result === 0;
  }


}
