import {Experience} from './experience';

export class Catalog {
    id: string;
    label: string;
    description: string;
    experiences: Experience[];


    constructor(id?: string, label?: string, description?: string, experiences?: Experience[]) {
        this.id = id;
        this.label = label;
        this.description = description;
        this.experiences = experiences;
    }

    static copy(c: Catalog): Catalog {
        return new Catalog(c.id, c.label, c.description, c.experiences.map(e => Experience.copy(e)));
    }

    clone(): Catalog {
        return new Catalog(this.id, this.label, this.description, this.experiences);
    }
}
