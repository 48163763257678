<div class="container dark">
    <div class="left">
        <div class="header-containe">
            <h2>{{ds.company | titlecase}}</h2>
        </div>
        <div class="experience">
            {{experienceName}}
        </div>
    </div>
    <div class="center">
        <div>
            {{demoLang}}
            <br/>
            {{demoSetTag}}
            <br/>
            {{demoTime}}
        </div>

    </div>
    <div class="actions hide-mobile">
        <button mat-button (click)="onCreateOpportunity()">Créer opportunité</button>
    </div>
</div>
