import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetText} from '../../../../../core/models/demo/widgets/widgetText';
import {DemoContext} from '../../../../../core/models/demo/demoContext';

@Component({
    selector: 'app-widget-text',
    templateUrl: './widget-text.component.html',
    styleUrls: ['./widget-text.component.scss']
})
export class WidgetTextComponent implements OnInit {

    @Input()
    ctx: DemoContext;
    @Input()
    widget: WidgetText;
    @Input()
    hidden = false;
    @Output()
    onToggleHide = new EventEmitter<void>();

    constructor() {
    }

    ngOnInit(): void {
    }

    toggle() {
        this.onToggleHide.next();
    }
}
