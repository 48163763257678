import {DEFAULT_CURRENCY_CODE, NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {AuthorizedUserGuard} from '../../core/auth/service/guards/authorized-user-guard.service';
import {MaterialModule} from '../../core/material/material.module';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OrganizationGuard} from '../../core/services/organization/guards/organization.guard';
import {DashboardComponent} from './dashboard.component';
import {UnauthorizedComponent} from './unauthorized/unauthorized.component';
import {DemoSummaryContainerComponent} from './demo-summary-container/demo-summary-container.component';
import {DemoSummaryWidgetComponent} from './demo-summary-container/demo-summary-widget/demo-summary-widget.component';
import {MatTableModule} from '@angular/material/table';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { OpportunityPipelineComponent } from './opportunity-pipeline/opportunity-pipeline.component';
import {OpportunityCreationComponent} from './opportunity-creation/opportunity-creation.component';
import { OpportunityQuoteFormComponent } from './opportunity-creation/opportunity-quote-form/opportunity-quote-form.component';
import { OpportunityQuoteDetailsComponent } from './opportunity-creation/opportunity-quote-details/opportunity-quote-details.component';
import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';
import { OpportunityCardComponent } from './opportunity-pipeline/opportunity-card/opportunity-card.component';
import {OpportunityEditionComponent} from './opportunity-edition/opportunity-edition.component';
import {OpportunityHeaderComponent} from './opportunity-edition/opportunity-header/opportunity-header.component';
import {OpportunityMainComponent} from './opportunity-edition/opportunity-main/opportunity-main.component';
import {QuoteEditorComponent} from './quote-editor/quote-editor.component';
import {QuoteHeaderComponent} from './quote-editor/quote-header/quote-header.component';
import {QuoteFormComponent} from './quote-editor/quote-form/quote-form.component';
import {QuoteDetailsComponent} from './quote-editor/quote-details/quote-details.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { OpportunityCardWidgetConfirmedComponent } from './opportunity-pipeline/opportunity-card/opportunity-card-widget-confirmed/opportunity-card-widget-confirmed.component';
import { OpportunityCardWidgetDraftNNewComponent } from './opportunity-pipeline/opportunity-card/opportunity-card-widget-draft-n-new/opportunity-card-widget-draft-n-new.component';
import { OpportunityCardWidgetCreatedInCrmComponent } from './opportunity-pipeline/opportunity-card/opportunity-card-widget-created-in-crm/opportunity-card-widget-created-in-crm.component';
import {OpportunityCardWidgetCanceledComponent} from './opportunity-pipeline/opportunity-card/opportunity-card-widget-canceled/opportunity-card-widget-canceled.component';
import { OpportunityEditDateDialogComponent } from './opportunity-edit-date-dialog/opportunity-edit-date-dialog.component';

const routes = [
    {
        path: 'org/:organization/dashboard',
        component: DashboardComponent,
        canActivate: [OrganizationGuard, AuthorizedUserGuard]
    },
    {
        path: 'org/:organization/dashboard/opportunity/new',
        component: OpportunityCreationComponent,
        canActivate: [OrganizationGuard, AuthorizedUserGuard],
    },
    {
        path: 'org/:organization/dashboard/opportunity/edit',
        component: OpportunityEditionComponent,
        canActivate: [OrganizationGuard, AuthorizedUserGuard],
    },
    {
        path: 'org/:organization/dashboard/quote/edit',
        component: QuoteEditorComponent,
        canActivate: [OrganizationGuard, AuthorizedUserGuard],
    },
    {
        path: 'org/:organization/unauthorized',
        component: UnauthorizedComponent,
        canActivate: [OrganizationGuard]
    },
];

@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        CoreModule,
        DragDropModule,
        RouterModule.forChild(routes),
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatTooltipModule,
    ],
    declarations: [
        DashboardComponent,
        UnauthorizedComponent,
        DemoSummaryContainerComponent,
        OpportunityPipelineComponent,
        DemoSummaryWidgetComponent,
        OpportunityCreationComponent,
        OpportunityQuoteFormComponent,
        OpportunityQuoteDetailsComponent,
        OpportunityCardComponent,
        OpportunityEditionComponent,
        OpportunityHeaderComponent,
        OpportunityMainComponent,
        QuoteEditorComponent,
        QuoteHeaderComponent,
        QuoteFormComponent,
        QuoteDetailsComponent,
        OpportunityCardWidgetConfirmedComponent,
        OpportunityCardWidgetDraftNNewComponent,
        OpportunityCardWidgetCreatedInCrmComponent,
        OpportunityCardWidgetCanceledComponent,
        OpportunityEditDateDialogComponent
        ],
    providers: [
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'}
    ]
})
export class DashboardModule {
}
