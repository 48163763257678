import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from "rxjs";
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private _navigationSubject: ReplaySubject<string>;

  constructor(private titleService: Title) {
    this._navigationSubject = new ReplaySubject<string>(1);
  }

  updateNavHeader(name: string) {
    this._navigationSubject.next(name);
    this.titleService.setTitle(`Espace Partenaire - ${name}`);
  }

  navHeader(): Observable<string> {
    return this._navigationSubject;
  }


  updateNavHeaderPrefix(name: string) {
    this._navigationSubject.next(name);
    this.titleService.setTitle(`${name} - Espace Partenaire`);
  }
}
