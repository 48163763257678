import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PlayerService} from '../../../../../core/services/player/player.service';
import {ReviewService} from '../../../../../core/services/review/review.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../../core/handler/subscription-handler';

@Component({
  selector: 'app-showtime-overlay-playing',
  templateUrl: './showtime-overlay-playing.component.html',
  styleUrls: ['./showtime-overlay-playing.component.scss']
})
export class ShowtimeOverlayPlayingComponent implements OnInit, OnDestroy {

  @Input()
  logoUrl: string;
  @Input()
  lang: string;
  playerCount = 1;
  submissionCount = 0;
  private playerCountSubscription: Subscription;
  private submissionCountSubscription: Subscription;

  constructor(private playerService: PlayerService, private reviewService: ReviewService) { }

  ngOnInit(): void {
    this.playerCountSubscription = this.playerService.playerCount().subscribe(pc => {
      this.playerCount = pc;
    });
    this.submissionCountSubscription = this.reviewService.songSubmissionCount().subscribe(submissionCount => {
      this.submissionCount = submissionCount;
    });
  }

  ngOnDestroy(): void {
    unsubscribe(this.playerCountSubscription, this.submissionCountSubscription);
  }

  get label() {
    if (this.lang === 'fr') {
      return 'Réponses';
    }
    return 'Answers';
  }

}
