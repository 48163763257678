import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { FirebaseAuthService } from '@frogconnexion/angular-auth';

import {Subscription} from 'rxjs';
import {OrganizationService} from '../../services/organization/organization.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  error: any;
  private _authenticated: boolean;
  private _currentUserSubscription: Subscription;

  constructor(private _authService: FirebaseAuthService,
              private _blindingService: OrganizationService,
              private _router: Router) {
  }

  get isLogged(): boolean {
    return this._authenticated;
  }

  ngOnInit() {
    this._currentUserSubscription = this._authService.currentUserObservable()
      .subscribe(user => {
        this._authenticated = user && !user.anonymous;
        if (this._authenticated) {
          this._router.navigate([`/org/${this._blindingService.getOrganizationTagSnapshot()}/dashboard`]);
        }
      });
  }

  ngOnDestroy() {
    if (this._currentUserSubscription) {
      this._currentUserSubscription.unsubscribe();
    }
  }

  loginFb() {
    const org = this._blindingService.getOrganizationTagSnapshot();

    this._authService.facebookSignIn()
      .then(success => {
        this._router.navigate([`/org/${org}/dashboard`]);
      })
      .catch(err => {
        this.error = err;
      });

  }

  loginGoogle() {
    const org = this._blindingService.getOrganizationTagSnapshot();
    this._authService.googleSignIn()
      .then(success => {
        this._router.navigate([`/org/${org}/dashboard`]);
      })
      .catch(err => {
        this.error = err;
      });
  }

}
