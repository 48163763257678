import {Component, Input, OnInit} from '@angular/core';
import {ServiceLeaderboardEntry} from '../../../../../../../core/models/leaderboard/serviceleaderboardentry';

@Component({
    selector: 'app-service-entry',
    templateUrl: './service-widget.component.html',
    styleUrls: ['./service-widget.component.scss']
})
export class ServiceWidgetComponent implements OnInit {

    @Input() entry: ServiceLeaderboardEntry;
    @Input() ranking: number;

    constructor() {
    }

    ngOnInit() {
    }

}
