import {DemoStepWidget} from './demoStepWidget';
import {DemoStepAction} from './demoStepAction';


export class DemoStep {
    index: number;
    widgets: DemoStepWidget[];
    actions: DemoStepAction[];
    actionLabel: string;

    constructor(index: number, widgets: DemoStepWidget[], actions: DemoStepAction[], actionLabel: string) {
        this.index = index;
        this.widgets = widgets;
        this.actions = actions;
        this.actionLabel = actionLabel;
    }
}
