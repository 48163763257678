import {Constraint} from './constraint';
import {ConstraintType} from './constraintType';

export class ConstraintIntlChecked implements Constraint {
    type = ConstraintType.INTL_CHECKED;
    value: boolean;

    constructor(checked: boolean) {
        this.value = checked;
    }

    matches(state: boolean): boolean {
        return state === this.value;
    }
}
