import {Component, Input, OnInit} from '@angular/core';
import {DemoSummary} from '../../../core/models/demo/demoSummary';
import {DemoService} from '../../../core/services/demo/demo.service';

@Component({
  selector: 'app-demo-summary-container',
  templateUrl: './demo-summary-container.component.html',
  styleUrls: ['./demo-summary-container.component.scss']
})
export class DemoSummaryContainerComponent implements OnInit {

  demoSummaries: DemoSummary[];
  @Input()
  demoOngoing = false;

  constructor(private demoService: DemoService) { }

  ngOnInit(): void {
    this.demoService.getLatest().subscribe(ds => {
      this.demoSummaries = ds;
    });
  }

}
