import {Pricing} from '../../models/opportunity/pricing';
import {PricingType} from '../../models/opportunity/pricingType';

export class ScaledPricing implements Pricing {
    type = PricingType.SCALED;
    basePrice: number;
    scaleThreshold: number;
    scaleUnit: number;
    scalePrice: number;

    compute(paxCount: number) {
        if (paxCount <= this.scaleThreshold) {
            return this.basePrice;
        }
        const paxBeyondThreshold = Math.max(0, paxCount - this.scaleThreshold);
        return this.basePrice + (1 + Math.floor(paxBeyondThreshold / this.scaleUnit)) * this.scalePrice;
    }

    constructor(basePrice: number, scaleThreshold: number, scaleUnit: number, scalePrice: number) {
        this.basePrice = basePrice;
        this.scaleThreshold = scaleThreshold;
        this.scaleUnit = scaleUnit;
        this.scalePrice = scalePrice;
    }
}
