<div class="container dark">
    <h1 class="dark">Animation</h1>
    <form [formGroup]="opportunityCreationForm" class="full-width">
        <mat-form-field appearance="fill" class="field-lg">
            <mat-select formControlName="experience" required>
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let xp of catalog?.experiences || []" [value]="xp.tag">{{xp.label}}</mat-option>
            </mat-select>
            <mat-error *ngIf="experienceFormControl.hasError('required')">
                Vous devez sélectionner une expérience
            </mat-error>
            <mat-hint>Quelle expérience souhaite le client ?*</mat-hint>
        </mat-form-field>
        <div class="clear-row"></div>
        <mat-form-field class="field-xs" appearance="fill">
            <input type="number" matInput [errorStateMatcher]="matcher" formControlName="paxCount">
            <mat-hint>Nb de pax*</mat-hint>
            <mat-error *ngIf="paxCountFormControl.hasError('required') && !opportunityCreationForm.hasError('min')">
                Doit être positif
            </mat-error>
        </mat-form-field>
        <div class="mat-form-field custom field-md">
            <mat-radio-group formControlName="lang" aria-label="Sélectionner une langue">
                <mat-radio-button value="fr">Français</mat-radio-button>
                <mat-radio-button value="en">Anglais</mat-radio-button>
            </mat-radio-group>
            <div class="mat-label">Langue de l'animation ?*</div>
        </div>
        <div class="clear-row"></div>
        <mat-form-field appearance="fill" class="full-width field-xl">
            <mat-label>Dispositif</mat-label>
            <mat-select formControlName="bundle" [errorStateMatcher]="matcher">
                <mat-option [value]="">---</mat-option>
                <mat-option *ngFor="let xp of bundles" [value]="xp.tag">{{xp.label}}</mat-option>
            </mat-select>
            <mat-error *ngIf="bundleFormControl.errors?.required">
                Vous devez sélectionner le dispositif
            </mat-error>
            <mat-hint>Quel dispositif pour la prestation ?*</mat-hint>
        </mat-form-field>
    </form>
</div>
