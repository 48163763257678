import {PricingType} from './pricingType';
import {FixedPricing} from './fixedPricing';
import {ScaledPricing} from '../../services/catalog/scaledPricing';

export interface Pricing {
    type: PricingType;
    compute(...any): number;
}



export function copyPricing(p: Pricing): Pricing {
    switch (p.type) {
        case PricingType.FIXED:
            return new FixedPricing((p as FixedPricing).price);
        case PricingType.SCALED:
            const sp = (p as ScaledPricing);
            return new ScaledPricing(sp.basePrice, sp.scaleThreshold, sp.scaleUnit, sp.scalePrice);
        default:
            throw new Error('Pricing Not implemented');
    }
}

export function computeItemPrice(context: {pax: number}, p: Pricing) {
    switch (p.type) {
        case PricingType.FIXED:
            return p.compute();
        case PricingType.SCALED:
            return p.compute(context.pax);
        default:
            throw new Error('Pricing Not implemented');
    }
}
