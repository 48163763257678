import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';


import {distinctUntilChanged, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {MediaPlayState} from '@frogconnexion/blinding-common';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {GameService} from '../../../core/services/game/game.service';
import {ErrorHandler} from '../../../core/handler/error-handler';
import {LoggerService} from '../../../core/services/logger/logger.service';
import {ShowtimeBlindingSettings} from '../models/ShowtimeBlindingSettings';
import {MediaSwitcherService} from './utils/media-switcher.service';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {ShowtimeSettings} from '@frogconnexion/blinding-common';
import {Database, object, ref} from '@angular/fire/database';

const SHOWTIME_KEY = 'showtime_settings';

@Injectable({
    providedIn: 'root'
})
export class ShowtimeService {
    private org: string;
    private showtimeSettingsSubscription: Subscription;
    private currentShowtimeSettings: ShowtimeSettings;
    private showtimeSettingsSubject: Subject<ShowtimeSettings>;
    private remoteMediaStateSubscription: Subscription;

    constructor(private database: Database,
                private organizationService: OrganizationService,
                private mediaSwitcherService: MediaSwitcherService,
                private gameService: GameService,
                private errorHandler: ErrorHandler,
                private logger: LoggerService,
                private http: HttpClient) {

        this.showtimeSettingsSubject = new ReplaySubject(1);

        this.organizationService.organizationTag().subscribe(o => {
            this.org = o;
            if (o) {
                unsubscribe(this.showtimeSettingsSubscription);
                this.showtimeSettingsSubscription = object(ref(this.database, `/blinding/showtime/${o}`))
                    .subscribe(sn => {
                        this.currentShowtimeSettings = sn.snapshot.val();
                        this.showtimeSettingsSubject.next(this.currentShowtimeSettings);
                    });
            }
        });
    }

    initShowtimeAutoSwitchingRules() {
        this.mediaStatus('feed').subscribe(s => {
            this.logger.debug('Video Feed next :' + s);
        });

        this.mediaStatus('countdown').subscribe(s => {
            this.logger.debug('Countdown next :' + s);
            if (s === MediaPlayState.END) {
                this.launchIntro().subscribe();
            }
        });

        this.mediaStatus('intro').subscribe(s => {
            this.logger.debug('Intro Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchFeed().subscribe();
            }
        });

        this.mediaStatus('connect').subscribe(s => {
            this.logger.debug('Connect Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchJoinTheGame().subscribe();
            }
        });

        this.mediaStatus('join-the-game').subscribe(s => {
            this.logger.debug('Join The Game Screen next :' + s);
        });

        this.mediaStatus('rules').subscribe(s => {
            this.logger.debug('Rules Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchFeed().subscribe();
            }
        });

        this.mediaStatus('jokers').subscribe(s => {
            this.logger.debug('Jokers Video next :' + s);
            if (s === MediaPlayState.END) {
                this.launchFeed().subscribe();
            }
        });
    }

    launchScreen(screen: string): Observable<boolean> {
        return this.http.post<boolean>(`manager://manager/org/${this.org}/demo/showtime/screen/${screen}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    launchFeed(): Observable<boolean> {
        return this.launchScreen('feed');
    }

    launchCountdown(): Observable<boolean> {
        return this.launchScreen('countdown');
    }

    launchIntro(): Observable<boolean> {
        return this.launchScreen('intro');
    }

    launchConnect(): Observable<boolean> {
        return this.launchScreen('connect');
    }

    launchRules(): Observable<boolean> {
        return this.launchScreen('rules');
    }

    launchJokers(): Observable<boolean> {
        return this.launchScreen('jokers');
    }

    launchPause(): Observable<boolean> {
        return this.launchScreen('pause');
    }

    launchEnding(): Observable<boolean> {
        return this.launchScreen('ending');
    }

    launchJoinTheGame(): Observable<boolean> {
        return this.launchScreen('join-the-game');
    }

    saveShowtimeSettings(settings: ShowtimeBlindingSettings) {
        localStorage.setItem(SHOWTIME_KEY, JSON.stringify(settings));
    }

    getShowtimeSettings(): ShowtimeBlindingSettings {
        return JSON.parse(localStorage.getItem(SHOWTIME_KEY));
    }

    mediaStatus(key: string): Subject<MediaPlayState> {
        return this.mediaSwitcherService.mediaStatus(key);
    }

    mediaStatusObservable(key: string): Observable<MediaPlayState> {
        return this.mediaSwitcherService.mediaStatusObservable(key);
    }

    mediastatusNot(key: string): Subject<MediaPlayState>[] {
        return this.mediaSwitcherService.mediaStatusNot(key);
    }

    showtime(): Observable<string> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.showtime), distinctUntilChanged());
    }

    currentlyOnScreen(): Observable<string> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.currentlyOnScreen), distinctUntilChanged());
    }

    listenToRemoteMediaStateChanges(): void {
        unsubscribe(this.remoteMediaStateSubscription);
        this.remoteMediaStateSubscription = this.remoteMediaState().subscribe(ms => {
            if (!this.currentShowtimeSettings) {
                return;
            }
            if (MediaPlayState.NONE === ms) {
                return;
            }
            this.mediaStatus(this.currentShowtimeSettings?.controls?.currentlyOnScreen).next(ms);
            this.changeRemoteMediaState(MediaPlayState.NONE).subscribe();
        });
    }

    private remoteMediaState(): Observable<MediaPlayState> {
        return this.showtimeSettingsSubject.pipe(map((ss) => ss?.controls?.mediaState), distinctUntilChanged());
    }

    changeRemoteMediaState(state: MediaPlayState): Observable<boolean> {
        return this.http.post<boolean>(`manager://manager/org/${this.org}/demo/showtime/media/state/${state}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

}
