<div class="container">
    <h1>Opportunité {{crmId}} - {{companyName}}</h1>
    <div class="main-container">
    </div>
</div>
<div class="container">
    <div class="actions">
        <button mat-raised-button class="saveButton" color="primary" (click)="onSubmit()" [disabled]="!otherFormsValid">Enregistrer</button>
        <button mat-raised-button color="accent" (click)="onReturn()">Retour</button>
    </div>
</div>
