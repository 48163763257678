import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {DemoContext} from '../../../../../core/models/demo/demoContext';
import {Subscription} from 'rxjs';
import {GameControls} from '@frogconnexion/blinding-common';
import {Song} from '@frogconnexion/blinding-common';
import {Blindtest} from '@frogconnexion/blinding-common';
import {SongPendingReview} from '@frogconnexion/blinding-common';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {OrganizationService} from '../../../../../core/services/organization/organization.service';
import {ShowtimeService} from '../../../screen/showtime.service';
import {BlindtestService} from '../../../../../core/services/blindtest/blindtest.service';
import {GameService} from '../../../../../core/services/game/game.service';
import {GameControlsService} from '../../../../../core/services/gamecontrols/gamecontrols.service';
import {GamePublicControlsService} from '../../../../../core/services/gamepubliccontrols/gamepubliccontrols.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ScoreService} from '../../../../../core/services/score/score.service';
import {ReviewService} from '../../../../../core/services/review/review.service';
import {MatDialog} from '@angular/material/dialog';
import {ValidationDialogComponent} from '../../../../../core/components/dialog/validation-dialog/validation-dialog.component';
import {unsubscribe} from '../../../../../core/handler/subscription-handler';
import {MatchResult} from '@frogconnexion/blinding-common';
import {PlayerService} from '../../../../../core/services/player/player.service';
import {SongSubmission} from '@frogconnexion/blinding-common';
import {WidgetQuizGameData} from '../../../../../core/models/demo/widgets/data/widgetQuizGameData';

@Component({
  selector: 'app-widget-quiz-game',
  templateUrl: './widget-quiz-game.component.html',
  styleUrls: ['./widget-quiz-game.component.scss']
})
export class WidgetQuizGameComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  ctx: DemoContext;
  @Input()
  widget: WidgetQuizGameComponent;
  @Output()
  changed = new EventEmitter<WidgetQuizGameData>();

  private areYouSureSubscription: Subscription;
  private currentGameMetadataSubscription: Subscription;
  private currentGameControlsSubscription: Subscription;
  private pendingReviewsSubscription: Subscription;
  private solvedPendingReviewsSubscription: Subscription;
  private submissionCountSubscription: Subscription;
  private reviewCountSubscription: Subscription;
  private playerCountSubscription: Subscription;
  private currentSongSubscription: Subscription;
  private submissionsSubscription: Subscription;
  private playersSubscription: Subscription;

  currentGameControls: GameControls;
  currentSong: Song;
  blindtest: Blindtest;
  pendingReviews: SongPendingReview[];
  submissionCount = 0;
  reviewCount = 0;
  playerCount = 0;
  solvedPendingReviews: SongPendingReview[];
  private currentGameMetadata: GameMetadata;
  private hasCurrentGame: boolean;
  submissionList: SongSubmission;
  private playerNames: Map<string, string> = new Map<string, string>();

  constructor(private blindingService: OrganizationService,
              private showtimeService: ShowtimeService,
              private blindtestService: BlindtestService,
              private gameService: GameService,
              private gameControlsService: GameControlsService,
              private gamePublicControlsService: GamePublicControlsService,
              private router: Router,
              private route: ActivatedRoute,
              private scoreService: ScoreService,
              private reviewService: ReviewService,
              private playerService: PlayerService,
              private dialog: MatDialog) {
  }

  lockSong() {
    if (!this.hasReviewedEverything) {
      const dialogRef = this.dialog.open(ValidationDialogComponent, {
        width: '300px',
        data: {subtitle: 'Vous n\'avez pas corrigé toutes les réponses. Voulez-vous quand même forcer le verrouillage ?'}
      });
      unsubscribe(this.areYouSureSubscription);
      this.areYouSureSubscription = dialogRef.afterClosed().subscribe(activate => {
        if (activate) {
          this.gameControlsService.lockSong().subscribe(() => {
            this.changed.next({ locked: true, revealed: false});
          });
        }
      });
    } else {
      this.gameControlsService.lockSong().subscribe(() => {
        this.changed.next({ locked: true, revealed: false});
      });
    }
  }

  forceReviewSong(pendingReview: SongPendingReview) {
    pendingReview.review = pendingReview.review.map(v => {
      if (v === MatchResult.Unsolved) {
        return MatchResult.Incorrect;
      }
      return v;
    });
    this.reviewService.solvePendingReview(pendingReview).subscribe();
  }

  revealSong() {
    this.gameControlsService.revealSong().subscribe(() => {
      this.changed.next({ locked: true, revealed: true});
    });
  }

  shouldShowControls(): boolean {
    return this.currentGameControls.setControl.isRunning();
  }

  shouldDisableLock(): boolean {
    return this.currentGameControls.songControl.locked || !this.hasReviewedEverything;
  }

  shouldDisableReveal(): boolean {
    return (!this.currentGameControls.songControl.locked && !this.hasReviewedEverything)
        || !this.currentGameControls.songControl.locked || this.currentGameControls.songControl.revealed;
  }

  get hasReviewedEverything() {
    return this.getUnsolvedPendingReviewCount() === 0;
  }

  getUnsolvedPendingReviewCount() {
    return this.pendingReviews?.map(pr => pr.key)
        .filter(prId => this.solvedPendingReviews?.map(pr => pr.key).indexOf(prId) === -1).length || 0;
  }

  ngOnDestroy() {
    unsubscribe(
        this.areYouSureSubscription,
        this.currentGameMetadataSubscription,
        this.currentGameControlsSubscription,
        this.pendingReviewsSubscription,
        this.solvedPendingReviewsSubscription,
        this.submissionCountSubscription,
        this.playerCountSubscription,
        this.currentSongSubscription,
        this.reviewCountSubscription,
        this.playersSubscription,
        this.submissionsSubscription);
  }

  ngOnChanges() {
    if (!this.currentSong) {
      return;
    }
    unsubscribe(this.pendingReviewsSubscription, this.solvedPendingReviewsSubscription);
    this.pendingReviewsSubscription = this.reviewService.currentPendingReviewsState().subscribe((reviews) => {
      this.pendingReviews = reviews ? reviews : [];
    });
    this.solvedPendingReviewsSubscription = this.reviewService.currentSolvedPendingReviewsState().subscribe((reviews) => {
      this.solvedPendingReviews = reviews ? reviews : [];
    });
  }

  hasBeenReviewed(key: string) {
    return this.solvedPendingReviews?.filter(s => s.key === key).length > 0;
  }

  updateHasCurrentGame() {
    this.hasCurrentGame = !!(this.currentGameMetadata && this.currentGameControls && this.blindtest);
    this.changed.next({ locked: this.currentGameControls?.songControl?.locked, revealed: this.currentGameControls?.songControl?.revealed});
  }

  get remainingItemsToReview(): number {
    return this.submissionCount - this.reviewCount;
  }

  ngOnInit() {
    this.currentGameControlsSubscription = this.gameService.currentGameControls()
        .subscribe(ct => {
          this.currentGameControls = ct;
          this.updateHasCurrentGame();
        });
    this.playerCountSubscription = this.playerService.playerCount().subscribe(pc => {
      this.playerCount = pc;
    });
    this.submissionCountSubscription = this.reviewService.songSubmissionCount().subscribe(submissionCount => {
      this.submissionCount = submissionCount;
    });
    this.reviewCountSubscription = this.reviewService.songReviewsCount().subscribe(reviewCount => {
      this.reviewCount = reviewCount;
    });
    this.pendingReviewsSubscription = this.reviewService.currentPendingReviewsState().subscribe(pendingReviews => {
      this.pendingReviews = pendingReviews;
    });
    this.solvedPendingReviewsSubscription = this.reviewService.currentSolvedPendingReviewsState().subscribe(pendingReviews => {
      this.solvedPendingReviews = pendingReviews;
    });
    this.currentSongSubscription = this.gameService.currentSong().subscribe(cs => {
      this.currentSong = cs;
    });

    this.submissionsSubscription = this.reviewService.getSubmissions().subscribe(sub => {
      this.submissionList = sub;
    });

    this.playersSubscription = this.playerService.players().subscribe(players => {
      this.playerNames = new Map(players.map(p => [p.id, p.name]));
    });
  }

  shouldShowStats() {
    return this.currentGameControls?.songControl?.locked && !this.currentGameControls.songControl.revealed;
  }

  isRevealed() {
    return this.currentGameControls?.songControl?.revealed;
  }

  getPlayerName(id: string): string {
    return (this.playerNames.get(id).charAt(0).toUpperCase() + this.playerNames.get(id).slice(1)) || 'N/A';
  }
}
