<div class="container">
    <div class="meta-container">
        <p>{{bt.description}}</p>
    </div>
    <div class="set-container" *ngFor="let set of bt.sets; let i = index;">
        <div class="set-songcount">{{getSongCount(set)}} éléments</div>
        <hr/>
        <div class="set-content">
            <div *ngFor="let section of set.sections">
                <app-blindtest-content-section [section]="section"></app-blindtest-content-section>
            </div>
        </div>
        <br/><br/>
    </div>
</div>
