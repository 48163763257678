import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';

/**
 * Attention a bien respecter la hiérarchie : sets [ { sections: [ {... songs: [{...}] } ] } ]
 */
function getDefaultAsset() {
    return {
        'name': '18-30 - Salon normal',
        'description': null,
        'tags': ['salon-18-30'],
        'sets': [{
            'sections': [{
                'type': 'NORMAL',
                'label': 'J\'assume, mais j\'assume pas',
                'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                'songs': [{
                    'title': 'Djadja',
                    'artist': 'Aya Nakamura',
                    'lyrics': null,
                    'type': 'NORMAL',
                    'alt': {
                        'artist': ['Aya', 'Aia nakamoura'],
                        'title': ['Oh djadja', 'Oh jaja', 'Oh Djah djah', 'O djah djah', 'Djah Djah'],
                        'lyrics': []
                    },
                    'ageRange': {'start': 25, 'end': 55}
                }]
            }, {
                'type': 'LYRICS',
                'label': 'Dessins animés',
                'altLabels': {'title': null, 'artist': null, 'lyrics': 'Nom du dessin animé'},
                'songs': [{
                    'title': 'Sponge Bob',
                    'artist': 'Sponge Bob',
                    'lyrics': 'Bob l\'éponge',
                    'type': 'LYRICS',
                    'alt': {'artist': [], 'title': [], 'lyrics': ['Sponge bob']},
                    'ageRange': {'start': 18, 'end': 35}
                }]
            }
            ]
        }]
    };
}


export function computeDemoSetSalon1830() {
    return new DemoSet('salon-18-30',
        'fr',
        'Salon (18-30 ans)',
        getDefaultAsset(),
        [
            new DemoStep(1,
                [
                    new WidgetText('Générique lancé. Step suivant : vidéo Morpheus.\n\nLe jeu est déjà activé.'),
                    new WidgetVideoControl()
                ],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/570915318.hd.mp4?s=26d60dcb32e7d6f751e78c94f94357036e8fc0a2&profile_id=175&download=1'),
                    new ActionNext()
                ],
                'Lancer la vidéo Morphéus'),
            new DemoStep(2,
                [
                    new WidgetText('Prochaine step : faire rejoindre la partie'),
                    new WidgetVideoControl(),
                ],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Faire rejoindre la partie'),
            new DemoStep(3,
                [new WidgetText('Prochaine step : chauffage des foules'),
                    new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/576212268.hd.mp4?s=fb5c2a7ceedc63ff1515741e09f0c5a98d92b0d4&profile_id=175&download=1', true),
                    new ActionNext()
                ],
                'Chauffer les foules'),
            new DemoStep(4,
                [new WidgetText(
                    'Next : section "J\'assume, mais j\'assume pas!"'
                ),
                    new WidgetVideoControl(),
                    new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576244626.hd.mp4?s=dc7063e87ffe0ed8ec654f27f989f1059438ec9f&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Lancer la Partie'),
            new DemoStep(5,
                [new WidgetText(
                    'Chanteuse française très connue, une poête au vocabulaire parfois difficile à comprendre...*\n\n' +
                    'Prochaine section : dessins animés\r\n\r\n' +
                    'Les Jokers seront activés !'
                    ,
                ),
                    new WidgetVideoControl(),
                    new WidgetQuizGame(),
                ],
                [
                    new ActionEnableJokers(),
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576244688.hd.mp4?s=8794f119314b707d17574cc070c1ccfa4bde080d&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Suivant'),
            new DemoStep(6,
                [new WidgetText(
                    '*C\'est un dessin animé assez connu des français, il vit dans les fonds marins, est tout jaune, et plutôt carré en forme... Je dirais même qu\'il est un peu... spongieux... *\n\n' +
                    ''
                ),
                    new WidgetVideoControl(),
                    new WidgetQuizGame(),
                ],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Montrer les scores'),
            new DemoStep(7,
                [new WidgetText(
                    'Pitch \n\n [saucer le gagnant !]\n' +
                    'Next step : La musique de l\'accessoire...'
                ),
                    new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576212374.hd.mp4?s=9a893fe855570e7d9567465796face61b5003f57&profile_id=175&download=1'),
                    new ActionNext()
                ],
                'Voir un exemple d\'animation'),
            new DemoStep(8,
                [
                    new WidgetText(
                        'Expliquer les défis en présentiel aussi'
                    ),
                    new WidgetVideoControl(),
                ],
                [
                    new ActionTerminateDemo()
                ],
                'Terminer la démo')
        ]
    );
}
