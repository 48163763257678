import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizedUserGuard} from '../../core/auth/service/guards/authorized-user-guard.service';
import {MaterialModule} from '../../core/material/material.module';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {SetupComponent} from './setup.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OrganizationGuard} from '../../core/services/organization/guards/organization.guard';

const routes = [
  {path: 'org/:organization/setup', component: SetupComponent, canActivate: [OrganizationGuard, AuthorizedUserGuard]},
];


@NgModule({
  imports: [
    MaterialModule,
    CommonModule,
    CoreModule,
    DragDropModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    SetupComponent
  ]
})
export class SetupModule {
}
