<h1>Fixer la date</h1>
<form [formGroup]="opportunityCreationForm" class="full-width container">
<mat-form-field appearance="fill" class="field-sm">
    <input matInput [matDatepicker]="picker1" formControlName="date">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
    <mat-hint>Date prestation</mat-hint>
</mat-form-field>
<div class="time">
    <mat-form-field appearance="fill" class="field-time">
        <mat-select formControlName="hour">
            <mat-option *ngFor="let h of hours()" [value]="h">
                {{h}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <span class="hour">h</span>
    <mat-form-field appearance="fill" class="field-time">
        <mat-select formControlName="minute">
            <mat-option *ngFor="let m of minutes()" [value]="m">
                {{m}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <div class="time-footer">Heure prestation</div>
</div>
</form>
<div class="warn">Attention, l'heure de la prestation doit être au fuseau horaire de Paris (CET)</div>
<div class="actions">
    <button mat-raised-button color="accent" (click)="onCancel()">Annuler</button>
    <button mat-raised-button class="saveButton" color="primary" (click)="onSubmit()" [disabled]="!opportunityCreationForm.valid">Enregistrer</button>
</div>
