<hr/>
<mat-list-item *ngIf="entry" class="item-container">
    <div class="ranking">{{ranking + 1}}</div>
    <div class="metadata">
        <div class="player-name">
            <h4 class="player">{{entry.name}}</h4>
        </div>
    </div>
    <div class="player-count">{{entry.playerCount}}</div>
    <div class="score">{{entry.score | number}}</div>
</mat-list-item>

<mat-list-item *ngIf="!entry">
    <h4 matLine class="player">--</h4>
</mat-list-item>
