import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {GameControls} from '@frogconnexion/blinding-common';
import {Chart, LinearScale, CategoryScale, BarController, BarElement } from 'chart.js';
import {NumbersOnTopPlugin} from './chart-js-numbers-on-top';

@Component({
    selector: 'app-showtime-overlay-locked',
    templateUrl: './showtime-overlay-locked.component.html',
    styleUrls: ['./showtime-overlay-locked.component.scss']
})
export class ShowtimeOverlayLockedComponent implements AfterViewInit, OnChanges {

    @Input()
    gc: GameControls;
    @Input()
    lang: string;
    @ViewChild('stats')
    statsRef: ElementRef<HTMLCanvasElement>;
    @ViewChild('container')
    containerRef: ElementRef<HTMLDivElement>;
    private chart: Chart;
    loading = true;


    constructor() {
        Chart.register(LinearScale, CategoryScale, BarController, BarElement, new NumbersOnTopPlugin());
        setTimeout(() => {
            this.loading = false;
        }, 2500);
    }

    ngAfterViewInit(): void {
        this.loadStats();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.gc?.currentValue !== changes.gc?.previousValue) {
            this.loadStats();
        }
    }

    private displayChart() {
        if (!this.statsRef?.nativeElement) {
            return;
        }
        const width = window.screen.width;
        let fontSize = -1;
        let legendFontSize = -1;
        let paddingTop = -1;
        if (width > 1900) {
            fontSize = 24;
            paddingTop = 50;
            legendFontSize = 22;
        } else if (width > 1500) {
            fontSize = 24;
            paddingTop = 50;
            legendFontSize = 17;
        } else if (width > 1300) {
            fontSize = 14;
            paddingTop = 40;
            legendFontSize = 14.5;
        } else {
            fontSize = 12;
            paddingTop = 35;
            legendFontSize = 12;
        }

        const responseRatio = this.stats.responsePercent / 100;
        if (this.chart) {
            this.chart.destroy();
        }
        let labels = ['Unanswered', 'All Good', 'Part. Good', 'Incorrect'];
        if (this.lang === 'fr') {
            labels = ['Abstenu', 'Tout Bon', 'En partie Bon', 'Tout faux'];
        }
        // @ts-ignore
        this.chart = new Chart(this.statsRef.nativeElement, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    type: 'bar',
                    label: '%',
                    data: [
                        Math.floor(100 - this.stats.responsePercent),
                        Math.floor(this.stats.successPercent * responseRatio),
                        Math.floor(this.stats.partialSuccessPercent * responseRatio),
                        Math.floor(this.stats.failurePercent * responseRatio)],
                    backgroundColor: [
                        'rgba(255, 206, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(255, 99, 132, 0.8)',
                    ],
                    borderColor: [
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255,99,132,1)',
                    ],
                    borderWidth: 8
                }]
            },
            options: {
                layout: {
                    padding: {
                        top: paddingTop
                    }
                },
                scales: {
                    gridLines: {
                        display : false
                    },
                    grid: {
                        display: false,
                    },
                    y: {
                        ticks: {
                            color: 'rgba(255, 255, 255, 0.4)'
                        },
                        grid: {
                            color: 'rgba(255, 255, 255, 0.2)'
                        },
                        beginAtZero: true
                    },
                    x: {
                        ticks: {
                            color: 'rgba(255, 255, 255, 0.9)',
                            font: {
                                family: 'Roboto, \'Helvetica Neue\', sans-serif',
                                size: legendFontSize,
                                style: 'normal',
                                weight: 'bold'
                            }
                        },
                        grid: {
                            color: 'rgba(255, 255, 255, 0.1)'
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        enabled: false
                    },
                    legend: {
                        display: false,
                        labels: {
                            // This more specific font property overrides the global property
                            color: 'white',
                            font: {
                                family: 'Roboto, \'Helvetica Neue\', sans-serif',
                                size: 16,
                                style: 'normal'
                            }
                        }
                    }
                }

            }
        });
    }

    get computingStats() {
        if (!this.gc) {
            return true;
        }
        return this.gc.songControl.computingStats;
    }

    get stats() {
        if (!this.gc) {
            return null;
        }
        return this.gc.songControl.stats;
    }

    private loadStats() {
        if (!this.gc) {
            return;
        }
        if (!this.computingStats && this.stats) {
            this.displayChart();
        }
    }

}
