import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Catalog} from '../../../core/models/opportunity/catalog';
import {CatalogService} from '../../../core/services/catalog/catalog.service';
import {NavigationService} from '../../../core/services/navigation/navigation.service';
import {QuoteDto} from '../../../core/models/opportunity/quoteDto';
import {ActivatedRoute, Router} from '@angular/router';
import {DemoService} from '../../../core/services/demo/demo.service';
import {DemoSummary} from '../../../core/models/demo/demoSummary';
import {take} from 'rxjs/operators';
import {OpportunityService} from '../../../core/services/opportunity/opportunity.service';
import {ToastrService} from 'ngx-toastr';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {Opportunity} from '../../../core/models/opportunity/opportunity';
import {Quote} from '../../../core/models/opportunity/quote';
import {Subscription} from 'rxjs';
import {OpportunityPanelUpdate} from './opportunity-main/opportunityPanelUpdate';
import {OpportunityEditionDescriptor} from '../../../core/models/opportunity/opportunityEditionDescriptor';
import {OpportunityHeaderUpdate} from './opportunity-header/opportunityHeaderUpdate';
import {Organization, User} from '@frogconnexion/core-common';
import {FirebaseAuthService, unsubscribe} from '@frogconnexion/angular-auth';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-opportunity-edition',
    templateUrl: './opportunity-edition.component.html',
    styleUrls: ['./opportunity-edition.component.scss']
})
export class OpportunityEditionComponent implements OnInit, AfterViewInit, OnDestroy {

    catalog: Catalog;
    currentQuote: QuoteDto;
    ds: DemoSummary;
    opportunity: Opportunity;
    quote: Quote;
    user: User;
    private subscriptions: Subscription[] = [];
    comment: string;
    organization: Organization;

    constructor(private catalogService: CatalogService,
                private demoService: DemoService,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService,
                private router: Router,
                private authService: FirebaseAuthService,
                private organizationService: OrganizationService,
                private opportunityService: OpportunityService,
                private toastr: ToastrService) {
        this.navigationService.updateNavHeader('Edition d\'opportunité');

        this.subscriptions.push(this.organizationService.organization().subscribe(o => this.organization = o));

        this.activatedRoute.queryParamMap.pipe(take(1)).subscribe(qp => {
            if (qp.has('opportunityId')) {
                this.opportunityService
                    .find(qp.get('opportunityId'))
                    .subscribe((op) => {
                        this.opportunity = op;
                        this.comment = op.comment;
                    });
            }
        });
    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.authService.currentUserObservable().subscribe(u => {
            this.user = u;
        }));
    }

    ngAfterViewInit(): void {

    }

    onQuoteUpdated(opu: OpportunityPanelUpdate) {
        this.opportunity.exonerated = opu.exonerated;
    }

    saveOpportunity(ohd: OpportunityHeaderUpdate) {
        const o = new OpportunityEditionDescriptor(
            this.opportunity.id,
            ohd.company,
            ohd.crmId,
            ohd.crmIdent,
            ohd.isFromCrm,
            this.opportunity.exonerated,
            ohd.dateIso,
            ohd.hour,
            ohd.minute,
            this.comment
        );
        this.opportunityService.edit(o).subscribe(() => {
            this.toastr.success('Opportunité créée avec succès');
            this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
        });

    }
}
