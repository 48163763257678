import {ChangeDetectorRef, Component, ErrorHandler, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ScoreDataSource} from '../../../../../core/services/score/score.datasource';
import {Subscription} from 'rxjs';
import {PlayerService} from '../../../../../core/services/player/player.service';
import {ScoreService} from '../../../../../core/services/score/score.service';
import {unsubscribe} from '../../../../../core/handler/subscription-handler';
import {GameService} from '../../../../../core/services/game/game.service';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {ServiceScoreDataSource} from '../../../../../core/services/score/service-score.datasource';

@Component({
  selector: 'app-showtime-overlay-leaderboard',
  templateUrl: './showtime-overlay-leaderboard.component.html',
  styleUrls: ['./showtime-overlay-leaderboard.component.scss']
})
export class ShowtimeOverlayLeaderboardComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  lang: string;
  @Input()
  gm: GameMetadata;
  playerCount = 0;
  ds: ScoreDataSource;
  servicesDs: ServiceScoreDataSource;

  private _playerCountSubscription: Subscription;


  constructor(private _playerService: PlayerService,
              private _gameService: GameService,
              private _scoreService: ScoreService,
              private _errorHandler: ErrorHandler,
              private _changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.ds = new ScoreDataSource(10, this._scoreService, this._errorHandler);
    this._playerCountSubscription = this._playerService.playerCount().subscribe((playerCount) => {
      this.playerCount = playerCount;
      this._changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    unsubscribe(this._playerCountSubscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gm && this.gm.servicesEnabled) {
      this.servicesDs = new ServiceScoreDataSource(10, this._scoreService, this._errorHandler);
    }
    this.ds = new ScoreDataSource(10, this._scoreService, this._errorHandler);
  }


}
