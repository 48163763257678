import {Component, Input, OnInit} from '@angular/core';
import {Quote} from '../../../../../core/models/opportunity/quote';

@Component({
  selector: 'app-opportunity-card-widget-confirmed',
  templateUrl: './opportunity-card-widget-confirmed.component.html',
  styleUrls: ['./opportunity-card-widget-confirmed.component.scss']
})
export class OpportunityCardWidgetConfirmedComponent implements OnInit {

  @Input()
  q: Quote;
  @Input()
  xpName: string;
  @Input()
  bundleName: string;
  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }


  toggleExpand() {
    this.expanded = !this.expanded;
  }

}
