import {Quote} from './quote';

export class QuoteNOpportunity {
    opportunityId: string;
    quote: Quote;


    constructor(opportunityId: string, quote: Quote) {
        this.opportunityId = opportunityId;
        this.quote = quote;
    }
}
