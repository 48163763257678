import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Bundle} from '../../../../core/models/opportunity/bundle';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Opportunity} from '../../../../core/models/opportunity/opportunity';
import {OpportunityService} from '../../../../core/services/opportunity/opportunity.service';
import {ToastrService} from 'ngx-toastr';
import {Status} from '../../../../core/models/opportunity/status';
import {CatalogService} from '../../../../core/services/catalog/catalog.service';
import {Catalog} from '../../../../core/models/opportunity/catalog';
import {Quote} from '../../../../core/models/opportunity/quote';
import {QuoteNOpportunity} from '../../../../core/models/opportunity/quoteNOpportunity';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {ValidationDialogComponent} from '../../../../core/components/dialog/validation-dialog/validation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {OpportunityEditDateDialogComponent} from '../../opportunity-edit-date-dialog/opportunity-edit-date-dialog.component';
import {OrganizationService} from '../../../../core/services/organization/organization.service';
import {Claims, RoleClaim, User} from '@frogconnexion/core-common';


@Component({
    selector: 'app-opportunity-card',
    templateUrl: './opportunity-card.component.html',
    styleUrls: ['./opportunity-card.component.scss']
})
export class OpportunityCardComponent implements OnInit {

    @Input()
    bundles: Bundle[] = [];

    @Input()
    op: Opportunity;

    @Input()
    user: User;

    @Input()
    loading = false;

    @Output()
    delete = new EventEmitter<Opportunity>();

    @Output()
    lose = new EventEmitter<Opportunity>();

    @Output()
    duplicate = new EventEmitter<Opportunity>();

    @Output()
    edit = new EventEmitter<Opportunity>();

    @Output()
    editDate = new EventEmitter<{id: string, dateOfShow: string, timeOfShow: string, confirmAfter: boolean}>();

    @Output()
    confirm = new EventEmitter<QuoteNOpportunity>();

    @Output()
    quoteDelete = new EventEmitter<QuoteNOpportunity>();

    @Output()
    quoteDuplicate = new EventEmitter<QuoteNOpportunity>();

    @Output()
    quoteEdit = new EventEmitter<QuoteNOpportunity>();
    private dialogSubscription: Subscription;

    catalog: Catalog;
    expanded = {};

    constructor(private catalogService: CatalogService,
                private dialog: MatDialog,
                private organizationService: OrganizationService,
                private opportunityService: OpportunityService, private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.catalogService.getCatalog().subscribe(c => this.catalog = c);
    }

    updateBrief(event: MatCheckboxChange) {
        this.op.briefingDone = event.checked;
        this.opportunityService.update(this.op).subscribe(() => {
            this.toastr.success('Opportunité mise à jour');
        });
    }

    isConfirmed() {
        return this.op?.status === Status.CONFIRMED;
    }

    getExperienceName(tag: string): string {
        return this.catalog?.experiences?.find(e => e.tag === tag)?.label || tag;
    }

    getBundleName(xpTag: string, tag: string): string {
        return this.catalog?.experiences?.find(e => e.tag === xpTag)?.bundles?.find(b => b.tag === tag).label || tag;
    }

    duplicateOpportunity(op: Opportunity) {
        this.duplicate.next(op);
    }

    editOpportunity(op: Opportunity) {
        this.edit.next(op);
    }

    deleteOpportunity(op: Opportunity) {
        unsubscribe(this.dialogSubscription);
        const dialogRef = this.dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {subtitle: 'Cette opération supprimera définitivement cette opportunité. Voulez-vous continuer ?'}
        });
        this.dialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                this.delete.next(op);
            }
        });
    }

    getTime() {
        const t = this.op.time?.split(':');
        return t ? t[0] + 'h' + t[1] : '';
    }

    isDraft(): boolean {
        return this.op?.status === Status.DRAFT;
    }


    isNew() {
        return this.op?.status === Status.NEW;
    }

    canMove() {
        return this.op?.status === Status.CREATED_IN_CRM;
    }


    validateDraft() {
        this.opportunityService.validateDraft(this.op.id).subscribe(() => {
            this.toastr.success('Demande envoyée avec succès');
            this.op.status = Status.NEW;
        });
    }

    isCreatedInCrm(): boolean {
        return this.op?.status === Status.CREATED_IN_CRM;
    }

    canEdit() {
        return this.op?.status === Status.DRAFT;
    }

    canEditDateOnly() {
        return this.op?.status === Status.NEW || this.op?.status === Status.CREATED_IN_CRM;
    }

    canCancel() {
        return this.op?.status === Status.CREATED_IN_CRM;
    }

    duplicateQuote(q: Quote) {
        this.quoteDuplicate.next(new QuoteNOpportunity(this.op.id, q));
    }

    editQuote(q: Quote) {
        this.quoteEdit.next(new QuoteNOpportunity(this.op.id, q));
    }


    confirmOpportunity(q: Quote, force?: boolean) {
        unsubscribe(this.dialogSubscription);
        if (this.dateAlreadySet() || force) {
            const dialogRef = this.dialog.open(ValidationDialogComponent, {
                width: '300px',
                data: {subtitle: 'En confirmant ce devis, vous acceptez que la prestation ait lieu. Confirmez vous la prestation ?'}
            });
            this.dialogSubscription = dialogRef.afterClosed().subscribe(activate => {
                if (activate) {
                    this.confirm.next(new QuoteNOpportunity(this.op.id, q));
                }
            });
        } else {
            this.editDateDialog(() => this.confirmOpportunity(q, true));
        }

    }

    deleteQuote(q: Quote) {
        unsubscribe(this.dialogSubscription);
        const dialogRef = this.dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {subtitle: 'Cette opération supprimera définitivement ce devis. Voulez-vous continuer ?'}
        });
        this.dialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                this.quoteDelete.next(new QuoteNOpportunity(this.op.id, q));
            }
        });
    }

    backToDraft(op: Opportunity) {
        this.opportunityService.setToDraft(this.op.id).subscribe(() => {
            this.toastr.success('Votre opportunité est à nouveau en brouillon');
            this.op.status = Status.DRAFT;
        });
    }


    loseOpportunity() {
        unsubscribe(this.dialogSubscription);
        const dialogRef = this.dialog.open(ValidationDialogComponent, {
            width: '300px',
            data: {subtitle: 'En annulant cette opportunité, les demandes de staffing seront libérées et l\'opération est irréversible. Voulez-vous continuer ?'}
        });
        this.dialogSubscription = dialogRef.afterClosed().subscribe(activate => {
            if (activate) {
                this.lose.next(this.op);
            }
        });
    }

    isCanceled() {
        return this.op?.status === Status.CANCELED;
    }

    dateAlreadySet() {
        return this.op?.date?.length > 0 && this.op.time?.length > 0;
    }

    editDateDialog(next?: () => void) {
        unsubscribe(this.dialogSubscription);
        const dialogRef = this.dialog.open(OpportunityEditDateDialogComponent, {
            width: '340px',
            data: {
                dateSet: this.op.date,
                timeSet: this.op.time,
                canEdit: this.isAdminOrManager()
            }
        });
        this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
            if (result) {
                result.id = this.op.id;
                this.editDate.next(result);
                if (next) {
                    next();
                }
            }
        });
    }

    isAdminOrManager(): boolean {
        return this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_MANAGER, this.organizationService.getOrganizationTagSnapshot())]);
    }
}
