import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs';

@Injectable()
export class AvatarService {

  AVAILABLE_AVATARS: string[] = [
    'amy',
    'dj',
    'jazz',
    'm',
    'gaga',
    'oasis',
    'brel',
    'drug',
    'johnny',
    'madonna',
    'ray',
    'celine',
    'gims',
    'keys',
    'michael',
    'solo',
    'coldplay',
    'hendrix',
    'kiss',
    'muse',
    'spice-girls'
  ];

  constructor() {
  }

  getAvatars(): Observable<string[]> {
    return of(this.AVAILABLE_AVATARS);
  }

  getAvatarUri(avatar: string): string {
    return `/assets/imgs/${avatar}.jpg`;
  }

  randomAvatarUri(): string {
    return this.getAvatarUri(this.AVAILABLE_AVATARS[Math.floor(Math.random() * this.AVAILABLE_AVATARS.length)]);
  }

}
