import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';
import {
    getJokerAdvice, getLeaderboardAdvice,
    getPitchEndGoogleTranslate,
    getPitchGagnant,
    getPitchInscription,
    getPitchTakima,
    getSongAnswerAdvice
} from './fr-takima-18-30';

function getDefaultAsset() {
    return {
        'name': '18-30 - Set 4',
        'description': null,
        'tags': ['takima-forum-set-4-18-30'],
        'sets': [{
            'sections': [
                {
                    'type': 'NORMAL',
                    'label': 'Grands classiques',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [
                        {
                            'title': 'I Gotta Feeling',
                            'artist': 'The Black Eyed Peas',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': ['Black eyed peas'], 'title': ['i got a feeling'], 'lyrics': []},
                            'ageRange': {'start': 25, 'end': 40}
                        },
                        {
                            'title': 'Rolling In The Deep',
                            'artist': 'Adele',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': [], 'title': [], 'lyrics': []},
                            'ageRange': {'start': 25, 'end': 40}
                        },
                        {
                            'title': 'Highway to hell',
                            'artist': 'ACDC',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': ['AC/DC', 'aycidici'], 'title': ['I way to hell', 'High way 2 hell'], 'lyrics': []},
                            'ageRange': {'start': 18, 'end': 65}
                        }
                    ]
                }, {
                    'type': 'LYRICS',
                    'label': 'Petit et grand écran',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': 'Nom'},
                    'songs': [{
                        'title': 'Pokemon Theme',
                        'artist': 'Pokemon Theme',
                        'lyrics': 'Pokemon',
                        'type': 'LYRICS',
                        'alt': {'artist': [], 'title': [], 'lyrics': ['The Pocket Monsters']},
                        'ageRange': {'start': 18, 'end': 55}
                    },
                        {
                            'title': 'Eye Of The Tiger',
                            'artist': 'Survivor',
                            'lyrics': 'Rocky',
                            'type': 'LYRICS',
                            'alt': {'artist': [], 'title': [], 'lyrics': ['Rocky3']},
                            'ageRange': {'start': 25, 'end': 60}
                        }
                    ]
                },
            ]
        }]
    };
}

function getPitchStartGrandsClassiques() {
    return 'On attaque avec la section "Grands classiques !", on parle de chansons anthologiques qui n\'ont pas de valeur temporelle.\n\n' +
        'Bien penser à répondre dans l\'appli directement, tout le monde est prêt ? C\'est parti !\n' +
        '\n';
}

function getPitchBEP() {
    return getSongAnswerAdvice() +
        '*Un groupe ultra connu, *\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getPitchAdele() {
    return getSongAnswerAdvice() +
        '*Une artiste UK que tu ne peu pas ignorer. Elle a fait la BO de certains James Bond notamment !*\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getSectionPetitGrandEcran() {
    return getSongAnswerAdvice() +
        'On continue avec une section Petit et grand écran !\n\n' +
        'Ici, il faut simplement retrouver le titre du film ou du dessin animé';
}

function getPitchPokemon() {
    return getSongAnswerAdvice() +
        '*Série épique des années 90 avec des petits monstres...*\n\n' +
        '\n\nC\'est parti pour la suivante';
}

function getPitchRocky() {
    return getSongAnswerAdvice() +
        '*Là encore, Du gros classique !*\n\n';
}


function getPitchACDC() {
    return getSongAnswerAdvice() +
        '*Là on est sur un groupe d\'anthologie, australien, ils ont rythmé le rock des années 80-90 et ils ont sorti l\'an dernier un nouvel album ! *\n\n\n\n';
}

function getPitchBritney() {
    return '[Fin de la chanson]\n' +
        'L\'artiste pop américaine la plus connue des années 2000. Elle est notamment sortie avec Justin Timberlake, avait promis qu\'elle resterait vierge jusqu\'au mariage, et a fini par se raser la tête...' +
        getLeaderboardAdvice();

}

export function computeTakimaDemoSetForum4() {
    return new DemoSet('takima-forum-set-4-18-30',
        'fr',
        'Jeune (18-30 ans) Set 4',
        getDefaultAsset(),
        [
            new DemoStep(2,
                [new WidgetText(getPitchInscription()), new WidgetVideoControl()],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Faire rejoindre la partie'),
            new DemoStep(3,
                [new WidgetText(getPitchStartGrandsClassiques()), new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/685436226/rendition/720p/i_gotta_feeling%20%28720p%29.mp4?loc=external&signature=3fdbc9670280a1f1762c4d883a669fa084b765312d7de262147aae48ebaeb154', true),
                    new ActionNext()
                ],
                'Lancer la Partie'),
            new DemoStep(4,
                [new WidgetText(getPitchBEP()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/685439358/rendition/720p/rolling_in_the_deep%20%28720p%29.mp4?loc=external&signature=b381a72705e80d483995b036392b1d96f9b5a553a741e90cc66762d14f8b3b25', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(5,
                [new WidgetText(getPitchAdele()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576266878.hd.mp4?s=0e607d3426a761e8c9cb2678fa20f4b6d5886e8a&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(6,
                [new WidgetText(getPitchACDC()), new WidgetQuizGame(), new WidgetVideoControl()],
                [
                    new ActionNextItem(),
                    new ActionNext(),
                ],
                'Section suivante'),
            new DemoStep(7,
                [new WidgetText(getSectionPetitGrandEcran())],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/576268716.hd.mp4?s=5df7e0fa8939d88421f1e3d86b947d60750d0b98&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(8,
                [new WidgetText(getPitchPokemon()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/685437172/rendition/720p/eye_of_the_tiger%20%28720p%29.mp4?loc=external&signature=591d7b891dd14399d69cb667066199bb10c70cf0f67e8d5e34ad3b0100b2e5cc', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),

            new DemoStep(9,
                [new WidgetText(getPitchRocky()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Montrer les scores'),
            new DemoStep(10,
                [new WidgetText(getPitchGagnant()), new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionNext()
                ],
                'Closing'),
            new DemoStep(11,
                [new WidgetText(getPitchTakima())],
                [
                    new ActionTerminateDemo()
                ],
                'Terminer la démo'),
        ]
    );
}
