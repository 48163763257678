import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, tap} from 'rxjs/operators';
import {MediaPlayState} from '@frogconnexion/blinding-common';
import {OrganizationService} from '../../../../core/services/organization/organization.service';

@Injectable({
    providedIn: 'root'
})
export class MediaSwitcherService {
    private statusSubjects = new Map<string, Subject<MediaPlayState>>();
    private org: string;

    constructor(private organizationService: OrganizationService) {
        this.organizationService.organizationTag().subscribe(o => {
            this.org = o;
        });
    }

    mediaStatus(key: string): Subject<MediaPlayState> {
        if (!this.statusSubjects.has(key)) {
            const s = new ReplaySubject<MediaPlayState>(1);
            s.next(MediaPlayState.STOP_IMMEDIATELY);
            this.statusSubjects.set(key, s);
        }
        return this.statusSubjects.get(key);
    }

    mediaStatusObservable(key: string): Observable<MediaPlayState> {
        return this.mediaStatus(key).pipe(distinctUntilChanged());
    }

    mediaStatusNot(key: string): Subject<MediaPlayState>[] {
        return Array.from(this.statusSubjects.entries())
            .filter((e) => e[0] !== key)
            .map(e => e[1]);
    }


}
