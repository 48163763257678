import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {

  private idToken: String;
  private managerApiBaseUrl: string;

  constructor(private authService: FirebaseAuthService) {
    authService.currentIdToken().subscribe(idToken => {
      this.idToken = idToken;
    });
    this.managerApiBaseUrl = this.sanitizePrefix(environment.services.managerApiBaseUrl);
  }

  private sanitizePrefix(prefix: string): string {
    if (prefix.endsWith('/')) {
      return prefix.substr(0, prefix.length - 1);
    }
    return prefix;
  }

  private sanitizeSuffix(suffix: string): string {
    if (!suffix.startsWith('/')) {
      return '/' + suffix;
    }
    return suffix;
  }

  /**
   * Url can be of two things:
   * - Call to third-party API (http://xxx https://yyy)
   * - Call to internal backend API (/endpoint or endpoint)
   * @param url
   * @private
   */
  private computeUrl(url: string): string {
    if (url.startsWith('http')) {
      return url;
    }
    if (url.startsWith('goblindtest://')) {
      throw Error('goblindtest unsupported');
    }
    if (url.startsWith('manager://')) {
      return this.managerApiBaseUrl + '/' + this.stripProtocol(url);
    }
    return this.managerApiBaseUrl + this.sanitizeSuffix(url);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = req.headers;
    if (this.idToken) {
      headers = req.headers.set('Authorization', 'Bearer ' + this.idToken);
      headers = headers.set('X-Frog-Client', 'frog-partner');
    }
    return next.handle(req.clone({
      headers: headers,
      url: this.computeUrl(req.url)
    }));
  }

  private stripProtocol(url: string) {
    return url.substring(url.indexOf('://') + 3);
  }
}
