import {Subscription} from 'rxjs';

export function unsubscribe(...subs: Subscription[]) {
  if (!subs || subs.length === 0) {
    return;
  }
  subs.forEach(s => {
    if (s) {
      s.unsubscribe();
    }
  });
}
