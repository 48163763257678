import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Quote} from '../../../../../core/models/opportunity/quote';
import {Status} from '../../../../../core/models/opportunity/status';

@Component({
  selector: 'app-opportunity-card-widget-draft-n-new',
  templateUrl: './opportunity-card-widget-draft-n-new.component.html',
  styleUrls: ['./opportunity-card-widget-draft-n-new.component.scss']
})
export class OpportunityCardWidgetDraftNNewComponent implements OnInit {

  @Input()
  single: boolean;
  @Input()
  q: Quote;
  @Input()
  status: Status;
  @Input()
  index: number;
  @Input()
  isLast: boolean;
  @Input()
  xpName: string;
  @Input()
  bundleName: string;
  @Output()
  duplicate = new EventEmitter<Quote>();
  @Output()
  edit = new EventEmitter<Quote>();
  @Output()
  delete = new EventEmitter<Quote>();
  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  duplicateQuote(q: Quote) {
    this.duplicate.next(q);
  }

  editQuote(q: Quote) {
    this.edit.next(q);
  }

  deleteQuote(q: Quote) {
    this.delete.next(q);
  }

  canEdit() {
    return this.status === Status.DRAFT;
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }
}
