import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  title: string;
  subtitle: string;
}

@Component({
  selector: 'validation-dialog',
  templateUrl: 'validation-dialog.component.html'
})
export class ValidationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }
}
