<div class="container">
    <mat-card class="content">
        <form (ngSubmit)="onSubmit()" [formGroup]="setupForm" class="full-width">
            <mat-form-field class="full-width">
                <input formControlName="company" matInput placeholder="Nom du Client" [errorStateMatcher]="matcher"/>
                <mat-error *ngFor="let validation of validationMessages.company">
                    <mat-error class="error-message" *ngIf="company.hasError(validation.type)">
                        {{validation.message}}
                    </mat-error>
                </mat-error>
            </mat-form-field>
            <mat-label class="mat-label">Langue</mat-label>
            <mat-radio-group formControlName="lang" class="radio-group-row">
                <mat-radio-button class="radio-button" value="fr" checked>Fr</mat-radio-button>
                <mat-radio-button class="radio-button" value="en">En</mat-radio-button>
            </mat-radio-group>
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Expérience</mat-label>
                <select formControlName="experience" matNativeControl required>
                    <option label="----"></option>
                    <option *ngFor="let v of experiences" [value]="v.id">{{getLabel(v.labels)}}</option>
                </select>
                <mat-error *ngFor="let validation of validationMessages.experience">
                    <mat-error *ngIf="experience.hasError(validation.type)">
                        <mat-error class="error-message" *ngIf="validation.message === 'experience.required'">
                            Merci de choisir une expérience
                        </mat-error>
                    </mat-error>
                </mat-error>
                <mat-hint>De quelle expérience voulez-vous faire la démo ?</mat-hint>
            </mat-form-field>

        </form>
        <div class="custom-fields">
            <ng-container *ngIf="shouldShowGoBlindtestPin()">
                <mat-form-field class="full-width">
                    <input matInput class="pin" placeholder="Game PIN" [errorStateMatcher]="matcher" [(ngModel)]="pin" (ngModelChange)="checkPinAvailable(pin)"/>
                </mat-form-field>
                <div class="error-message">
                    <ng-container *ngIf="pinAlreadyTaken">Ce PIN est déjà pris</ng-container>
                </div>
            </ng-container>
        </div>
        <div class="centered">
            <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="!setupForm.valid || pinAlreadyTaken">Lancer la démo !
            </button>
        </div>
    </mat-card>
</div>

