import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Catalog} from '../../../../core/models/opportunity/catalog';
import {Bundle} from '../../../../core/models/opportunity/bundle';
import {Article} from '../../../../core/models/opportunity/article';
import {MyErrorStateMatcher} from '../opportunity-creation.component';
import {constraintMatch} from '../../../../core/models/opportunity/constraint';
import {QuoteDto} from '../../../../core/models/opportunity/quoteDto';
import {QuoteFormUpdate} from './quoteFormUpdate';
import {Quote} from '../../../../core/models/opportunity/quote';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {OrganizationService} from '../../../../core/services/organization/organization.service';
import {Claims, RoleClaim, User} from '@frogconnexion/core-common';

@Component({
    selector: 'app-opportunity-quote-form',
    templateUrl: './opportunity-quote-form.component.html',
    styleUrls: ['./opportunity-quote-form.component.scss']
})
export class OpportunityQuoteFormComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    opportunityCreationForm = new UntypedFormGroup({
        'paxCount': new UntypedFormControl('', {
            validators: [Validators.required, Validators.min(0)]
        }),
        'experience': new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
        'bundle': new UntypedFormControl('', [Validators.required, Validators.minLength(1)]),
        'date': new UntypedFormControl('', []),
        'hour': new UntypedFormControl('', []),
        'minute': new UntypedFormControl('', []),
        'lang': new UntypedFormControl('fr', [Validators.required])
    });

    @Input()
    catalog: Catalog;
    @Input()
    quote: QuoteDto;
    @Input()
    opQuote: Quote;
    @Input()
    editing: boolean;
    @Input()
    user: User;
    @Output()
    quoteUpdated = new EventEmitter<QuoteFormUpdate>();
    @Output()
    validityChanged = new EventEmitter<boolean>();
    bundles: Bundle[] = [];
    price = 0;
    options: Article[] = [];
    matcher = new MyErrorStateMatcher();
    private subscriptions: Subscription[] = [];

    constructor(private organizationService: OrganizationService) {

    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.opportunityCreationForm.statusChanges.subscribe(s => {
            this.validityChanged.next(this.opportunityCreationForm.valid);
        }));
    }

    ngAfterViewInit(): void {
        this.subscriptions.push(this.paxCountFormControl.valueChanges.subscribe((pc) => {
            const q = new QuoteFormUpdate(this.quote?.experienceTag,
                this.quote?.bundleTag, pc, this.quote?.lang);
            this.quoteUpdated.next(q);
        }));

        this.subscriptions.push(this.experienceFormControl.valueChanges.subscribe((e) => {
            const q = new QuoteFormUpdate(e,
                this.quote?.bundleTag, this.quote?.paxCount, this.quote?.lang);
            this.quoteUpdated.next(q);
        }));
        this.subscriptions.push(this.bundleFormControl.valueChanges.subscribe((bt) => {
            const q = new QuoteFormUpdate(this.quote?.experienceTag,
                bt, this.quote?.paxCount, this.quote?.lang);
            this.quoteUpdated.next(q);
        }));
        this.subscriptions.push(this.langFormControl.valueChanges.subscribe((l) => {
            const q = new QuoteFormUpdate(this.quote?.experienceTag,
                this.quote?.bundleTag, this.quote?.paxCount, l);
            this.quoteUpdated.next(q);
        }));
        setTimeout(() => this.validateForm(), 2000);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quote?.currentValue?.experienceTag && this.experienceFormControl.value === '') {
            this.experienceFormControl.setValue(this.quote.experienceTag || '');
        }

        if (changes?.quote?.currentValue?.bundleTag && this.bundleFormControl.value === '' && this.editing === true) {
            this.bundleFormControl.setValue(this.quote?.bundleTag || '');
            this.paxCountFormControl.setValue(this.quote?.paxCount || '');
        }

        if (this.langFormControl.value === '' && this.editing === true) {
            this.langFormControl.setValue(this?.opQuote?.lang);
        }
        this.updateBundles();
        if (changes?.quote?.currentValue?.bundleTag && !this.bundles.find(b => changes?.quote?.currentValue?.bundleTag === b.tag)) {
            setTimeout(() => {
                this.bundleFormControl.setValue('');
            }, 0);
        }
    }

    get paxCountFormControl() {
        return this.opportunityCreationForm.get('paxCount');
    }

    get experienceFormControl() {
        return this.opportunityCreationForm.get('experience');
    }

    get bundleFormControl() {
        return this.opportunityCreationForm.get('bundle');
    }

    get langFormControl() {
        return this.opportunityCreationForm.get('lang');
    }

    private updateBundles() {
        if (!this.experienceFormControl.value || this.experienceFormControl.value.length === 0) {
            this.bundles = [];
            return;
        }
        this.bundles = this.catalog?.experiences.find(e => e.tag === this.experienceFormControl.value)?.bundles
            .filter(b => constraintMatch({
                intl: this.langFormControl.value === 'en',
                pax: this.paxCountFormControl.value,
                ignorePaxWhenNull: !(this.paxCountFormControl.value > 0)
            }, ...b.constraints)) || [];
    }

    private validateForm() {
        const keys = Object.keys(this.opportunityCreationForm.controls);
        this.opportunityCreationForm.markAllAsTouched();
        for (const k of keys) {
            this.opportunityCreationForm.controls[k].markAsTouched();
            this.opportunityCreationForm.controls[k].updateValueAndValidity();
        }
    }


    isAdminOrManager(): boolean {
        return this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_MANAGER, this.organizationService.getOrganizationTagSnapshot())]);
    }

}
