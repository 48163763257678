import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {QuoteDto} from '../../../../core/models/opportunity/quoteDto';
import {Article} from '../../../../core/models/opportunity/article';
import {computeItemPrice} from '../../../../core/models/opportunity/pricing';
import {MatCheckboxChange} from '@angular/material/checkbox';

enum ArticleType {
    MANDATORY = 'mandatory',
    OPTIONAL = 'optional'
}

@Component({
    selector: 'app-quote-details',
    templateUrl: './quote-details.component.html',
    styleUrls: ['./quote-details.component.scss']
})
export class QuoteDetailsComponent implements OnInit, OnChanges {
    @Input()
    quote: QuoteDto;
    @Input()
    exonerated = false;
    @Output()
    optionChecked = new EventEmitter<{ ref: string, selected: boolean }[]>();

    allChecked = false;
    optional: { ref: string, selected: boolean }[];
    displayedColumns: string[] = ['selected', 'ref', 'description', 'price'];
    dataSource: { selected: boolean, type: ArticleType, ref: string, name: string, description: string, price: number }[] = [];
    totalPrice: number;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.quote.previousValue !== changes.quote.currentValue) {
            if (this.quote?.articles?.optional) {
                this.optional = [...this.quote.articles.optional.map(a => {
                    return {ref: a.article.ref, selected: a.selected};
                })];
                this.updateAllChecked();
            }
            this.updateDataSource();
        }
        this.computeTotalPrice();
    }

    updateAllChecked() {
        if (!(this.quote?.articles?.optional)) {
            return;
        }
        this.allChecked = this.optional.every(t => t.selected);
    }

    getArticle(ref: string): Article {
        return this.quote.articles.optional.find(a => a.article.ref === ref).article ||
            this.quote.articles.mandatory.find(a => a.ref === ref);
    }

    onCheckboxChanged(ref: string, checked: boolean) {
        this.optional.find(o => o.ref === ref).selected = checked;
        this.optionChecked.next(this.optional);
    }

    get someChecked(): boolean {
        return this.optional?.filter(t => t.selected)?.length > 0 && !this.allChecked;
    }

    checkAll(completed: boolean) {
        this.allChecked = completed;
        if (!(this.quote?.articles?.optional)) {
            return;
        }
        const r = [];
        this.quote.articles.optional.forEach(t => r.push({ref: t.article.ref, selected: completed}));
        this.optionChecked.next(r);
    }


    updateDataSource() {
        if (!(this.quote?.articles?.mandatory)) {
            this.dataSource = [];
            return;
        }
        this.dataSource = [...this.quote.articles.mandatory.map(a => {
            return {
                selected: true,
                type: ArticleType.MANDATORY,
                ref: a.ref,
                name: a.name,
                description: a.description,
                price: computeItemPrice({pax: this.quote.paxCount}, a.price)
            };
        }), ...(this.quote.articles.optional?.map(a => {
            return {
                selected: a.selected,
                type: ArticleType.OPTIONAL,
                ref: a.article.ref,
                name: a.article.name,
                description: a.article.description,
                price: computeItemPrice({pax: this.quote.paxCount}, a.article.price)
            };
        }) || [])];
    }

    computeTotalPrice() {
        const options =
            this.quote?.articles?.optional?.map(a => a.selected ? computeItemPrice({pax: this.quote.paxCount}, a.article.price) : 0)
            .reduce((previous, current) => previous + current, 0) || 0;
        const mandatory = this.quote?.articles?.mandatory?.map(a => computeItemPrice({pax: this.quote.paxCount}, a.price))
            .reduce((previous, current) => previous + current, 0) || 0;
        this.totalPrice = mandatory + options;
    }
}
