<div class="header">
    <img src='/assets/imgs/song-normal.png' class="section-type" alt='Section type' *ngIf="section.type === 0"/>
    <img src='/assets/imgs/song-lyrics.png' class="section-type" alt='Section type' *ngIf="section.type === 1"/>
    <div class="header-title"><h4>{{section.label}}</h4></div>

</div>
<div class="content">
    <div class="item" *ngFor="let song of section.songs">
        <app-blindtest-content-song [item]="song"></app-blindtest-content-song>
    </div>
</div>
