<div class="container dark">
    <div class="header-container">
        <h1>Vos dernières démos</h1>
        <button mat-raised-button color="accent" routerLink="../setup">Nouvelle Démo</button>
    </div>
    <div class="summaries-container">
        <div class="summary-container" *ngFor="let ds of demoSummaries">
            <app-demo-summary-widget class="item" [ds]="ds"></app-demo-summary-widget>
        </div>
    </div>
    <div class="overlay" *ngIf="demoOngoing">
        <h2>Une démo est en cours</h2>
        <button mat-raised-button color="accent" routerLink="../demo">Rejoindre la démo</button>
        <br/>
    </div>
</div>
