import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DemoContext} from '../../../../../core/models/demo/demoContext';
import {WidgetDemoSetPicker} from '../../../../../core/models/demo/widgets/widgetDemoSetPicker';
import {DemoSet} from '../../../../../core/models/demo/demoSet';
import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {WidgetDemoSetPickerData} from '../../../../../core/models/demo/widgets/data/widgetDemoSetPickerData';
import {BlindtestService} from '../../../../../core/services/blindtest/blindtest.service';
import {BlindtestCreationDescriptor, BlindtestImportDescriptor} from '@frogconnexion/blinding-common';
import {mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-widget-demo-set-picker',
    templateUrl: './widget-demo-set-picker.component.html',
    styleUrls: ['./widget-demo-set-picker.component.scss']
})
export class WidgetDemoSetPickerComponent implements OnInit {

    @Input()
    ctx: DemoContext;
    @Input()
    widget: WidgetDemoSetPicker;
    @Output()
    changed = new EventEmitter<WidgetDemoSetPickerData>();

    bt?: BlindtestImportDescriptor;

    validationMessages = {
        'demoSet': [
            {type: 'required', message: 'demoSet.required'}
        ]
    };

    demoSetPickerForm = new UntypedFormGroup({
        'demoSet': new UntypedFormControl('', [Validators.required])
    });

    matcher = new MyErrorStateMatcher();

    constructor(private blindtestService: BlindtestService) {
    }

    getDemoSets(): DemoSet[] {
        return this.ctx?.experience?.demoSets?.filter(ds => {
            return ds.lang === this.ctx.metadata.lang;
        });
    }

    get demoSet() {
        return this.demoSetPickerForm.get('demoSet');
    }

    ngOnInit(): void {
        this.demoSet.valueChanges.subscribe(tag => {
            if (!tag) {
                this.changed.next({experienceId: this.ctx.experience.id, demoSetTag: null, blindtestId: null});
                this.bt = null;
                return;
            }
            this.blindtestService.getWithTag(tag)
                .pipe(mergeMap(bt => {
                    if (!bt) {
                        return this.blindtestService
                            .importDefault(
                                this.ctx.experience.demoSets.find(ds => ds.lang === this.ctx.metadata.lang && ds.tag === tag).defaultAsset);
                    }
                    return of(bt);
                }))
                .subscribe(bt => {
                    this.bt = BlindtestImportDescriptor.fromBlindtest(bt);
                    this.changed.next({experienceId: this.ctx.experience.id, demoSetTag: tag, blindtestId: bt?.id});
                });
        });
    }

}
