<mat-card>
  <h3>--</h3>
  <div *ngIf='pendingReview.type === 0'>
    Artist: <b>{{pendingReview.answer[0] || 'N/A'}}</b>
    <br/>
    <mat-button-toggle-group [(ngModel)]="pendingReview.review[0]" aria-label="Artist review" [value]="pendingReview.review[0] === 2 ? 0 : pendingReview.review[0]">
      <mat-button-toggle [value]='0'>Nope</mat-button-toggle>
      <mat-button-toggle [value]='1'>Yep</mat-button-toggle>
    </mat-button-toggle-group>
    <br/><br/>
    Title: <b>{{pendingReview.answer[1] || 'N/A'}}</b>
    <br/>
    <mat-button-toggle-group [(ngModel)]="pendingReview.review[1]" aria-label="Title review" [value]="pendingReview.review[1] === 2 ? 0 : pendingReview.review[1]">
      <mat-button-toggle color="primary" [value]='0'>Nope</mat-button-toggle>
      <mat-button-toggle [value]='1'>Yep</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div *ngIf='pendingReview.type === 1'>
    Lyrics: <b>{{pendingReview.answer[0] || 'N/A'}}</b>
    <mat-button-toggle-group [(ngModel)]="pendingReview.review[0]" aria-label="Lyrics review" [value]="pendingReview.review[0] === 2 ? 0 : pendingReview.review[0]">
      <mat-button-toggle [value]='0' selected>Nope</mat-button-toggle>
      <mat-button-toggle [value]='1'>Yep</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <br/><br/>
  <button mat-raised-button color="primary" (click)='submitReview()'>Review</button>
</mat-card>
