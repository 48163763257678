import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

import {LoggerService} from '../../../services/logger/logger.service';
import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: FirebaseAuthService, private router: Router, private logger: LoggerService) {
  }

  canActivate(): Observable<boolean> {
    return this.authService.currentUserObservable().pipe(mergeMap(user => {
      if (!user || !user.authenticated) {
        this.logger.debug('Auth Guard Failed. Authenticating first.');
        this.router.navigate(['/']);
        return of(false);
      }
      this.logger.debug('Auth Guard Succeeded with user', user);
      return of(true);
    }));
  }
}
