import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {MediaPlayState} from '@frogconnexion/blinding-common';
import {ShowtimeService} from '../showtime.service';
import {BlobMediaLoading} from '../../models/BlobMediaLoading';
import {environment} from '../../../../../../environments/environment';
import {BlobMediaDownloading} from '../../models/BlobMediaDownloading';

@Component({
  selector: 'app-screen-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input()
  link: string;
  @Input()
  screen: string;
  @Input()
  lang: string;
  @Input()
  showtimeLoaded: boolean;
  @Input()
  loading: BlobMediaLoading | BlobMediaDownloading;
  @Input()
  interactionMade: boolean;

  private ended = false;
  private subscription: Subscription;
  private status: MediaPlayState;
  extensionId: string = environment.showtime.extensionId;

  get mediaLoadedCount(): number {
    return this.loading?.loadedCount() || 0;
  }

  get mediaTotalCount(): number {
    return this.loading?.totalCount() || 0;
  }

  constructor(private showtimeService: ShowtimeService) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resubscribe();
  }

  ngAfterViewInit(): void {
    this.resubscribe();
  }

  private resubscribe() {
    unsubscribe(this.subscription);
    this.subscription = this.showtimeService.mediaStatusObservable(this.screen)
        .subscribe(s => {
      this.status = s;
      if (s === MediaPlayState.START) {
        this.ended = false;
        this.showtimeService.mediaStatus(this.screen).next(MediaPlayState.PLAYING);
      } else if (s === MediaPlayState.STOP_IMMEDIATELY) {
        this.ended = true;
      }
    });
  }

}
