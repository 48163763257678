import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DemoContext} from '../../../../../../core/models/demo/demoContext';
import {ScoreService} from '../../../../../../core/services/score/score.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../../../core/handler/subscription-handler';
import {LeaderboardEntry} from '../../../../../../core/models/leaderboard/leaderboardentry';

@Component({
    selector: 'app-leaderboard',
    templateUrl: './leaderboard.component.html',
    styleUrls: ['./leaderboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LeaderboardComponent implements OnInit {

    @Input()
    ctx: DemoContext;
    @Input()
    playerCount: number;

    private scoreDataSubscription: Subscription;

    data: LeaderboardEntry[];


    @Input() limit = -1;
    @Input() offset = 0;
    @Input() showZeros = true;

    constructor(private scoreService: ScoreService) {
    }

    ngOnInit(): void {
        this.scoreDataSubscription = this.scoreService.fetchScores(0, 100).subscribe(scorePlayer => {
            this.data = scorePlayer;
        });

    }

    ngOnDestroy() {
        unsubscribe(this.scoreDataSubscription);
    }


}

