import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {BlindtestService} from '../blindtest/blindtest.service';
import {GameService} from '../game/game.service';
import {ReviewService} from '../review/review.service';
import {ScoreService} from '../score/score.service';
import {StatsService} from '../stats/stats.service';
import {GameControls} from '@frogconnexion/blinding-common';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {LoggerService} from '../logger/logger.service';
import {OrganizationService} from '../organization/organization.service';

@Injectable({
    providedIn: 'root'
})
export class GameControlsService {
    private currentGameStateObservable: Observable<GameControls>;
    private currentGameState: GameControls;
    private organization: string;

    constructor(private blindingService: OrganizationService,
                private blindtestService: BlindtestService,
                private gameService: GameService,
                private scoreService: ScoreService,
                private reviewService: ReviewService,
                private statsService: StatsService,
                private http: HttpClient,
                private logger: LoggerService,
                private errorHandler: ErrorHandler) {

        this.blindingService.organizationTag().subscribe(o => {
            this.organization = o;
        });
        // Current Game State observable
        this.currentGameStateObservable = this.gameService.currentGameControls();
        this.currentGameStateObservable.subscribe(g => {
            this.currentGameState = g;
        });

    }

    // Public Async methods

    lockSong(): Observable<any> {
        if (!this.currentGameState) {
            throw new Error('Cannot lock song: No game is currently set.');
        }
        // Set status of target set to OnGoing
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/game/song/lock`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    revealSong(): Observable<any> {
        if (!this.currentGameState) {
            throw new Error('Cannot reveal song: No game is currently set.');
        }
        // Set status of target set to OnGoing
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/game/song/reveal`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    nextSong(): Observable<any> {
        if (!this.currentGameState) {
            throw new Error('Cannot switch to next song: No game is currently set.');
        }
        // Set status of target set to OnGoing
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/game/song/next`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setShowLeaderboard(show: boolean): Observable<void> {
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/game/leaderboard/show/${show}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setEnableJokers(enable: boolean) {
        return this.http.post<void>(`manager://manager/org/${this.organization}/demo/game/jokers/enable/${enable}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

}
