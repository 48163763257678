import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {ShowtimeService} from '../showtime.service';
import {MediaPlayState} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})
export class VideoComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('video')
    videoElement: ElementRef<HTMLVideoElement>;
    @Input()
    key: string;
    @Input()
    link: string;
    @Input()
    fadeOutTimeInSeconds: number;
    durationInSeconds = -1;
    @Input()
    gm: GameMetadata;
    private subscription: Subscription;
    private ending = false;
    private ended = false;
    private onEachSecondPlayedListener: () => void;
    private onFinishedListener: () => void;
    private fadeOutStep: number;
    private onMetadataKnownListener: (event) => void;
    private status: MediaPlayState;

    constructor(private showtimeService: ShowtimeService) {
        this.onMetadataKnownListener = (event) => {
            this.durationInSeconds = event.target.duration;
        };
    }

    ngAfterViewInit(): void {
        this.onEachSecondPlayedListener = () => this.onEachSecondPlayed();
        this.onFinishedListener = () => this.onFinished();
        this.videoElement.nativeElement.volume = 1.0;
        this.videoElement.nativeElement.click();
        this.videoElement.nativeElement.addEventListener('loadedmetadata', this.onMetadataKnownListener);
        this.videoElement.nativeElement.addEventListener('timeupdate', this.onEachSecondPlayedListener);
        this.videoElement.nativeElement.addEventListener('ended', this.onFinishedListener);
        if (this.link) {
            this.videoElement.nativeElement.click();
            this.videoElement.nativeElement.src = this.link;
        }
        this.subscription = this.showtimeService.mediaStatusObservable(this.key)
            .subscribe((status) => {
                this.status = status;
                if (status === MediaPlayState.START) {
                    this.startVideo();
                } else if (status === MediaPlayState.PAUSE) {
                    this.pauseVideo();
                } else if (status === MediaPlayState.RESUME) {
                    this.playVideo();
                } else if (status === MediaPlayState.FORWARD) {
                    this.goForward();
                } else if (status === MediaPlayState.BACKWARD) {
                    this.goBackward();
                } else if (status === MediaPlayState.STOP_IMMEDIATELY) {
                    this.onFinished(false);
                }
            });
    }

    onFinished(broadcastEndState: boolean = true) {
        if (!this.ended) {
            this.videoElement?.nativeElement.pause();
            if (broadcastEndState) {
                this.showtimeService.mediaStatus(this.key).next(MediaPlayState.END);
            }
            this.ended = true;
        }
    }

    startVideo() {
        this.videoElement.nativeElement.currentTime = 0;
        this.videoElement.nativeElement.volume = 1.0;
        this.videoElement.nativeElement.focus();
        this.videoElement.nativeElement.click();
        this.ending = false;
        this.ended = false;
        this.videoElement.nativeElement.play()
            .then(() => {
                this.showtimeService.mediaStatus(this.key).next(MediaPlayState.PLAYING);
            })
            .catch(() => {
                this.videoElement.nativeElement.click();
                this.videoElement.nativeElement.play().then();
            });
    }

    private playVideo() {
        this.ended = false;
        if (this.videoElement.nativeElement.ended) {
            this.videoElement.nativeElement.currentTime = 0;
        }
        this.videoElement.nativeElement.play()
            .then(() => {
                this.showtimeService.mediaStatus(this.key).next(MediaPlayState.PLAYING);
            })
            .catch(() => {
                this.videoElement.nativeElement.click();
                this.videoElement.nativeElement.play().then();
                this.showtimeService.mediaStatus(this.key).next(MediaPlayState.PLAYING);
            });
    }

    private pauseVideo() {
        this.videoElement.nativeElement.pause();
        this.showtimeService.mediaStatus(this.key).next(MediaPlayState.PAUSED);
    }

    ngOnDestroy(): void {
        unsubscribe(this.subscription);
        this.videoElement?.nativeElement.removeEventListener('timeupdate', this.onEachSecondPlayedListener);
        this.videoElement?.nativeElement.removeEventListener('ended', this.onFinishedListener);
    }

    private onEachSecondPlayed() {
        const currentTimeInSeconds = Math.floor(this.videoElement.nativeElement.currentTime);
        if (this.durationInSeconds === -1) {
            return;
        }
        if ((this.durationInSeconds - this.fadeOutTimeInSeconds) === currentTimeInSeconds && !this.ending) {
            this.ending = true;
            this.showtimeService.mediaStatus(this.key).next(MediaPlayState.ENDING);
            this.fadeOutStep = this.videoElement.nativeElement.volume / (this.fadeOutTimeInSeconds * 10);
            this.fadeOutVolume();
        } else if (this.durationInSeconds <= currentTimeInSeconds && !this.ended) {
            this.onFinished();
        }
    }

    private fadeOutVolume() {
        setTimeout(() => {
            this.videoElement.nativeElement.volume -= this.fadeOutStep;
            if (this.videoElement.nativeElement.volume >= this.fadeOutStep) {
                this.fadeOutVolume();
            }
        }, 100);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.videoElement && changes['link'] && changes['link'].currentValue) {
            this.videoElement.nativeElement.src = this.link;
            if (this.status === MediaPlayState.START) {
                this.startVideo();
            }
        }
    }

    private goForward() {
        this.videoElement.nativeElement.currentTime = Math.min(this.videoElement.nativeElement.currentTime + 5, this.durationInSeconds);
        this.showtimeService.mediaStatus(this.key).next(MediaPlayState.PLAYING);
    }

    private goBackward() {
        this.videoElement.nativeElement.currentTime = Math.max(0, this.videoElement.nativeElement.currentTime - 5);
        this.showtimeService.mediaStatus(this.key).next(MediaPlayState.PLAYING);
    }
}
