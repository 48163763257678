import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Catalog } from '../../models/opportunity/catalog';
import { map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ErrorHandler } from '../../handler/error-handler';
import { OrganizationService } from '../organization/organization.service';
import { CatalogUpdateDescriptor } from '../../models/opportunity/catalogUpdateDescriptor';


@Injectable({
    providedIn: 'root',
})
export class CatalogService {
    constructor(
        private http: HttpClient,
        private errorHandler: ErrorHandler,
        private organizationService: OrganizationService
    ) {
    }

    getCatalog(): Observable<Catalog> {
        return this.http.get<Catalog>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/catalog/current`)
            .pipe(map(c => Catalog.copy(c)))
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    getCatalogFromOrg(org: string): Observable<Catalog> {
        return this.http.get<Catalog>(`manager://manager/org/${org}/catalog/org`)
            .pipe(map(c => Catalog.copy(c)))
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    assignCatalog(org: string, catalogId: string): Observable<void> {
        return this.http.post<void>(`manager://admin/organizations/${org}/catalog/${catalogId}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    save(catalog: CatalogUpdateDescriptor): Observable<void> {
        return this.http
            .post<void>(`manager://admin/catalog`, catalog)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    delete(id: string): Observable<void | Object> {
        return this.http
            .delete(`manager://admin/catalog/${id}`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    findAll(): Observable<Catalog[]> {
        return this.http
            .get<Catalog[]>(`manager://admin/catalog`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    update(catalog: CatalogUpdateDescriptor, id: String): Observable<void> {
        return this.http.put<void>(`manager://admin/catalog/${id}`, catalog)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    createFromJson(catalog: Catalog): Observable<Catalog> {
        delete catalog.id;
        return this.http.post<Catalog>(`manager://admin/catalog`, catalog)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    getOnebyId(id: string): Observable<Catalog> {
        return this.http.get<Catalog>(`manager://admin/catalog/${id}`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }
}
