<mat-card>
    <mat-card-subtitle>
        Les réponses sont maintenant verrouillées.
    </mat-card-subtitle>
    <mat-card-content>
        <div class="loading">
            <mat-spinner *ngIf="computingStats" [diameter]="40"></mat-spinner>
            <img src="assets/imgs/valid.png" alt="valid" *ngIf="!computingStats"/>
            Calcul des statistiques
        </div>
        <div class="loading">
            <mat-spinner *ngIf="computingResults" [diameter]="40"></mat-spinner>
            <img src="assets/imgs/valid.png" alt="valid" *ngIf="!computingResults"/>
            Mise à jour des résultats
        </div>
        <div *ngIf="!computingStats && stats">
            <p *ngIf="noOneAnswered()">Personne n'a répondu<br/>Ça va ? Faut qu'on s'inquiète ?<br/> Non parce qu'il faut le dire hein...</p>
            <p *ngIf="!noOneAnswered()"><b>{{stats?.responsePercent}}%</b> des joueurs ont répondu</p>
            <p *ngIf="noOneSucceededEvenPartially()">... personne n'a réussi à trouver la bonne réponse...<br/>Rien. Nada. Walou. Que dalle.</p>
            <p *ngIf="fewSucceeded()">... seulement {{stats?.successPercent + stats?.partialSuccessPercent}}% ont marqué des points.</p>
            <p *ngIf="allSucceeded()">... tout le monde a réussi en partie !!!</p>
            <app-special-mentions
                    [fastest]="stats?.fastestPlayer"
                    [fastestResult]="stats?.fastestPlayerResult"
                    [slowest]="stats?.slowestPlayer"
                    [slowestResult]="stats?.slowestPlayerResult"
                    [soleLoser]="stats?.soleLoser"
                    [soleWinner]="stats?.soleWinner">
            </app-special-mentions>
        </div>
        <canvas #statsElement width="400" height="400"></canvas>
    </mat-card-content>
</mat-card>
