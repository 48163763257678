import {DemoStepWidgetDataValidator} from '../../demoStepWidgetDataValidator';
import {WidgetDemoSetPickerData} from './widgetDemoSetPickerData';
import {WidgetQuizGameData} from './widgetQuizGameData';

export class WidgetQuizGameDataValidator implements DemoStepWidgetDataValidator<WidgetQuizGameData> {

    validate(data: WidgetQuizGameData): boolean {
        return !!data.revealed && !!data.locked;
    }
}
