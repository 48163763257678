import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {DemoSummary} from '../../../../core/models/demo/demoSummary';
import {DemoService} from '../../../../core/services/demo/demo.service';
import {DemoExperience} from '../../../../core/models/demo/demoExperience';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../../core/services/organization/organization.service';

@Component({
    selector: 'app-demo-summary-widget',
    templateUrl: './demo-summary-widget.component.html',
    styleUrls: ['./demo-summary-widget.component.scss']
})
export class DemoSummaryWidgetComponent implements OnInit, OnChanges {

    constructor(private demoService: DemoService, private router: Router, private organizationService: OrganizationService) {
    }

    get experienceName() {
        return this.xp?.labels[this.ds.lang] || 'N/A';
    }

    get demoSetTag() {
        return this.xp?.demoSets.find(demoSet => demoSet.tag === this.ds.demoSetTag)?.label || 'N/A';
    }

    get demoLang() {
        const lang = {fr: 'Français', en: 'English'};
        return lang[this.ds?.lang] || 'N/A';
    }

    get demoTime() {
        return new Date(this.ds?.creationDateTime).toISOString();
    }

    @Input()
    ds: DemoSummary;
    xp: DemoExperience;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ds) {
            this.demoService.getDemoExperience(this.ds?.experienceId).subscribe((xp) => {
                this.xp = xp;
            });
        }
    }

    ngOnInit(): void {

    }

    onCreateOpportunity() {
        this.router
            .navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard/opportunity/new`],
                {queryParams: {edit: false, demoSummaryId: this.ds.id}});
    }
}
