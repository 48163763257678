<mat-divider></mat-divider>
<mat-card-title>
    <p i18n="@@coreLeaderboardTitle"> Service leaderboard</p>
</mat-card-title>
<mat-card-subtitle
        i18n="@@coreLeaderboardSubtitlePlayers">{playerCount, plural, =0 {No player} =1 {1 player} other {{{playerCount}} players}}
</mat-card-subtitle>
<mat-card-content class="content">
    <mat-list>
        <mat-list-item class="header">
            <div class="header-position">#</div>
            <div class="header-player" i18n="@@coreLeaderboardPlayerHeader">Player</div>
            <div class="header-score" i18n="@@coreLeaderboardScoreHeader">Score</div>
        </mat-list-item>
        <app-service-entry></app-service-entry>
        <app-leaderboard-entry *ngFor="let i = index; let item of data" [entry]="item"
                               [ranking]="i" class="example-item">
        </app-leaderboard-entry>
        <mat-divider></mat-divider>
    </mat-list>
</mat-card-content>
