import {DemoExperience} from './demoExperience';
import {DemoMetadata} from './demoMetadata';

export class DemoContext {
    experience: DemoExperience;
    metadata: DemoMetadata;


    constructor(state: DemoMetadata, experience: DemoExperience) {
        this.experience = experience;
        this.metadata = state;
    }
}
