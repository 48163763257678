import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {OrganizationService} from '../../services/organization/organization.service';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';


@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss'],
})
export class LoginAdminComponent implements OnInit, OnDestroy {
  error: any;
  private _authenticated: boolean;
  private _currentUserSubscription: Subscription;

  constructor(private _authService: FirebaseAuthService,
              private _blindingService: OrganizationService,
              private _router: Router) {
  }

  get isLogged(): boolean {
    return this._authenticated;
  }

  ngOnInit() {
    this._currentUserSubscription = this._authService.currentUserObservable()
      .subscribe(user => {
        this._authenticated = user && !user.anonymous;
        if (this._authenticated) {
          this._router.navigate([`/admin/iam`]);
        }
      });
  }

  ngOnDestroy() {
    if (this._currentUserSubscription) {
      this._currentUserSubscription.unsubscribe();
    }
  }

  loginFb() {
    this._authService.facebookSignIn()
      .then()
      .catch(err => {
        this.error = err;
      });

  }

  loginGoogle() {
    const org = this._blindingService.getOrganizationTagSnapshot();
    this._authService.googleSignIn()
      .then()
      .catch(err => {
        this.error = err;
      });
  }

}
