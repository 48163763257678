<div class="main-container">
    <div class="section-container">
        <app-opportunity-header class="container recap-container dark"
                                [companyName]="opportunity?.company"
                                [crmId]="opportunity?.crmId"
                                [crmIdent]="opportunity?.crmIdent"
                                [date]="opportunity?.date"
                                [time]="opportunity?.time"
                                [user]="user"
                                [organization]="organization"
                                [otherFormsValid]="true"
                                (saveOpportunity)="saveOpportunity($event)">
        </app-opportunity-header>
    </div>
    <div class="section-container">
        <app-opportunity-main class="container quote-form-container dark"
                              [exonerated]="opportunity?.exonerated"
                              [user]="user"
                              (quoteUpdated)="onQuoteUpdated($event)"
        >
        </app-opportunity-main>
        <div class="section-subcontainer">
            <div class="container quote-comment-container dark">
                <div class="comment-section">
                    <h1>Détails / Contexte</h1>
                    <textarea [(ngModel)]="comment" class="textarea dark" cdkTextareaAutosize rows="5"
                              placeholder="Aucun commentaire pour le moment"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
