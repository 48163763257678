
export class DemoCreationDescriptor {
    company: string;
    lang: string;
    pin: string;
    experienceId: string;
    preGameStepCount: number;
    // FIXME should disappear after demo experiences are in DB
    blindtestDescriptors: any[];

    constructor(company: string, lang: string, pin: string, experienceId: string, preGameStepCount: number, blindtestDescriptors: any[]) {
        this.company = company;
        this.lang = lang;
        this.pin = pin;
        this.experienceId = experienceId;
        this.preGameStepCount = preGameStepCount;
        this.blindtestDescriptors = blindtestDescriptors;
    }
}
