import {Component, OnInit} from '@angular/core';
import {ShowtimeService} from '../../../screen/showtime.service';
import {MediaPlayState} from '@frogconnexion/blinding-common';

@Component({
    selector: 'app-widget-video-controls',
    templateUrl: './widget-video-controls.component.html',
    styleUrls: ['./widget-video-controls.component.scss']
})
export class WidgetVideoControlsComponent implements OnInit {

    constructor(private showtimeService: ShowtimeService) {
    }

    ngOnInit(): void {
    }

    goBackward() {
        this.showtimeService.changeRemoteMediaState(MediaPlayState.BACKWARD).subscribe();
    }

    goForward() {
        this.showtimeService.changeRemoteMediaState(MediaPlayState.FORWARD).subscribe();
    }

    pause() {
        this.showtimeService.changeRemoteMediaState(MediaPlayState.PAUSE).subscribe();
    }

    resume() {
        this.showtimeService.changeRemoteMediaState(MediaPlayState.RESUME).subscribe();
    }
}
