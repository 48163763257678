import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';

import {GameService} from '../../core/services/game/game.service';
import {EMPTY, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {LoggerService} from '../../core/services/logger/logger.service';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements AfterViewInit, OnInit, OnDestroy {

  private organizationSubscription: Subscription;
  hasCurrentGame: boolean;
  error: string;
  orgForm = new UntypedFormGroup({
    organization: new UntypedFormControl('', [Validators.required])
  });

  constructor(private blindingService: OrganizationService,
              private gameService: GameService,
              private logger: LoggerService,
              private router: Router, private _cdr: ChangeDetectorRef) {
    this.organizationSubscription = this.blindingService.organization().subscribe(b => {
      this.hasCurrentGame = b?.getBlindingHasCurrentGame();
    });
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    unsubscribe(this.organizationSubscription);
  }

  ngAfterViewInit() {
    this._cdr.detectChanges();
  }

  get organization() {
    return this.orgForm.get('organization');
  }

  onSubmit() {
    const org = this.organization.value.toLowerCase();
    this.blindingService.isValid(org)
      .pipe(catchError((err, caught) => {
        this.logger.error(err);
        this.error = err;
        return EMPTY;
      }))
      .subscribe(
        (result: boolean) => {
          if (result) {
            this.logger.debug(`Game PIN valid. Organization ${org} exists`);
            this.router.navigate([`/org/${org}/dashboard`]);
          } else {
            this.logger.warn('Wrong Game PIN. Organization doesn\'t exist');
            this.error = 'Wrong game PIN';
          }
        });
  }
}
