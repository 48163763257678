
export interface ShowtimeBlobMetadata {
    key: string;
    lang: string;
    type: string;
    elementId: string;
    link: string;
    duration: number;
}

export interface BlobContentConfig {
    organization: string;
    lang: string;
    blobs: ShowtimeBlobMetadata[];
}

export function normalizeToKey(str: string) {
    return str.toLowerCase().trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '-').replace(/'/g, '');
}

export function computeKeyHashFromUrl(str: string): string {
    return btoa(normalizeToKey(str)).replace(/\\|\/|\+|\=/g, 'x');
}

export function mapEnvironmentToShowtimeConfig(organization: string,
                                               lang: string,
                                               env: any,
                                               ids?: { [p: string]: { id: string, type: string } }): BlobContentConfig {
    const blobs = [];
    if (!ids) {
        ids = {};
    }
    const media: { key: string, lang: string, type: string, link: string, duration: number }[] = env.showtime.media;
    media.forEach(m => {
        blobs.push({
            key: m.key,
            lang: m.lang,
            type: m.type,
            link: m.link,
            duration: m.duration,
            elementId: ids[m.key].id
        });
    });
    return {
        organization: organization,
        lang: lang,
        blobs: blobs
    };
}
