import {DemoStep} from './demoStep';

export class DemoSet {
    tag: string;
    lang: string;
    label: string;
    steps: DemoStep[];
    defaultAsset: any;


    constructor(tag: string, lang: string, label: string, defaultAsset: any, steps: DemoStep[]) {
        this.tag = tag;
        this.label = label;
        this.lang = lang;
        this.defaultAsset = defaultAsset;
        this.steps = steps;
    }
}
