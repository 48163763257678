<div class="container">
    <h1>Détails du devis</h1>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="9999">
        Aucun élément dans ce devis pour le moment
      </td>
    </tr>
    <!-- Selection Column -->
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef class="head-selection">
        <mat-checkbox [checked]="allChecked"
                      color="accent"
                      [indeterminate]="someChecked"
                      (change)="checkAll($event.checked)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element" class="selection">
          <mat-checkbox
                  *ngIf="element.type === 'optional'"
                  [(ngModel)]="element.selected"
                  (ngModelChange)="onCheckboxChanged(element.ref, $event)">
          </mat-checkbox>
      </td>
    </ng-container>

    <!-- Ref Column -->
    <ng-container matColumnDef="ref">
      <th mat-header-cell *matHeaderCellDef class="head-ref"> Ref. </th>
      <td mat-cell *matCellDef="let element" class="ref"> {{element.ref}} </td>
    </ng-container>

    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="head-description"> Description </th>
      <td mat-cell *matCellDef="let element" class="description">
        <b>{{element.name}}</b>
        <br/>
        <span class="description-text">{{element.description}}</span>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef class="head-price"> Prix </th>
      <td mat-cell *matCellDef="let element" class="price">
        <ng-container *ngIf="element.selected">{{element.price | currency:'EUR':'':'3.0-0'}} €</ng-container>
        <ng-container *ngIf="!element.selected"><span class="error">Non inclus</span></ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <hr/>
  <div class="footer">
    <div class="vat-exonerated">
      <mat-checkbox disabled [ngModel]="exonerated">Client exonéré de TVA</mat-checkbox>
    </div>
    <div class="total-price" *ngIf="totalPrice > 0">Total : {{totalPrice | currency:'EUR':'':'3.0-0'}} € <ng-container *ngIf="exonerated">T.T.C</ng-container> <ng-container *ngIf="!exonerated">H.T.</ng-container></div>
  </div>

</div>
