<div class="ending-container">
    <audio id="audioEndFeed" #audio autoplay loop [controls]="false"></audio>
    <div class="upper-block-container" *ngIf="this.screen === 'welcome' && lang === 'fr'">
        <h1 class="thanks tentozero">Bienvenue</h1>
    </div>
    <div class="center-block-container">
        <app-audiowave-video class="heartbeat" *ngIf="interactionMade"></app-audiowave-video>
        <img class="logo tentozero" [src]="'/assets/imgs/logo-gamified.png'" alt="logo"/>
        <app-audiowave-video class="heartbeat" [reverse]="true" *ngIf="interactionMade"></app-audiowave-video>
    </div>
    <div class="bottom-block-container">
        <span class="bottom-label tentozero" *ngIf="lang === 'fr'">Démo Le Blindtest</span>
        <span class="bottom-label tentozero" *ngIf="lang === 'en'">The Live Quiz Demo</span>
    </div>
    <div class="bottom-block-container" *ngIf="this.screen === 'welcome' && lang === 'fr'">
        <ng-container *ngIf="showtimeLoaded && !loading?.isCompleted()">
            <span class="loading-label">Loading videos <span *ngIf="mediaTotalCount > 0">({{mediaLoadedCount}} / {{mediaTotalCount}})</span></span>
        </ng-container>
        <ng-container *ngIf="showtimeLoaded === undefined">
            <span class="loading-label">Recherche de l'Extension Showtime</span>
        </ng-container>
        <ng-container *ngIf="showtimeLoaded === false">
            <span class="loading-label">Installer l'extension Chrome Showtime pour GoBlindtest avant de continuer.</span>
            <a mat-raised-button [href]="'https://chrome.google.com/webstore/detail/' + extensionId" id="install-button" target="_blank" color="warn">Installer l'extension</a>
        </ng-container>
    </div>
</div>
