<div class="container">
    <mat-list class="list">
        <mat-list-item class="header">
            <div class="header-position">#</div>
            <div class="header-player" *ngIf="gm?.servicesEnabled && gm?.lang === 'en'">Team</div>
            <div class="header-player" *ngIf="gm?.servicesEnabled && gm?.lang === 'fr'">Équipe</div>
            <div class="header-player" *ngIf="!gm?.servicesEnabled && gm?.lang === 'en'">Player</div>
            <div class="header-player" *ngIf="!gm?.servicesEnabled && gm?.lang === 'fr'">Joueur</div>
            <div class="header-score" i18n="@@coreLeaderboardScoreHeader">Score</div>
        </mat-list-item>
        <cdk-virtual-scroll-viewport itemSize="110" class="example-viewport" *ngIf="!gm?.servicesEnabled">
            <app-leaderboard-entry class="example-item" *cdkVirtualFor="let i = index; let item of ds"
                                   [entry]="item" [ranking]="i"></app-leaderboard-entry>
        </cdk-virtual-scroll-viewport>
    </mat-list>
</div>

