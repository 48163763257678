<div class="main-container">
    <div class="section-container">
        <app-opportunity-header class="container recap-container dark"
                                [companyName]="company"
                                [user]="user"
                                [organization]="organization"
                                [otherFormsValid]="leftFormValid"
                                (saveOpportunity)="saveOpportunity($event)">
        </app-opportunity-header>
    </div>
    <div class="section-container">
        <app-opportunity-quote-form class="container quote-form-container dark" [editing]="false" [catalog]="catalog"
                                    [user]="user"
                                    (validityChanged)="validityChanged($event)"
                                    [quote]="currentQuote" [opQuote]="quote" (quoteUpdated)="onQuoteUpdated($event)">
        </app-opportunity-quote-form>
        <div class="section-subcontainer">
            <app-opportunity-quote-details class="container quote-details-container dark" [quote]="currentQuote"
                                           (vatExonerated)="onVatExonerated($event)"
                                           (optionChecked)="onQuoteOptionsChecked($event)"></app-opportunity-quote-details>
            <div class="container quote-comment-container dark">
                <div class="comment-section">
                    <h1>Détails / Contexte</h1>
                    <textarea [(ngModel)]="comment" class="textarea dark" cdkTextareaAutosize rows="5"
                              placeholder="Aucun commentaire pour le moment"></textarea>
                </div>
            </div>
        </div>
    </div>

</div>
