<div class="header">
    <mat-icon>sports_esports</mat-icon>
    <h2>Contrôles Jeu</h2>
</div>
<div>
    <button mat-raised-button color="accent" *ngIf="shouldShowControls()" (click)="lockSong()"
            [disabled]="shouldDisableLock()">Verrouiller
    </button>
    <button mat-raised-button color="accent" *ngIf="shouldShowControls()" (click)="revealSong()"
            [disabled]="shouldDisableReveal()">Révéler
    </button>
</div>
<div>
    <div class="song" *ngIf="currentSong">{{currentSong.artist}} - {{currentSong.title}}</div>
    <div class="song" *ngIf="currentSong && currentSong?.type === 1">{{currentSong.lyrics}}</div>
    Nb Équipes : {{playerCount || 0}}
    <br/>Réponses : {{submissionCount}}
    <br/>En traitement : {{remainingItemsToReview}}
</div>
<app-admin-stats-widget *ngIf="shouldShowStats()"></app-admin-stats-widget>
<br/>
<div *ngIf="isRevealed()">
    <div class="response" *ngFor="let subs of submissionList?.items | keyvalue">
        {{getPlayerName(subs.value.playerId)}}: {{subs.value.artist}} - {{subs.value.title }} {{subs.value.lyrics}}
    </div>
</div>
<div class="container" *ngIf="currentSong">
  <span *ngFor="let review of pendingReviews">
    <app-submit-review-widget *ngIf="!hasBeenReviewed(review.key)" [pendingReview]="review"></app-submit-review-widget>
  </span>
</div>
