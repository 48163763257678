import {Component, Injectable, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LoggerService} from '../../../../../../core/services/logger/logger.service';
import {ReviewService} from '../../../../../../core/services/review/review.service';
import {SongPendingReview} from '@frogconnexion/blinding-common';
import {MatchResult} from '@frogconnexion/blinding-common';
import {DemoContext} from '../../../../../../core/models/demo/demoContext';
import {WidgetSubmitReview} from '../../../../../../core/models/demo/widgets/widgetSubmitReview';

@Component({
    selector: 'app-submit-review-widget',
    templateUrl: './widget-submit-review.component.html',
    styleUrls: ['./widget-submit-review.component.scss']
})
export class WidgetSubmitReviewComponent implements OnInit, OnChanges {

    @Input()
    pendingReview: SongPendingReview;

    @Input()
    ctx: DemoContext;
    @Input()
    widget: WidgetSubmitReview;

    constructor(private _reviewService: ReviewService,
                private _logger: LoggerService) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }


    submitReview() {
        this.pendingReview.review = this.pendingReview.review.map(v => {
            if (v === MatchResult.Unsolved) {
                return MatchResult.Incorrect;
            }
            return v;
        });
        this._reviewService.solvePendingReview(this.pendingReview).subscribe();
    }
}
