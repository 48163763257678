import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DemoContext} from '../../../../../../core/models/demo/demoContext';
import {ScoreService} from '../../../../../../core/services/score/score.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../../../core/handler/subscription-handler';
import {ServiceLeaderboardEntry} from '../../../../../../core/models/leaderboard/serviceleaderboardentry';

@Component({
    selector: 'app-service-leaderboard',
    templateUrl: './service-leaderboard.component.html',
    styleUrls: ['./service-leaderboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ServiceLeaderboardComponent implements OnInit, OnDestroy {

    @Input()
    ctx: DemoContext;
    @Input()
    playerCount: number;

    private scoreServiceDataSubscription: Subscription;

    data: ServiceLeaderboardEntry[];

    @Input() limit = -1;
    @Input() offset = 0;
    @Input() showZeros = true;

    constructor(private scoreService: ScoreService) {
    }

    ngOnInit(): void {
        this.scoreServiceDataSubscription = this.scoreService.fetchServiceScores(0, 100).subscribe(scoreService => {
            this.data = scoreService;
        });
    }

    ngOnDestroy() {
        unsubscribe(this.scoreServiceDataSubscription);
    }

}

