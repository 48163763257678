<div class="header">
    <mat-icon>theater_comedy</mat-icon>
    <h2>Choix de la Démo</h2>
</div>
<div>
    <form [formGroup]="demoSetPickerForm" class="full-width">
        <mat-form-field appearance="fill" class="full-width">
            <mat-label>Set de Démo</mat-label>
            <select formControlName="demoSet" matNativeControl required>
                <option label="----"></option>
                <option *ngFor="let ds of getDemoSets()" [value]="ds.tag">{{ds.label}}</option>
            </select>
            <mat-error *ngFor="let validation of validationMessages.demoSet">
                <mat-error *ngIf="demoSet.hasError(validation.type)">
                    <mat-error class="error-message" *ngIf="validation.message === 'demoSet.required'">
                        Merci de choisir un set de Démo
                    </mat-error>
                </mat-error>
            </mat-error>
            <mat-hint>Quelle démo pour le client ?</mat-hint>
        </mat-form-field>
    </form>
    <div *ngIf="bt">
        <app-blindtest-content [bt]="bt"></app-blindtest-content>
    </div>
</div>
