import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {OrganizationService} from '../../organization/organization.service';
import {LoggerService} from '../../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class GameGuard implements CanActivate {

  constructor(private _blindingService: OrganizationService,
              private _router: Router,
              private _logger: LoggerService) {
  }

  canActivate(): Observable<boolean> {
    return this._blindingService.organization()
      .pipe(map(blinding => blinding?.getBlindingHasCurrentGame()))
      .pipe(map(hasCurrentGame => {
        if (!hasCurrentGame) {
          this._logger.debug('Game Guard Failed. Redirecting.');
          this._router.navigate(['/dashboard']);
          return false;
        }
        this._logger.debug('Game Guard Succeeded.');
        return true;
      }));
  }
}
