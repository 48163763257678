<div class="header">
    <mat-icon>movie</mat-icon>
    <h2>Contrôles Vidéo</h2>
</div>
<div class="btn-container">
    <button mat-raised-button color="primary" (click)="goBackward()"><mat-icon>fast_rewind</mat-icon></button>
    <button mat-raised-button color="primary" (click)="pause()"><mat-icon>pause</mat-icon></button>
    <button mat-raised-button color="primary" (click)="resume()"><mat-icon>play_arrow</mat-icon></button>
    <button mat-raised-button color="primary" (click)="goForward()"><mat-icon>fast_forward</mat-icon></button>
</div>
