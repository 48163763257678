import { Quote } from './quote';
import {QuoteDto} from './quoteDto';

export class QuoteDescriptor {
    id: string;
    experienceTag: string;
    bundleTag: string;
    lang: string;
    paxCount: number;
    articleRefs: string[];

    static fromQuote(q: Quote): QuoteDescriptor {
        const qc = new QuoteDescriptor();
        qc.experienceTag = q.experienceTag;
        qc.bundleTag = q.bundleTag;
        qc.lang = q.lang;
        qc.paxCount = q.paxCount;
        qc.articleRefs = q.articles.map(a => a.ref);
        return qc;
    }


    static from(q: QuoteDto): QuoteDescriptor {
        const qc = new QuoteDescriptor();
        qc.id = q.id;
        qc.experienceTag = q.experienceTag;
        qc.bundleTag = q.bundleTag;
        qc.lang = q.lang;
        qc.paxCount = q.paxCount;
        const articleList = q.articles.mandatory.map(a => a.ref);
        q.articles.optional.filter(item => item.selected === true).map(item => articleList.push(item.article.ref));
        qc.articleRefs = articleList;
        return qc;
    }
}
