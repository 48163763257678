import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthorizedUserGuard} from '../../core/auth/service/guards/authorized-user-guard.service';
import {MaterialModule} from '../../core/material/material.module';
import {CoreModule} from '../../core/core.module';
import {RouterModule} from '@angular/router';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {OrganizationGuard} from '../../core/services/organization/guards/organization.guard';
import {DemoComponent} from './demo.component';
import {ControlComponent} from './control/control.component';
import {ScreenComponent} from './screen/screen.component';
import {StepsComponent} from './control/steps/steps.component';
import {WidgetTextComponent} from './control/widgets/widget-text/widget-text.component';
import {MarkdownModule} from 'ngx-markdown';
import {WidgetDemoSetPickerComponent} from './control/widgets/widget-demo-set-picker/widget-demo-set-picker.component';
import {WelcomeComponent} from './screen/welcome/welcome.component';
import {LeaderboardComponent} from './control/widgets/widget-leaderboard/leaderboard/leaderboard.component';
import { BlindtestContentComponent } from './control/widgets/widget-demo-set-picker/blindtest-content/blindtest-content.component';
import { BlindtestContentSectionComponent } from './control/widgets/widget-demo-set-picker/blindtest-content/blindtest-content-section/blindtest-content-section.component';
import { BlindtestContentSongComponent } from './control/widgets/widget-demo-set-picker/blindtest-content/blindtest-content-song/blindtest-content-song.component';
import {WidgetSubmitReviewComponent} from './control/widgets/widget-quiz-game/widget-submit-review/widget-submit-review.component';
import {AdminStatsWidgetComponent} from './control/widgets/widget-quiz-game/widget-stats/widget-stats.component';
import {GameControlsService} from '../../core/services/gamecontrols/gamecontrols.service';
import {WidgetDemoPlayerCounterComponent} from './control/widgets/widget-demo-player-counter/widget-demo-player-counter.component';
import {WidgetDemoSubmissionsCounterComponent} from './control/widgets/widget-demo-submissions-counter/widget-demo-submissions-counter.component';
import { WidgetQuizGameComponent } from './control/widgets/widget-quiz-game/widget-quiz-game.component';
import {PlayerMentionWidgetComponent} from './control/widgets/widget-quiz-game/widget-stats/special-mentions/player-mention-widget/player-mention-widget.component';
import {SpecialMentionsComponent} from './control/widgets/widget-quiz-game/widget-stats/special-mentions/special-mentions.component';
import {VideoComponent} from './screen/video/video.component';
import {PlayerWidgetComponent} from './control/widgets/widget-leaderboard/leaderboard/player-widget/player-widget.component';
import {WidgetLeaderboardComponent} from './control/widgets/widget-leaderboard/widget-leaderboard.component';
import {ServiceLeaderboardComponent} from './control/widgets/widget-leaderboard/service-leaderboard/service-leaderboard.component';
import {MatTabsModule} from '@angular/material/tabs';
import {ServiceWidgetComponent} from './control/widgets/widget-leaderboard/service-leaderboard/service-widget/service-widget.component';
import {JoinTheGameComponent} from './screen/join-the-game/join-the-game.component';
import {LivefeedComponent} from './screen/livefeed/livefeed.component';
import {ShowtimeOverlayLockedComponent} from './screen/livefeed/showtime-overlay-locked/showtime-overlay-locked.component';
import {ShowtimeOverlayLeaderboardComponent} from './screen/livefeed/showtime-overlay-leaderboard/showtime-overlay-leaderboard.component';
import {ShowtimeOverlayPlayingComponent} from './screen/livefeed/showtime-overlay-playing/showtime-overlay-playing.component';
import {ShowtimeOverlayRevealedComponent} from './screen/livefeed/showtime-overlay-revealed/showtime-overlay-revealed.component';
import {RoundProgressWidgetComponent} from './screen/common/round-progress-widget/round-progress-widget.component';
import {SpinnerSquareComponent} from './screen/common/spinner-square/spinner-square.component';
import { WidgetVideoControlsComponent } from './control/widgets/widget-video-controls/widget-video-controls.component';
import {AudiowaveVideoComponent} from './screen/common/audiowave-video/audiowave-video.component';

const routes = [
    {path: 'org/:organization/demo', component: DemoComponent, canActivate: [OrganizationGuard, AuthorizedUserGuard]},
    {path: 'org/:organization/demo/control', component: ControlComponent, canActivate: [OrganizationGuard, AuthorizedUserGuard]},
    {path: 'org/:organization/demo/screen', component: ScreenComponent, canActivate: [OrganizationGuard, AuthorizedUserGuard]},
];


@NgModule({
    imports: [
        MaterialModule,
        CommonModule,
        CoreModule,
        MarkdownModule.forChild(),
        DragDropModule,
        RouterModule.forChild(routes),
        MatTabsModule
    ],
    declarations: [
        DemoComponent,
        ControlComponent,
        ScreenComponent,
        StepsComponent,
        WidgetTextComponent,
        ServiceWidgetComponent,
        PlayerWidgetComponent,
        WidgetDemoSetPickerComponent,
        AdminStatsWidgetComponent,
        WidgetSubmitReviewComponent,
        PlayerMentionWidgetComponent,
        SpecialMentionsComponent,
        WidgetDemoPlayerCounterComponent,
        WidgetDemoSubmissionsCounterComponent,
        AudiowaveVideoComponent,
        WelcomeComponent,
        LeaderboardComponent,
        ServiceLeaderboardComponent,
        WidgetLeaderboardComponent,
        BlindtestContentComponent,
        BlindtestContentSectionComponent,
        BlindtestContentSongComponent,
        WidgetQuizGameComponent,
        VideoComponent,
        JoinTheGameComponent,
        LivefeedComponent,
        ShowtimeOverlayLockedComponent,
        ShowtimeOverlayLeaderboardComponent,
        ShowtimeOverlayPlayingComponent,
        ShowtimeOverlayRevealedComponent,
        RoundProgressWidgetComponent,
        SpinnerSquareComponent,
        WidgetVideoControlsComponent
    ],
    exports: [],
    providers: [
        GameControlsService,
    ]
})
export class DemoModule {
}
