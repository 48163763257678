<mat-card class="opportunity-card">
    <div class="right">
        <div class="top-right">{{op.crmIdent || op.crmId}}</div>
        <div class="bot-right"></div>
    </div>
    <mat-card-header>
        <mat-card-title>
            <div class="header-container">
                {{op.company}}
            </div>
        </mat-card-title>
        <mat-card-subtitle>
            {{(op.date | date:'dd/MM/YYYY') || 'Aucune date précisée'}} {{getTime()}}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content *ngIf="!loading">
        <ng-container *ngIf="isConfirmed()">
            <app-opportunity-card-widget-confirmed
                    [xpName]="getExperienceName(op?.acceptedQuote?.experienceTag)"
                    [bundleName]="getBundleName(op?.acceptedQuote?.experienceTag, op?.acceptedQuote?.bundleTag)"
                    [q]="op?.acceptedQuote">
            </app-opportunity-card-widget-confirmed>
        </ng-container>

        <ng-container *ngIf="isNew() || isDraft()">
            <app-opportunity-card-widget-draft-n-new
                    *ngFor="let q of op.quotes; let index = index; let isFirst = first; let isLast = last;"
                    [single]="isFirst && isLast"
                    [isLast]="isLast"
                    [xpName]="getExperienceName(q.experienceTag)"
                    [bundleName]="getBundleName(q.experienceTag, q.bundleTag)"
                    [index]="index"
                    [status]="op?.status"
                    [q]="q"
                    (delete)="deleteQuote(q)"
                    (duplicate)="duplicateQuote(q)"
                    (edit)="editQuote(q)"
            >
            </app-opportunity-card-widget-draft-n-new>
        </ng-container>
        <ng-container *ngIf="isCreatedInCrm()">
            <app-opportunity-card-widget-created-in-crm
                    *ngFor="let q of op.quotes; let index = index; let isFirst = first; let isLast = last;"
                    [single]="isFirst && isLast"
                    [isLast]="isLast"
                    [xpName]="getExperienceName(q.experienceTag)"
                    [bundleName]="getBundleName(q.experienceTag, q.bundleTag)"
                    [index]="index"
                    [status]="op?.status"
                    [q]="q"
                    (confirm)="confirmOpportunity(q)"
            >
            </app-opportunity-card-widget-created-in-crm>
        </ng-container>
        <ng-container *ngIf="isCanceled()">
            <app-opportunity-card-widget-canceled
                    *ngFor="let q of op.quotes; let index = index; let isFirst = first; let isLast = last;"
                    [single]="isFirst && isLast"
                    [isLast]="isLast"
                    [xpName]="getExperienceName(q.experienceTag)"
                    [bundleName]="getBundleName(q.experienceTag, q.bundleTag)"
                    [index]="index"
                    [status]="op?.status"
                    [q]="q"
            >
            </app-opportunity-card-widget-canceled>
        </ng-container>
    </mat-card-content>
    <mat-card-content *ngIf="loading" class="loading">
        Mise à jour en cours
        <mat-spinner [diameter]="40"></mat-spinner>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions *ngIf="!loading">
        <div class="actions">
            <div class="edit" *ngIf="!isConfirmed()">
                <ng-container *ngIf="!canEditDateOnly()">
                    <button mat-button matTooltip="Éditer l'opportunité" (click)="editOpportunity(op)"
                            [disabled]="!canEdit()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </ng-container>
                <ng-container *ngIf="canEditDateOnly()">
                    <button mat-button matTooltip="Éditer la date/heure" (click)="editDateDialog()"
                            [disabled]="!isAdminOrManager() && dateAlreadySet()">
                        <mat-icon>event</mat-icon>
                    </button>
                </ng-container>

                <button mat-button [matMenuTriggerFor]="menu" class="edit">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="duplicateOpportunity(op)">
                        <mat-icon>file_copy</mat-icon>
                        Dupliquer
                    </button>
                    <button mat-menu-item (click)="deleteOpportunity(op)" *ngIf="canEdit()">
                        <mat-icon>delete</mat-icon>
                        Supprimer
                    </button>
                    <button mat-menu-item (click)="backToDraft(op)" *ngIf="isNew()">
                        <mat-icon>undo</mat-icon>
                        Remettre en Brouillon
                    </button><button mat-menu-item (click)="loseOpportunity()" *ngIf="canCancel()">
                    <mat-icon>thumb_down_alt</mat-icon>
                    Opportunité perdue
                </button>
                </mat-menu>
            </div>
            <div class="action-buttons" *ngIf="isDraft()">
                <button mat-raised-button color="accent" (click)="validateDraft()">Envoyer La Demande</button>
            </div>
            <div class="action-text" *ngIf="isNew()">
                Demande envoyée
            </div>
            <!--
            <div class="checkbox" *ngIf="false && isConfirmed()">
                <mat-checkbox color="primary" [checked]="op.briefingDone" name="État" value="checked">Brief réalisé
                </mat-checkbox>
            </div>-->
        </div>
    </mat-card-actions>
</mat-card>
