import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';

/**
 * Attention a bien respecter la hiérarchie : sets [ { sections: [ {... songs: [{...}] } ] } ]
 */
function getDefaultAsset() {
    return {
        'name': 'Expert J.B.Goode Sirene IFeelGood',
        'description': null,
        'tags': ['fr-expert-goode-sirene-feelgood'],
        'sets': [{
            'sections': [
                {
                    'type': 'NORMAL',
                    'label': 'Shuffle',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [{
                        'title': 'Johnny B. Goode',
                        'artist': 'Chuck Berry',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {'artist': [], 'title': [], 'lyrics': []},
                        'ageRange': {'start': 45, 'end': 60}
                    }]
                }, {
                    'type': 'LYRICS',
                    'label': 'Disney',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': 'Nom du Disney'},
                    'songs': [{
                        'title': 'Embrasse-la',
                        'artist': 'Henri Salvador',
                        'lyrics': 'La Petite Sirène',
                        'type': 'LYRICS',
                        'alt': {'artist': [], 'title': [], 'lyrics': ['the little mermaid','sirene']},
                        'ageRange': {'start': 45, 'end': 60}
                    }]
                }, {
                    'type': 'NORMAL',
                    'label': 'J\'ai 2 mots à te dire',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [{
                        'title': 'I Got You (I Feel Good)',
                        'artist': 'James Brown',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {'artist': ['Brown'], 'title': ['I got you','i feel good'], 'lyrics': []},
                        'ageRange': {'start': 45, 'end': 60}
                    }]
                }
            ]
        }]
    };
}

function getPitchStep2() {
    return '**[Lorsque le générique commence]**\n' +
        '\n' +
        'Et c\'est parti...  \r\n\r\n' +
        '**[Arrivée en trombe des artistes]**\n\n' +
        '**[Fin de l\'extrait]**\n\n' +
        ' * Les gens ont l\'habitude d\'un événementiel digital un peu "rustique"\n' +
        ' * Là ils voient un générique télé, un groupe qui arrive en criant sur un plateau avec plein de caméras...\n' +
        ' * La première impression que ça crée c\'est que les gens sont **scotchés à leur siège** en mode **"ok, c\'est quoi ce truc ? ça va être fou"**\n\n' +
        ' * On travaille beaucoup cette entrée pour qu\'elle crée une rupture chez les gens !\n' +
        ' * Très vite, derrière, les artistes vont expliquer ce qu\'ils font ici : **"vous allez jouer à un Blindtest, il va falloir trouver le nom de l\'artiste et le titre."**\n' +
        '\n' +
        ' * Deux grandes différences : Tout sera joué en live, et vous allez répondre via une app !  \n\n' +
        'Et pour présenter l\'app, on a trouvé la personne parfaite en vidéo...';
}

function getPitchStep3() {
    return '**[La vidéo commence]**  \n\n' +
        'Donc évidemment, violation totale de copyright ici ^^, mais du coup c\'est entrainant !  \n\n' +
        'On a une **voix-off**, les gens s\'immergent dans le concept et ça donne le ton\n\n' +
        'Je vous passe les détails, c\'est surtout là qu\'on va expliquer aux participants comment rejoindre le jeu\n' +
        '\n' +
        '\n' +
        '**[Fin de l\'extrait]**';
}

function getPitchStep4() {
    return 'Et du coup, c\'est là que je vais m\'arrêter, puisque **c\'est à vous**  \n\n' +
        'Ehh oui, je vous invite à tous prendre vos téléphones, et vous connecter sur **www.GoBlindTest.fr**\n\n' +
        'Le pin d\'aujourd\'hui c\'est *[VOTRE ORGANISATION]*\n\n' +
        'En général, c\'est le **nom de votre société** qu\'on met, ' +
        'on peut aussi **brander** l\'application à vos couleurs\n\n' +
        '**[On les laisse s\'inscrire]**\n\n' +
        'Ce processus d\'onboarding prend entre 3 et 5 minutes selon le nombre de personnes présentes.\n\n' +
        'Les artistes vont généralement rappeler comment se connecter, faire tourner une chanson en attendant etc...\n';
}

function getPitchStep5() {
    return 'Pitch \n\n Juste avant de commencer le jeu, on va généralement faire un chauffage de foules :  \n' +
        'Les gens vont devoir ouvrir leurs micros et crier avec tout le monde.' +
        '[La vidéo se déroule]\n\n' +
        'on se rend compte que certaines personnes n\'ont pas ri ou crié comme ça depuis longtemps, ça fait une énorme décharge d\'énergie,\n' +
        '(rires) en général, les gens nous prennent déjà pour des fous à ce stade, et c\'est très bien car ça décomplexe ! \n' +
        'on va pouvoir attaquer !\n\n\n\n' +
        'On attaque avec la section SHUFFLE, c\'est en hommage à toutes ces chansons que tu avais dans ton MP3, toujours dans le même ordre "aléatoire" qu\'on avait fini par retenir...\n\n' +
        'Bien penser à répondre dans l\'appli directement, tout le monde est prêt ? C\'est parti !\n' +
        '\n';
}

function getPitchStep6() {
    return 'Pitch \n\n Conseils : Soyez engageant, drôle, faites des blagues, expliquez bien chaque section avec un peu d\'humour\n\n' +
        '[Attendre toutes les réponses]\n\n' +
        '[Si vous voyez qu\'ils galèrent : proposer des indices. On veut qu\'ils trouvent !]\n\n' +
        '*On vous avait dit que c\'était le mode expert ! Bon on est sympa on l\'a laissée continuer, ils ont bien dit le titre 2 ou 3 fois ;)*\n\n' +
        '[Verrouiller les réponses une fois que c\'est OK. Ne pas attendre trop longtemps après l\'extrait]\n\n' +
        '[les statistiques seront affichées, vous pouvez les commenter librement avant de...]\n\n' +
        '[Révéler la réponse. Ici la bonne réponse sera révélée au joueur.]\n\n' +
        '\n\n' +
        'La section suivante est une section DISNEY. Il va falloir retrouver le nom d\'un Disney, donc il n\'y aura plus qu\'un seul champ à remplir. Prêt ? C\'est parti !\n\n'
        ;
}

function getPitchStep7() {
    return 'Pitch \n\n Conseils : Soyez engageant, drôle, faites des blagues, expliquez bien chaque section avec un peu d\'humour\n\n' +
        '[Attendre toutes les réponses]\n\n' +
        '[Si vous voyez qu\'ils galèrent : proposer des indices. On veut qu\'ils trouvent !]\n\n' +
        '*Alors vous l\'avez ? C\'est l\'histoire d\'une fille qui échange sa voix contre des jambes ;)\n' +
        '[Verrouiller les réponses une fois que c\'est OK. Ne pas attendre trop longtemps après l\'extrait]\n\n' +
        '[les statistiques seront affichées, vous pouvez les commenter librement avant de...]\n\n' +
        '[Révéler la réponse. Ici la bonne réponse sera révélée au joueur.]\n\n' +
        'On va continuer avec la dernière, mais j\'ai décidé de vous aider un peu\n\n' +
        'On va vous activer les jokers !\n\n' +
        '\n' +
        '\n' +
        'En général on les annonce en vidéo, là on va les activer rapidement. Le plus drôle est celui du milieu : Il vous permet de piquer la réponse d\'une autre équipe\n' +
        '\n';
}

function getPitchStep8() {
    return 'C\'est le Joker en personne qui va activer les jokers.\n' +
        '\n' +
        '\n' +
        'C\'est un peu comme qui veut gagner des millions, vous avez un 50-50, un Quitte ou double\n' +
        '\n' +
        '\n' +
        'Le meilleur c\'est celui du milieu, il permet de piquer la réponse d\'une autre équipe ^^\n' +
        'Attention, pensez bien à soumettre d\'abord votre réponse, c\'est obligatoire !\n\n' +
        'On termine avec une de nos sections signatures c\'est la section J\'AI 2 MOTS A TE DIRE\n' +
        '\n' +
        '\n' +
        'En gros on demande l\'aide du public, on leur demande de nous donner des mots improbables et on fait la chanson avec uniquement ces mots là (en l\'occurrence sur l\'exemple que vous allez écouter, on a choisi juste le prénom d\'un participant)\n';
}

function getPitchStep9() {
    return '[Fin de la chanson]\n' +
        'Bon là il y avait un GROS piège, mais comme on est sympa on a accepté de donner quand même les points (le vrai titre c\'est I Got You)\n' +
        'Bravo ! je vois qu\'il y a du niveau ^^\n' +
        '\n' +
        '\n' +
        '\n' +
        'Est-ce qu\'on jette un oeil aux scores ?\n' +
        '\n' +
        '\n' +
        '\n' +
        'Regardons qui a gagné...';
}

function getPitchStep10() {
    return 'Pitch \n\n [saucer le gagnant !]\n' +
        '\n' +
        '\n' +
        '\n' +
        'Sachez qu\'on peut jouer en solo ou par équipes si vous avez des services ou des départements.\n' +
        '\n' +
        '\n' +
        '\n' +
        'Quoi qu\'il arrive vous recevrez aussi les scores détaillés en fichier excel juste après l\'animation.\n' +
        '\n' +
        '\n' +
        '\n' +
        'Grosso modo, vous avez tout compris au concept.' +
        '\n\n' +
        'Qu\'est-ce que je peux vous montrer de plus ?\n\n' +
        'Ahh oui, selon l\'équipe et la couleur que vous donnez à votre show, on va souvent ponctuer l\'animation de quelques temps-forts\n\n' +
        'Par exemple, la musique de l\'accessoire...';
}

function getPitchStep11() {
    return 'Là, on va demander aux gens de se lever, de courir et d\'aller chercher un accessoire loufoque pour gagner 50 points\n\n' +
        '\n' +
        '\n' +
        '\n' +
        '[Laisser la vidéo commencer]' +
        '\n' +
        '\n' +
        'Les gens vont disparaitre de la caméra, tout le monde se barre, il n\'y a plus personne\n\n' +
        'Je vous passe les détails, regardez surtout ce avec quoi les gens reviennent :)\n\n' +
        'On a des animaux, des peluches, des enfants maquillés, on a vu des choses incroyables ces derniers mois.\n\n' +
        'C\'est là que la magie se passe. C\'est quand les gens ne sont plus dans leur salon mais ailleurs, ensemble.\n\n' +
        'C\'est quand plus personne ne se prend au sérieux, même ceux qui ne jouent pas le jeu seront morts de rire\n\n' +
        'On a vu des portées dirty dancing qui se finissent mal, \n\nOn a vu des papas qui vont chercher leur nouveau né dans la chambre et qui le portent comme le roi lion sur une section Disney\n\n' +
        'Et mine de rien quand les gens vivent ça ensemble, ils se reconnectent à leur culture d\'entreprise, c\'est là que la magie se passe.\n\n' +
        'On se redécouvre, différemment, et je vous préviens, ils vont s\'en rappeler longtemps !\n\n';
}

function getPitchStep12() {
    return 'Est ce que vous pensez que c\'est une expérience qui peut plaire à vos équipes ?\n\n' +
        'Sachez qu\'on a plein de déclinaisons de cette animation, elle se fait en digital (comme montré ici) ou en présentiel, en hybride présentiel / digital, etc...\n\n' +
        'Si c\'est quelque chose qui vous plait, le plus urgent en général est de bloquer une option pour une date, afin de voir si les artistes sont disponibles.\n\n' +
        'En général on demande au moins 3 semaines pour pouvoir organiser le show comme vous le souhaitez\n\n' +
        'Une fois que la date est bloquée, on fait ce qu\'on appelle le "brief", où vous déciderez de la couleur artistique que vous voulez donner au show.\n\n' +
        'Selon l\'équipe, on vous proposera des thèmes, on demandera la pyramide des âges, on vous conseillera sur la comm\' (on a des plans de comm\' tout prêts pour optimiser l\'engagement des équipes.)\n\n' +
        'Une fois que le brief est terminé, c\'est clé en main et on s\'occupe de toute la suite.\n\n' +
        'Dans l\'idée, quand aimeriez-vous faire cet évent ?\n\n' +
        'C\'est plutôt en journée ? Plutôt en fin d\'aprem ?\n' +
        '\n' +
        'En début de semaine ? Fin de semaine ?\n' +
        '\n' +
        'D\'autre part, on a plusieurs dispositifs selon le nombre de personnes que vous avez en cible. Pour ce que vous m\'avez présenté, on aurait tendance à vous orienter sur l\'expérience X, est-ce que ça vous dit qu\'on regarde un peu combien ça peut vous couter ?\n' +
        '\n' +
        '\n' +
        'Je vais vous dire tout ça';
}

export function computeDemoSetGoodeSireneFeelGood() {
    return new DemoSet('fr-expert-goode-sirene-feelgood',
        'fr',
        'Expert J.B.Goode Sirene IFeelGood',
        getDefaultAsset(),
        [
            new DemoStep(2,
                [new WidgetText(getPitchStep2()), new WidgetVideoControl()],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/570915318.hd.mp4?s=26d60dcb32e7d6f751e78c94f94357036e8fc0a2&profile_id=175&download=1'),
                    new ActionNext()
                ],
                'Lancer la vidéo Morphéus'),
            new DemoStep(3,
                [new WidgetText(getPitchStep3()), new WidgetVideoControl()],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Faire rejoindre la partie'),
            new DemoStep(4,
                [new WidgetText(getPitchStep4()), new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691342560/rendition/720p/chauffe_foules_bravo%20%28720p%29.mp4?loc=external&signature=82f198c0a5cc0715ad830b02ee16810854ec5faf2af6c0a5d380c5e3001cd63d', true),
                    new ActionNext()
                ],
                'Chauffer les foules'),
            new DemoStep(5,
                [new WidgetText(getPitchStep5()), new WidgetVideoControl(), new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691499720/rendition/720p/set_expert_-_01_johny_be_good%20%28720p%29.mp4?loc=external&signature=094fbeed5e02795d16dd70197298643c28bad9eb48f41f3fdc2eec5b80c9a4c1', true),
                    new ActionNext(),
                ],
                'Lancer la Partie'),
            new DemoStep(6,
                [new WidgetText(getPitchStep6()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691499869/rendition/720p/set_expert_-_02_la_petite_sirene%20%28720p%29.mp4?loc=external&signature=cd51954c65f0586b8e012fcb0b3384c6a142e93a891fd119e8339a26a5ef9900', true),
                    new ActionNext(),
                ],
                'Suivant'),
            new DemoStep(7,
                [new WidgetText(getPitchStep7()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionEnableJokers(),
                    new ActionNextItem(),
                    new ActionNext(),
                ],
                'Activer les jokers'),
            new DemoStep(8,
                [new WidgetText(getPitchStep8())],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691499959/rendition/720p/set_expert_-_03_i_got_you%20%28720p%29.mp4?loc=external&signature=d0bb0b64029c788910701322d5a0bc67daf327a8f83040a88980d261d6da2ae3', true),
                    new ActionNext(),
                ],
                'Chanson suivante'),
            new DemoStep(9,
                [new WidgetText(getPitchStep9()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Montrer les scores'),
            new DemoStep(10,
                [new WidgetText(getPitchStep10()), new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576212374.hd.mp4?s=9a893fe855570e7d9567465796face61b5003f57&profile_id=175&download=1'),
                    new ActionNext()
                ],
                'Voir un exemple d\'animation'),
            new DemoStep(11,
                [
                    new WidgetText(getPitchStep11()),
                    new WidgetVideoControl(),
                ],
                [
                    new ActionNext()
                ],
                'Closing'),
            new DemoStep(12,
                [new WidgetText(getPitchStep12())],
                [
                    new ActionTerminateDemo()
                ],
                'Terminer la démo'),
        ]
    );
}
