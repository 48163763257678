import {Injectable} from '@angular/core';


import {Observable, ReplaySubject, Subject, Subscription} from 'rxjs';
import {GameControls} from '@frogconnexion/blinding-common';
import {OrganizationService} from '../organization/organization.service';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {GameDescriptor} from '@frogconnexion/blinding-common';
import {Game} from '@frogconnexion/blinding-common';
import {ErrorHandler} from '../../handler/error-handler';
import {HttpClient} from '@angular/common/http';
import {LoggerService} from '../logger/logger.service';
import {Song} from '@frogconnexion/blinding-common';
import {BlindtestService} from '../blindtest/blindtest.service';
import {unsubscribe} from '../../handler/subscription-handler';
import {Blindtest} from '@frogconnexion/blinding-common';
import {Database, object, ref} from '@angular/fire/database';
import {environment} from '../../../../../environments/environment';
import {Claims, PropertyClaim} from '@frogconnexion/core-common';

@Injectable({
    providedIn: 'root'
})
export class GameService {

    private blindtestSubscription: Subscription;
    private _currentBlindtest: Blindtest;
    private gameStateSubject: Subject<GameControls>;
    private gameControlsSubscription: Subscription;
    private _currentGameState: GameControls;
    private gameMetadataSubject: Subject<GameMetadata>;
    private gameMetadataSubscription: Subscription;
    private _currentGameMetadata: GameMetadata;
    private currentSongSubject: ReplaySubject<Song>;
    private _currentSong: Song;
    private _organization: string;

    constructor(private database: Database,
                private organizationService: OrganizationService,
                private blindtestService: BlindtestService,
                private http: HttpClient,
                private errorHandler: ErrorHandler,
                private logger: LoggerService) {

        this.gameStateSubject = new ReplaySubject(1);
        this.gameMetadataSubject = new ReplaySubject(1);
        this.currentSongSubject = new ReplaySubject<Song>(1);

        // When blinding object changes state
        this.organizationService.organization().subscribe(o => {
            this._organization = o?.organization;
            unsubscribe(this.gameControlsSubscription, this.gameMetadataSubscription);

            // If blinding has changed, compute and emit next game state value
            const claim = PropertyClaim.parse(Claims.Organization.BLINDING_GLOBAL_PROP_HAS_CURRENT_GAME);
            const hasCurrentGame = o?.properties.find(p => p.claimKey === claim.claimKey)?.value === true;
            if (!hasCurrentGame) {
                this.updateCurrentGameState(null);
                this.updateCurrentMetadata(null);
                return;
            }
            this.gameControlsSubscription = object(ref(this.database, `/${environment.globalNamespace}/games/${this._organization}/game/control`))
                .subscribe(sn => {
                    this.updateCurrentGameState(sn.snapshot.val());
                });
            this.gameMetadataSubscription = object(ref(this.database, `/${environment.globalNamespace}/games/${this._organization}/game/metadata`))
                .subscribe(sn => {
                    this.updateCurrentMetadata(sn.snapshot.val());
                });
            // Current Blindtest observable
            this.blindtestSubscription = this.blindtestService.currentBlindtest().subscribe(bt => {
                this.updateCurrentBlindtest(bt);
            });
        });
    }

    private updateCurrentGameState(gs: GameControls) {
        this._currentGameState = GameControls.fromDto(gs);
        this.gameStateSubject.next(this._currentGameState);
        this._updateCurrentSong();
    }

    private updateCurrentMetadata(gm: GameMetadata) {
        this._currentGameMetadata = gm;
        this.gameMetadataSubject.next(gm);
    }

    private updateCurrentBlindtest(bt: Blindtest) {
        this._currentBlindtest = bt;
        this._updateCurrentSong();
    }

    private _updateCurrentSong() {
        let newSong: Song = null;
        // If both requirements to get song are here, get it
        if (!this._currentBlindtest || !this._currentGameState) {
            newSong = null;
        } else {
            newSong = this._currentBlindtest.sets[this._currentGameState.blindtestControl.current]
                .songs[this._currentGameState.setControl.current];
        }
        if (this._currentSong !== newSong) {
            this._currentSong = newSong;
            this.currentSongSubject.next(this._currentSong);
        }
    }

    // Public Async methods

    startCurrentGame(): Observable<void> {
        if (!this._currentGameState) {
            throw new Error('Cannot start: No game is currently set.');
        }
        return this.http.post<void>(`/manager/org/${this._organization}/demo/game/start`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    finishCurrentGame(): Observable<void> {
        if (!this._currentGameState) {
            throw new Error('Cannot start: No game is currently set.');
        }
        return this.http.post<void>(`/manager/org/${this._organization}/demo/game/finish`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    unsetCurrentGame(): Observable<void> {
        if (!this._currentGameState) {
            throw new Error('Cannot start: No game is currently set.');
        }
        return this.http.delete<void>(`/manager/org/${this._organization}/demo/game`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    createCurrentGame(g: GameDescriptor): Observable<Game> {
        return this.http.post<Game>(`/manager/org/${this._organization}/demo/game`, g)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    // Observables

    currentGameMetadata(): Observable<GameMetadata> {
        return this.gameMetadataSubject;
    }

    currentGameControls(): Observable<GameControls> {
        return this.gameStateSubject;
    }

    currentSong(): Observable<Song> {
        return this.currentSongSubject;
    }

}
