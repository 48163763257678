export class OpportunityEditionDescriptor {
    id: string;
    company: string;
    crmId: string;
    crmIdent: string;
    fromCrm: boolean;
    partner: boolean;
    exonerated: boolean;
    date: string;
    hour: number;
    minute: number;
    comment: string;


    constructor(id: string, company: string, crmId: string, crmIdent: string, fromCrm: boolean,
                exonerated: boolean, date: string, hour: number, minute: number, comment: string) {
        this.id = id;
        this.company = company;
        this.crmId = crmId;
        this.crmIdent = crmIdent;
        this.fromCrm = fromCrm;
        this.exonerated = exonerated;
        this.date = date;
        this.hour = hour;
        this.minute = minute;
        this.comment = comment;
    }

    clone(): OpportunityEditionDescriptor {
        return new OpportunityEditionDescriptor(this.id, this.company, this.crmId, this.crmIdent, this.fromCrm,
            this.exonerated, this.date, this.hour, this.minute, this.comment);
    }
}
