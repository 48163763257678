<mat-toolbar>
    <div class="container">
        <!-- Avatar -->
        <img class="logo-bubble" src="/assets/imgs/logo.png"/>
        <!-- Title -->
        <div class="title">
            <span *ngIf="header">{{header | titlecase}}</span>
            <span *ngIf="!(header)">Frog Manager</span>
        </div>
        <span class="fill-remaining-space"></span>

        <div fxLayout="row" fxShow="false" fxShow.gt-sm>
            <ng-container *ngIf="organization">
                <button *ngIf="authorizedUser" [routerLink]="'/org/' + organization + '/dashboard'" mat-button>
                    Dashboard
                </button>
                <button *ngIf="authorizedUser && isDemoOngoing" (click)="stopDemo()" mat-button>Stop Demo</button>
                <button *ngIf="authenticated" (click)="logout()" mat-button>Logout</button>
            </ng-container>
        </div>
        <button mat-icon-button [mat-menu-trigger-for]="menu" fxHide="false" fxHide.gt-sm>
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</mat-toolbar>

<mat-menu class="sub-menu" fxLayout="column" xPosition="before" #menu="matMenu" [overlapTrigger]="false">
    <button *ngIf="authorizedUser" [routerLink]="'/org/' + organization + '/dashboard'" mat-menu-item>Dashboard</button>
    <button *ngIf="authorizedUser && isDemoOngoing" (click)="stopDemo()" mat-menu-item>Stop Demo</button>
    <button *ngIf="authenticated" (click)="logout()" mat-menu-item>Logout</button>
</mat-menu>
