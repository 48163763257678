<div class="container">
  <mat-card>
    <form (ngSubmit)="onSubmit()" [formGroup]="signupForm" class="container form">
      <h2 style="text-align: center;">Crée ton compte DemoTime !</h2>

      <div class="form-group">
        <mat-form-field class="full-width">
          <input formControlName="user" matInput placeholder="Nom" [errorStateMatcher]="matcher"/>
          <mat-error *ngFor="let validation of validationMessages.user">
            <mat-error class="error-message" *ngIf="user.hasError(validation.type)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input formControlName="email" matInput placeholder="Email" [errorStateMatcher]="matcher"/>
          <mat-error *ngFor="let validation of validationMessages.email">
            <mat-error class="error-message" *ngIf="email.hasError(validation.type)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-group" [formGroup]="passwordFormGroup">
        <mat-form-field class="full-width">
          <input formControlName="password" matInput placeholder="Mot de passe" [type]="!hide ? 'password' : 'text'"/>
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          <mat-error *ngFor="let validation of validationMessages.password">
            <mat-error class="error-message" *ngIf="passwordFormGroup.get('password').hasError(validation.type)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width">
          <input formControlName="confirmPassword" matInput placeholder="Confirmation du mot de passe"
                 [type]="!hide ? 'password' : 'text'"/>
          <mat-icon matSuffix (click)="hide = !hide">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          <mat-error *ngFor="let validation of validationMessages.confirmPassword">
            <mat-error class="error-message" *ngIf="passwordFormGroup.get('confirmPassword').hasError(validation.type)">
              {{validation.message}}
            </mat-error>
          </mat-error>
        </mat-form-field>
        <mat-error *ngFor="let validation of validationMessages.passwords">
          <mat-error class="error-message" *ngIf="passwordFormGroup.hasError(validation.type)">
            {{validation.message}}
          </mat-error>
        </mat-error>
      </div>

      <div class="form-group">
        <br/>
        <mat-hint style="width: 100%; text-align: left;">Choisis un avatar:</mat-hint>
        <app-avatar-selector formControlName="avatar">
        </app-avatar-selector>
        <mat-error *ngFor="let validation of validationMessages.avatar">
          <mat-error class="error-message" *ngIf="avatar.hasError(validation.type)">
            {{validation.message}}
          </mat-error>
        </mat-error>
      </div>

      <br/>
      <div class="centered">
        <button mat-raised-button color="primary" type="submit" [disabled]="!signupForm.valid">On y va!</button>
      </div>
      <br/>
      <mat-error *ngIf="error" class="error-message">
        {{error}}
      </mat-error>

      <mat-card-footer class="bottom-pad">
        <div fxFlex fxLayoutAlign="space-around center">
          <a routerLink="/" id="goback">Retour</a>
        </div>
      </mat-card-footer>
    </form>
  </mat-card>
</div>
