import {Pricing} from './pricing';
import {PricingType} from './pricingType';

export class FixedPricing implements Pricing {
    type = PricingType.FIXED;
    price: number;

    compute(): number {
        return this.price;
    }

    constructor(price: number) {
        this.type = PricingType.FIXED;
        this.price = price;
    }
}
