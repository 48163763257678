import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-audiowave-video',
  templateUrl: './audiowave-video.component.html',
  styleUrls: ['./audiowave-video.component.scss']
})
export class AudiowaveVideoComponent implements OnInit {

  @Input()
  reverse: boolean

  constructor() { }

  ngOnInit(): void {
  }

}
