import {DemoContent} from '../../models/demo/demoContent';
import {DemoExperience} from '../../models/demo/demoExperience';
import {DemoStep} from '../../models/demo/demoStep';
import {WidgetText} from '../../models/demo/widgets/widgetText';
import {ActionNext} from '../../models/demo/actions/actionNext';
import {WidgetDemoSetPicker} from '../../models/demo/widgets/widgetDemoSetPicker';
import {ActionLaunchVideo} from '../../models/demo/actions/actionLaunchVideo';
import {ActionSetGame} from '../../models/demo/actions/actionSetGame';
import {computeDemoSet1830GirlyFr} from './sets/fr-18-30-girly';
import {computeDemoSetGeneration6075} from './sets/fr-generation-60-75';
import {computeDemoSet2540GeekyFr} from './sets/fr-25-40-geeky';
import {computeDemoSetInternational2} from './sets/en-international-2';
import {computeDemoSetGeneration7085} from './sets/fr-generation-70-85';
import {computeDemoSetGeneration8595} from './sets/fr-generation-85-95';
import {computeDemoSetGeneration95xy} from './sets/fr-generation-95';
import {computeDemoSetInternationalClassicsFr} from './sets/fr-international-classics';
import {computeDemoSetInternationalClassicsEn} from './sets/en-international-classics';
import {computeDemoSetSalon1830} from './sets/fr-salon-normal';
import {computeDemoSetSalon1830Fast} from './sets/fr-salon-fast';
import {computeDemoSetSalon1830SuperFast} from './sets/fr-salon-super-fast';
import {computeDemoSetInternational3Fast} from './sets/en-international-3-fast';
import {computeDemoSetInternational8595Fast} from './sets/en-international-85-95-fast';
import {computeDemoSetInternational3SuperFast} from './sets/en-international-3-super-fast';
import {computeDemoSetInternationalMoyenJeune} from './sets/en-moyenjeune';
import {computeDemoSetInternationalPassePartout} from './sets/en-passepartout';
import {computeDemoSetGoodeSireneFeelGood} from './sets/fr-expert-goode-sirene-feelgood';
import {computeDemoSetGenYTreasureMulanMagueule} from './sets/fr-genY-treasure-mulan-magueule';
import {computeDemoSetSeniorCelineFlammePurple} from './sets/fr-senior-celine-flamme-purple';
import {computeDemoSetJeuneOvniTigerPoker} from './sets/fr-jeune-ovni-tiger-poker';
import {computeTakimaDemoSetForum1} from './sets/fr-takima-18-30-1';
import {computeTakimaDemoSetForum2} from './sets/fr-takima-18-30-2';
import {computeTakimaDemoSetForum3} from './sets/fr-takima-18-30-3';
import {computeTakimaDemoSetForum4} from './sets/fr-takima-18-30-4';

function computeLeBlindtestPreGameStepsFr() {
    return [
        new DemoStep(0, [new WidgetText('Qu\'est-ce que vous avez fait en événementiel ces derniers mois ?  \r\n' +
            'Qu\'est-ce qui a bien fonctionné ? Pourquoi ?  \r\n' +
            ' * Le milieu de l\'événementiel a eu du mal à s\'adapter au format digital\r\n' +
            ' * Les gens ont besoin de pouvoir se retrouver et de partager de bons moments même s\'ils ne sont pas dans le même lieu physique\r\n' +
            ' * C\'est une activité inclusive, qui rassemble et entrainante\r\n' +
            ' * L\'idée : transporter les gens ailleurs, oublier qu\'ils sont derrière un écran\r\n' +
            '\r\n' +
            '**Note** : trouver leur âge, cerner l\'ambiance  \r\n\r\n' +
            'Est-ce que ça vous dit une petite démo ?  \r\n' +
            'Ça vous permettra de le vivre de l\'intérieur'
        )], [new ActionNext()], 'Suivant'),
        new DemoStep(1, [
            new WidgetText(
                'Imaginez que vous êtes avec tous vos collaborateurs sur Zoom  \r\n\r\n' +
                'Vous voyez les gens sur la mosaïque, ça a l\'air sympa  \r\n\r\n' +
                'La seule chose qui est différente, c\'est que vous voyez un petit compte à rebours\r\n'
            ),
            new WidgetDemoSetPicker(),
        ], [new ActionSetGame(), new ActionLaunchVideo('https://player.vimeo.com/external/570900068.hd.mp4?s=d7576fe54c0307010e5446d18d523e53346b3bb7&profile_id=175&download=1'), new ActionNext()], 'Lancer le générique')
    ];
}

function computeLeBlindtestPreGameStepsEn() {
    return [
        new DemoStep(0, [new WidgetText('Have you organized some events in the past few months?  \r\n' +
            'What worked well? Why?\r\n' +
            ' * The event industry had troubles adapting to the digital world\r\n' +
            ' * More than ever, people need to be able to see each other and share great and fun moments together\r\n' +
            ' * This is what we have been trying to re-create\r\n' +
            ' * The idea is simple: transport people somewhere else, and make them live an out of this world experience\r\n' +
            '\r\n' +
            '**Note** : find their age, try to see how engaging you can get in your sales call  \r\n\r\n' +
            'Would you like a demo?  \r\n' +
            'I guess living it yourself is better than words!'
        )], [new ActionNext()], 'Next'),
        new DemoStep(1, [
            new WidgetText(
                'Imagine you are here with your teammates on Zoom \r\n\r\n' +
                'You can see each other on the video mosaïc\r\n\r\n' +
                'Among the webcams, one is different and showing a countdown like this one\r\n'
            ),
            new WidgetDemoSetPicker(),
        ], [new ActionSetGame(), new ActionLaunchVideo('https://player.vimeo.com/external/583435499.hd.mp4?s=1f21228c3c817a20243f81381025b56ac0accea5&profile_id=175&download=1'), new ActionNext()], 'Launch Entry Theme')
    ];
}


function computeLeBlindtestPreGameStepsSalon() {
    return [
        new DemoStep(0, [
            new WidgetText(
                '-'
            ),
            new WidgetDemoSetPicker(),
        ], [new ActionSetGame(), new ActionLaunchVideo('https://player.vimeo.com/external/570900068.hd.mp4?s=d7576fe54c0307010e5446d18d523e53346b3bb7&profile_id=175&download=1'), new ActionNext()], 'Lancer le générique')
    ];
}


function computeLeBlindtestPreGameStepsTakima() {
    return [
        new DemoStep(0, [
            new WidgetText(
                '-'
            ),
            new WidgetDemoSetPicker(),
        ], [new ActionSetGame(), new ActionLaunchVideo('https://player.vimeo.com/external/570900068.hd.mp4?s=d7576fe54c0307010e5446d18d523e53346b3bb7&profile_id=175&download=1'), new ActionNext()], 'Lancer le générique')
    ];
}

function computeLeBlindtestPreGameStepsSalonEn() {
    return [
        new DemoStep(0, [
            new WidgetText(
                '-'
            ),
            new WidgetDemoSetPicker(),
        ], [new ActionSetGame(), new ActionLaunchVideo('https://player.vimeo.com/external/583435499.hd.mp4?s=1f21228c3c817a20243f81381025b56ac0accea5&profile_id=175&download=1'), new ActionNext()], 'Launch Entry Theme')
    ];
}



const CONTENT: DemoContent = {
    'experiences': [
        new DemoExperience('le-blindtest-digital', {
            'fr': 'Le Blindtest (Digital)',
            'en': 'The Live Quiz (Digital)'
        }, {
            'fr': computeLeBlindtestPreGameStepsFr(),
            'en': computeLeBlindtestPreGameStepsEn(),
        }, [
            computeDemoSet1830GirlyFr(),
            computeDemoSet2540GeekyFr(),
            computeDemoSetInternationalClassicsFr(),
            computeDemoSetInternationalClassicsEn(),
            computeDemoSetInternational2(),
            computeDemoSetInternationalMoyenJeune(),
            computeDemoSetInternationalPassePartout(),
            computeDemoSetGeneration7085(),
            computeDemoSetGeneration8595(),
            computeDemoSetGeneration95xy(),
            computeDemoSetGeneration6075(),
            computeDemoSetGoodeSireneFeelGood(),
            computeDemoSetGenYTreasureMulanMagueule(),
            computeDemoSetJeuneOvniTigerPoker(),
            computeDemoSetSeniorCelineFlammePurple()
        ]),
        new DemoExperience('le-blindtest-digital-salon', {
            'fr': 'Le Blindtest (Digital) Salon',
            'en': 'The Live Quiz (Digital) Trade Show'
        }, {
            'fr': computeLeBlindtestPreGameStepsSalon(),
            'en': computeLeBlindtestPreGameStepsSalonEn(),
        }, [
            computeDemoSetSalon1830(),
            computeDemoSetSalon1830Fast(),
            computeDemoSetSalon1830SuperFast(),
            computeDemoSetInternational3Fast(),
            computeDemoSetInternational3SuperFast(),
            computeDemoSetInternational8595Fast(),
        ]),
        new DemoExperience('le-blindtest-digital-takima', {
            'fr': 'Le Blindtest Forum Takima',
            'en': 'Le Blindtest Forum Takima'
        }, {
            'fr': computeLeBlindtestPreGameStepsTakima(),
            'en': computeLeBlindtestPreGameStepsTakima(),
        }, [
            computeTakimaDemoSetForum1(),
            computeTakimaDemoSetForum2(),
            computeTakimaDemoSetForum3(),
            computeTakimaDemoSetForum4(),
        ])
    ]
};

export default CONTENT;
