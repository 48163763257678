import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Catalog} from '../../../core/models/opportunity/catalog';
import {CatalogService} from '../../../core/services/catalog/catalog.service';
import {NavigationService} from '../../../core/services/navigation/navigation.service';
import {QuoteDto} from '../../../core/models/opportunity/quoteDto';
import {ActivatedRoute, Router} from '@angular/router';
import {DemoService} from '../../../core/services/demo/demo.service';
import {DemoSummary} from '../../../core/models/demo/demoSummary';
import {take} from 'rxjs/operators';
import {QuoteFormUpdate} from './opportunity-quote-form/quoteFormUpdate';
import {constraintMatch} from '../../../core/models/opportunity/constraint';
import {Article} from '../../../core/models/opportunity/article';
import {OpportunityCreationDescriptor} from '../../../core/models/opportunity/opportunityCreationDescriptor';
import {OpportunityService} from '../../../core/services/opportunity/opportunity.service';
import {ToastrService} from 'ngx-toastr';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {QuoteDescriptor} from '../../../core/models/opportunity/quoteDescriptor';
import {Quote} from '../../../core/models/opportunity/quote';
import {Subscription} from 'rxjs';
import {OpportunityHeaderUpdate} from '../opportunity-edition/opportunity-header/opportunityHeaderUpdate';
import {FirebaseAuthService, unsubscribe} from '@frogconnexion/angular-auth';
import {AuthUser, Organization} from '@frogconnexion/core-common';
import { User } from 'firebase/auth';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-opportunity-creation',
    templateUrl: './opportunity-creation.component.html',
    styleUrls: ['./opportunity-creation.component.scss']
})
export class OpportunityCreationComponent implements OnInit, AfterViewInit, OnDestroy {

    catalog: Catalog;
    currentQuote: QuoteDto;
    ds: DemoSummary;
    quote: Quote;
    company: string;
    user: AuthUser;
    private subscriptions: Subscription[] = [];
    comment: string;
    organization: Organization;
    private exonerated: boolean;
    leftFormValid = false;

    constructor(private catalogService: CatalogService,
                private demoService: DemoService,
                private activatedRoute: ActivatedRoute,
                private navigationService: NavigationService,
                private router: Router,
                private authService: FirebaseAuthService,
                private organizationService: OrganizationService,
                private opportunityService: OpportunityService,
                private toastr: ToastrService) {
        this.navigationService.updateNavHeader('Nouvelle opportunité');
        this.catalogService.getCatalog().subscribe(c => {
            this.catalog = c;
        });
        this.subscriptions.push(this.organizationService.organization().subscribe(o => this.organization = o));
        this.activatedRoute.queryParamMap.pipe(take(1)).subscribe(qp => {
            if (qp.has('demoSummaryId')) {
                this.demoService.getDemoSummary(qp.get('demoSummaryId')).subscribe(ds => {
                    this.ds = ds;
                    this.currentQuote = new QuoteDto(undefined, this.ds.experienceId);
                    this.company = this.ds.company;
                });
            }
        });
    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.authService.currentUserObservable().subscribe(u => {
            this.user = u;
        }));
    }

    ngAfterViewInit(): void {

    }

    onQuoteUpdated(quoteUpdate: QuoteFormUpdate) {
        const q = this.currentQuote?.clone() || new QuoteDto(undefined, quoteUpdate.experienceTag);
        q.paxCount = quoteUpdate.paxCount;
        q.bundleTag = quoteUpdate.bundleTag;
        q.experienceTag = quoteUpdate.experienceTag;
        q.type = this.catalog.experiences?.find(x => x.tag === q.experienceTag)?.bundles?.find(b => b.tag === q.bundleTag)?.type;
        q.lang = quoteUpdate.lang;
        q.articles = this.computeArticles(q);
        this.currentQuote = q;
    }

    computeArticles(q: QuoteDto): { excluded: Article[], mandatory: Article[], optional: { article: Article, selected: boolean }[] } {
        const experienceEmpty = !q.experienceTag || q.experienceTag.length === 0;
        const bundleEmpty = !q.bundleTag || q.bundleTag.length === 0;
        const result: {
            excluded: Article[],
            mandatory: Article[],
            optional: { article: Article, selected: boolean }[]
        } = {excluded: [], mandatory: [], optional: []};
        if (experienceEmpty || bundleEmpty || q.articles === undefined) {
            return result;
        }
        const xp = this.catalog?.experiences.find(e => e.tag === q.experienceTag);
        const bundle = xp.bundles.find(b => b.tag === q.bundleTag);
        const ctx = {intl: (q.lang === 'en'), pax: q.paxCount};

        result.excluded = xp.articles.filter(a => {
            const bi = bundle?.items.find(i => i.ref === a.ref);
            if (!bi) {
                return false;
            }
            return !constraintMatch(ctx, ...bi.constraints);
        });

        result.mandatory = xp.articles.filter(a => {
            const bi = bundle?.items.find(i => i.ref === a.ref);
            if (!bi) {
                return false;
            }
            return constraintMatch(ctx, ...bi.constraints) && !bi.isOptional(ctx);
        });

        result.optional = xp.articles.filter(a => {
            const bi = bundle?.items.find(i => i.ref === a.ref);
            if (!bi) {
                return false;
            }
            return constraintMatch(ctx, ...bi.constraints) && bi.isOptional(ctx);
        }).map(a => {
            return {article: a, selected: !!q.articles.optional.find(o => o.article.ref === a.ref && o.selected)};
        });

        return result;
    }

    onQuoteOptionsChecked(options: { ref: string; selected: boolean }[]) {
        const q = this.currentQuote.clone();
        options.forEach(o => q.articles.optional.find(a => a.article.ref === o.ref).selected = o.selected);
        this.currentQuote = q;
    }


    saveOpportunity(ohd: OpportunityHeaderUpdate) {
        const o = new OpportunityCreationDescriptor(
            ohd.company,
            ohd.crmId,
            ohd.crmIdent,
            ohd.isFromCrm,
            ohd.dateIso,
            ohd.hour,
            ohd.minute,
            this.exonerated,
            QuoteDescriptor.from(this.currentQuote),
            this.comment
        );

        if (o.quote.bundleTag === undefined || o.quote.bundleTag === '') {
            this.toastr.error('Vous devez choisir un bundle !');
        } else if (o.quote.paxCount === undefined || !o.quote.paxCount) {
            this.toastr.error('Vous devez saisir une valeur de pax !');
        } else {
            this.opportunityService.save(o).subscribe(() => {
                this.toastr.success('Opportunité créée avec succès');
                this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
            });
        }
    }

    onVatExonerated(exonerated: boolean) {
        this.exonerated = exonerated;
    }

    validityChanged(valid: boolean) {
        this.leftFormValid = valid;
    }
}
