import {Component, OnDestroy, OnInit} from '@angular/core';

import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {GameService} from '../../services/game/game.service';
import {unsubscribe} from '../../handler/subscription-handler';
import {NavigationService} from '../../services/navigation/navigation.service';
import {OrganizationService} from '../../services/organization/organization.service';
import {DemoService} from '../../services/demo/demo.service';
import {ValidationDialogComponent} from '../dialog/validation-dialog/validation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';
import {AuthUser, Claims, RoleClaim, User} from '@frogconnexion/core-common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  private stopDemoDialogSubscription: Subscription;

    constructor(private authService: FirebaseAuthService,
                private router: Router,
                private dialog: MatDialog,
                private gameService: GameService,
                private demoService: DemoService,
                private organizationService: OrganizationService,
                private navigationService: NavigationService) {
    }

    authenticated: boolean;
    admin: boolean;
    authorizedUser: boolean;
    isDemoOngoing: boolean;
    hasCurrentGame: boolean;
    organization: string;
    user: AuthUser = AuthUser.anonymousUnauthenticated();
    header: string;
    private currentUserSubscription: Subscription;
    private gameSubscription: Subscription;
    private navHeaderSubscription: Subscription;
    private organizationSubscription: Subscription;
    private demoSubscription: Subscription;
    organizations: string[];

    ngOnInit() {

        this.currentUserSubscription = this.authService.currentUserObservable().subscribe(user => {
            if (!user) {
                return;
            }
            this.authenticated = !user.anonymous;
            if (user.anonymous) {
                this.authorizedUser = false;
                return;
            }
            this.user = user;
            this.authorizedUser = false;
            this.admin = user.hasAnyRole([RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN)]);
        });
        this.organizationSubscription = this.organizationService.organizationTag().subscribe(o => {
            this.organization = o;
            if (this.organization) {
                unsubscribe(this.currentUserSubscription);
                this.currentUserSubscription = this.authService.currentUserObservable().subscribe(user => {
                    if (!user) {
                        return;
                    }
                    this.authenticated = !user.anonymous;
                    if (user.anonymous) {
                        this.authorizedUser = false;
                        return;
                    }
                    this.user = user;
                    this.authorizedUser = user.hasAnyRole([
                        RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN),
                        RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_MANAGER, this.organization),
                        RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_PARTNER, this.organization)]);
                    this.admin = user.hasAnyRole([RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN)]);
                });
            }
        });
        this.gameSubscription = this.gameService.currentGameControls().subscribe(gs => {
            this.hasCurrentGame = !!gs;
        });
        this.navHeaderSubscription = this.navigationService.navHeader().subscribe(h => {
            this.header = h;
        });
        this.demoSubscription = this.demoService.currentDemoMetadata().subscribe(d => {
            this.isDemoOngoing = !!d;
        });
    }

    ngOnDestroy() {
        unsubscribe(this.currentUserSubscription,
            this.demoSubscription,
            this.gameSubscription,
            this.navHeaderSubscription,
            this.stopDemoDialogSubscription,
            this.organizationSubscription);
    }


    logout() {
        this.authService.signOut()
            .then(() => this.router.navigate(['/']));
    }

    stopDemo() {
      unsubscribe(this.stopDemoDialogSubscription);
      const dialogRef = this.dialog.open(ValidationDialogComponent, {
        width: '300px',
        data: {subtitle: 'Vous allez arrêter la démo en cours pour votre équipe. Êtes-vous certain ?'}
      });
      this.stopDemoDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
        if (activate) {
          this.demoService.deactivateDemo().subscribe(() => {
            this.router.navigate([`/org/${this.organization}/dashboard`]);
          });
        }
      });
    }
}
