import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';
import {
    getJokerAdvice, getLeaderboardAdvice,
    getPitchEndGoogleTranslate,
    getPitchGagnant,
    getPitchInscription,
    getPitchTakima,
    getSongAnswerAdvice
} from './fr-takima-18-30';

function getDefaultAsset() {
    return {
        'name': '18-30 - Set 3',
        'description': null,
        'tags': ['takima-forum-set-3-18-30'],
        'sets': [{
            'sections': [
                {
                    'type': 'NORMAL',
                    'label': 'Du Franchouillard',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [
                        {
                            'title': 'Pour que tu m\'aimes encore',
                            'artist': 'Céline Dion',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': ['celine'], 'title': ['tu m\'aimes encore'], 'lyrics': []},
                            'ageRange': {'start': 45, 'end': 60}
                        },
                        {
                            'title': 'Alors on danse',
                            'artist': 'Stromae',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': [], 'title': [], 'lyrics': []},
                            'ageRange': {'start': 25, 'end': 40}
                        },
                        {
                            'title': 'Santiano',
                            'artist': 'Hugues Aufray',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': ['H. Aufray'], 'title': [], 'lyrics': []},
                            'ageRange': {'start': 25, 'end': 60}
                        }
                    ]
                }, {
                    'type': 'LYRICS',
                    'label': 'Séries TV',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': 'Nom de la série'},
                    'songs': [

                        {
                            'title': 'My Life Is Going On',
                            'artist': 'Cecilia Krull',
                            'lyrics': 'La Casa de Papel',
                            'type': 'LYRICS',
                            'alt': {
                                'artist': [],
                                'title': [],
                                'lyrics': ['Casa de papel', 'casa del papel', 'Money Heist', 'La maison de papier']
                            },
                            'ageRange': {'start': 18, 'end': 45}
                        },
                        {
                            'title': 'Malcolm Theme',
                            'artist': 'They Might Be Giants',
                            'lyrics': 'Malcolm',
                            'type': 'LYRICS',
                            'alt': {'artist': [], 'title': [], 'lyrics': ['Malcolm in the middle']},
                            'ageRange': {'start': 25, 'end': 60}
                        },
                    ]
                },
            ]
        }]
    };
}

function getPitchStartFranchouillard() {
    return 'On attaque avec la section "Franchouillard !", que de la chanson française, à l\'ancienne.\n\n' +
        'Bien penser à répondre dans l\'appli directement, tout le monde est prêt ? C\'est parti !\n' +
        '\n';
}

function getPitchCeline() {
    return getSongAnswerAdvice() +
        '*Une artiste Canadienne internationale, je ne la présente pas...*\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getPitchStrom() {
    return getSongAnswerAdvice() +
        '*Célèbre artiste belge, qui a recemment sorti un album, grand pote de Orelsan*\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getSectionTVShows() {
    return 'On continue avec une section Séries TV. Accrochez vous !\n\n' +
        'Il va falloir uniquement entrer le nom de la série';
}

function getPitchCasa() {
    return getSongAnswerAdvice() +
        '*Super série Netflix sur laquelle on s\'est tous gavés il y a quelques années.*\n\n' +
        '\n\nC\'est parti pour la dernière';
}

function getPitchMalcolm() {
    return getSongAnswerAdvice() +
        '*Série anthologique avec une famille américaine et ses 4 enfants !*\n\n';
}


function getPitchStartJokers() {
    return getSongAnswerAdvice() +
        '*Bon franchement, hyper connu même si cest pas simple de retrouver l\'artiste...*\n\n\n\n' +
        getJokerAdvice();
}

function getPitchBritney() {
    return '[Fin de la chanson]\n' +
        'L\'artiste pop américaine la plus connue des années 2000. Elle est notamment sortie avec Justin Timberlake, avait promis qu\'elle resterait vierge jusqu\'au mariage, et a fini par se raser la tête...' +
        getLeaderboardAdvice();

}

export function computeTakimaDemoSetForum3() {
    return new DemoSet('takima-forum-set-3-18-30',
        'fr',
        'Jeune (18-30 ans) Set 3',
        getDefaultAsset(),
        [
            new DemoStep(2,
                [new WidgetText(getPitchInscription()), new WidgetVideoControl()],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Faire rejoindre la partie'),
            new DemoStep(3,
                [new WidgetText(getPitchStartFranchouillard()), new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691495070/rendition/1080p/set_3_-_01_pour_que_tu_m_aimes_encore%20%281080p%29.mp4?loc=external&signature=e836abd95b7e91c1e95c415919f80629de890f5da3de8b13cf3a460f710cc36c', true),
                    new ActionNext()
                ],
                'Lancer la Partie'),
            new DemoStep(4,
                [new WidgetText(getPitchCeline()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/771545850/rendition/720p/alpha_alors_on_danse%20%28720p%29.mp4?loc=external&signature=b271a7c6e5f7206e5f5f43c9e1468a27fe814e4499b028ddce5ee27bcb5d99b2', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(5,
                [new WidgetText(getPitchStrom()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/576285100/rendition/720p/santiano%20%28720p%29.mp4?loc=external&signature=c9bb9e55f2c308ba52bcc6b2f789100fe31e38d95a83bc8945f96d8ddc395468', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(6,
                [new WidgetText(getPitchStartJokers()), new WidgetQuizGame(), new WidgetVideoControl()],
                [
                    new ActionEnableJokers(),
                    new ActionNextItem(),
                    new ActionNext(),
                ],
                'Activer les jokers'),
            new DemoStep(7,
                [new WidgetText(getSectionTVShows())],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/576268687/rendition/720p/charlie_-_la_casa_de_papel%20%28720p%29.mp4?loc=external&signature=bba042131787a12cfe47d732b3cf5e542781646b7124ffe6003be24295d25131', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(8,
                [new WidgetText(getPitchCasa()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/771545551/rendition/1080p/alpha_malcom%20%281080p%29.mp4?loc=external&signature=9d7da17764241c4e41a8ea9cdbfd2d30c4d2d53a2db6adcc1f615add3326d925', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),

            new DemoStep(9,
                [new WidgetText(getPitchMalcolm()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Montrer les scores'),
            new DemoStep(10,
                [new WidgetText(getPitchGagnant()), new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionNext()
                ],
                'Closing'),
            new DemoStep(11,
                [new WidgetText(getPitchTakima())],
                [
                    new ActionTerminateDemo()
                ],
                'Terminer la démo'),
        ]
    );
}
