<div class="main-container">
  <img class="avatar" src="{{player.avatar}}" alt="{{player.name}}">
  <div class="container">
    <div class="header-container">
      <h4 class="name">{{player.name}}</h4>
      <div class="mention" *ngIf="mention === 'fastest'">Le plus rapide !</div>
      <div class="mention" *ngIf="mention === 'slowest'">Le... plus lent !</div>
      <div class="mention" *ngIf="mention === 'winner'">Le seul gagnant !</div>
      <div class="mention" *ngIf="mention === 'loser'">Le seul... perdant !</div>
    </div>
    <div class="comment" *ngIf="mention === 'winner'">est le seul à avoir eu tout juste</div>
    <div class="comment" *ngIf="mention === 'loser'">est le seul à avoir eu tout faux</div>
    <div class="comment" *ngIf="mention === 'fastest' && isCorrect()">
      respect: Le Plus Rapide ET 100% Correct
    </div>
    <div class="comment" *ngIf="mention === 'fastest' && isPartiallyCorrect()">...et seulement la moitié des points !
    </div>
    <div class="comment" *ngIf="mention === 'fastest' && isIncorrect()">...Et le plus rapide à se louper aussi!
    </div>
    <div class="comment" *ngIf="mention === 'slowest' && isCorrect()">Être le plus lent ça paye : 100% Correct</div>
    <div class="comment" *ngIf="mention === 'slowest' && isPartiallyCorrect()">... WoOps!</div>
    <div class="comment" *ngIf="mention === 'slowest' && isIncorrect()">... Et tout est faux !
    </div>
  </div>
</div>
