
export enum DemoWidgetType {
    TEXT = 'text',
    PRE_GAME_DEMO_SET_PICKER = 'pre_game_demo_set_picker',
    QUIZ_PLAYER_COUNTER = 'player_count',
    QUIZ_GAME_WIDGET = 'quiz_game_widget',
    LEADERBOARD_WIDGET = 'quiz_leaderboard_widget',
    LOCK_SHOW_SONG_BUTTONS_WIDGET= 'lock_show_buttons_widget',
    REVIEW_WIDGET = 'review_widget',
    SUBMIT_REVIEW_WIDGET = 'submit_review_widget',
    STATS_WIDGET = 'stats_widget',
    QUIZ_SUBMISSIONS_COUNTER = 'QUIZ_SUBMISSIONS_COUNTER',
    VIDEO_CONTROL = 'video_control',
}

export interface DemoStepWidget {
    type: DemoWidgetType;
}
