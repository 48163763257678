import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DemoContext} from '../../../../../core/models/demo/demoContext';
import {Subscription} from 'rxjs';
import {WidgetDemoSubmissionsCounter} from '../../../../../core/models/demo/widgets/widgetDemoSubmissionsCounter';
import {ReviewService} from '../../../../../core/services/review/review.service';
import {unsubscribe} from '../../../../../core/handler/subscription-handler';
import {PlayerService} from '../../../../../core/services/player/player.service';

@Component({
    selector: 'app-widget-demo-submissions-counter',
    templateUrl: './widget-demo-submissions-counter.component.html',
    styleUrls: ['./widget-demo-submissions-counter.component.scss'],
})
export class WidgetDemoSubmissionsCounterComponent implements OnInit, OnDestroy {
    @Input()
    ctx: DemoContext;
    @Input()
    widget: WidgetDemoSubmissionsCounter;
    private submissionCountSubscription: Subscription;
    private reviewCountSubscription: Subscription;
    private playerCountSubscription: Subscription;
    submissionCount: number;
    reviewCount: number;
    playerCount: number;

    constructor(private reviewService: ReviewService, private playerService: PlayerService) {
    }

    ngOnInit(): void {
        this.playerCountSubscription = this.playerService.playerCount().subscribe(pc => {
            this.playerCount = pc;
        });
        this.submissionCountSubscription = this.reviewService.songSubmissionCount().subscribe(submissionCount => {
            this.submissionCount = submissionCount;
        });
        this.reviewCountSubscription = this.reviewService.songReviewsCount().subscribe(reviewCount => {
            this.reviewCount = reviewCount;
        });
    }

    get remainingItemsToReview() {
        return this.submissionCount - this.reviewCount;
    }

    ngOnDestroy() {
        unsubscribe(this.submissionCountSubscription, this.reviewCountSubscription, this.playerCountSubscription);
    }
}
