import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../organization/organization.service';
import {Opportunity} from '../../models/opportunity/opportunity';
import {ErrorHandler} from '../../handler/error-handler';
import {OpportunityCreationDescriptor} from '../../models/opportunity/opportunityCreationDescriptor';
import {CrmOpportunity} from '../../models/opportunity/crmOpportunity';
import {OpportunityEditionDescriptor} from '../../models/opportunity/opportunityEditionDescriptor';
import {QuoteDescriptor} from '../../models/opportunity/quoteDescriptor';

@Injectable({
    providedIn: 'root',
})
export class OpportunityService {

    constructor(
        private http: HttpClient,
        private errorHandler: ErrorHandler,
        private organizationService: OrganizationService
    ) {
    }

    save(descriptor: OpportunityCreationDescriptor): Observable<void> {
        return this.http
            .post<void>(
                `manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity`,
                descriptor
            )
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    searchCrm(search: string): Observable<CrmOpportunity[]> {
        return this.http
            .get<CrmOpportunity[]>(
                `manager://admin/opportunity/crm/search/${search}`
            )
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    find(id: string) {
        return this.http
            .get<Opportunity>(
                `manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}`
            )
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    findAll(): Observable<Opportunity[]> {
        return this.http
            .get<Opportunity[]>(
                `manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity`
            )
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    update(descriptor: Opportunity): Observable<void> {
        return this.http
            .put<void>(
                `manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${
                    descriptor.id
                }/status/${descriptor.status}`, null
            )
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    edit(descriptor: OpportunityEditionDescriptor): Observable<void> {
        return this.http
            .put<void>(
                `manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${descriptor.id}`, descriptor)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    delete(id: string): Observable<void> {
        return this.http
            .delete<void>(
                `manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${
                    id
                }`
            )
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    validateDraft(id: string): Observable<void> {
        return this.http.put<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}/validate-draft`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    confirm(id: string, quoteId: string): Observable<void> {
        return this.http.put<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}/confirm/${quoteId}`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    duplicate(id: string) {
        return this.http.post<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}/duplicate`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    deleteQuote(opportunityId: string, id: string) {
        return this.http
            .delete<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${opportunityId}/quotes/${id}`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    duplicateQuote(opportunityId: string, id: string) {
        return this.http.post<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${opportunityId}/quotes/${id}/duplicate`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    saveQuote(opportunityId: string, qc: QuoteDescriptor) {
        return this.http.put<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${opportunityId}/quotes/${qc.id}`, qc)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    setToDraft(id: string) {
        return this.http.put<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}/back-to-draft`, null)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    lose(id: string) {
        return this.http.delete<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}/cancel?lose=true`)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }

    editDate(id: string, o: { dateOfShow: string; timeOfShow: string }) {
        return this.http.put<void>(`manager://manager/org/${this.organizationService.getOrganizationTagSnapshot()}/opportunity/${id}/date`, o)
            .pipe(this.errorHandler.retryThreeTimesOrError());
    }
}
