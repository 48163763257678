import {Article} from './article';
import {Bundle} from './bundle';
import {ParseUtils} from '../../utils/parse-utils';

export class Experience {
    tag: string;
    articles: Article[];
    bundles: Bundle[];
    label: string;


    constructor(tag?: string, articles?: Article[], bundles?: Bundle[], label?: string) {
        this.tag = tag;
        this.articles = articles;
        this.bundles = bundles;
        this.label = label;
    }

    static copy(e: Experience): Experience {
        return new Experience(e.tag, e.articles?.map(a => Article.copy(a)), e.bundles?.map(b => Bundle.copy(b)), e.label);
    }
}
