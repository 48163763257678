import {Component, OnInit} from '@angular/core';
import {DemoService} from '../../core/services/demo/demo.service';
import {DemoContext} from '../../core/models/demo/demoContext';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    ctx: DemoContext;

    constructor(private demoService: DemoService) {
    }

    ngOnInit(): void {
        this.demoService.currentDemoContext().subscribe(ctx => {
            this.ctx = ctx;
        });
    }

}
