import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DemoContext} from '../../../../../core/models/demo/demoContext';
import {WidgetDemoPlayerCounter} from '../../../../../core/models/demo/widgets/widgetDemoPlayerCounter';
import {Subscription} from 'rxjs';
import {PlayerService} from '../../../../../core/services/player/player.service';
import {unsubscribe} from '../../../../../core/handler/subscription-handler';

@Component({
    selector: 'app-widget-demo-player-counter',
    templateUrl: './widget-demo-player-counter.component.html',
    styleUrls: ['./widget-demo-player-counter.component.scss'],
})
export class WidgetDemoPlayerCounterComponent implements OnInit, OnDestroy {
    @Input()
    ctx: DemoContext;
    @Input()
    widget: WidgetDemoPlayerCounter;
    private playersSubscription: Subscription;
    playerCount: number;

    constructor(private playerService: PlayerService) {
    }

    ngOnInit(): void {
        this.playersSubscription = this.playerService.playerCount().subscribe(playerCount => {
            this.playerCount = playerCount;
        });

    }

    ngOnDestroy(): void {
        unsubscribe(this.playersSubscription);
    }
}
