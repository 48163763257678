<div class="container">
  <div class="content-1">
    <br/>
    <div class="container">
      <mat-card>
        <h2 i18n="@@homeDashboardTitle">DemoTime</h2>
        <form (ngSubmit)="onSubmit()" [formGroup]="orgForm" class="full-width">
          <mat-form-field class="mat-headline">
            <input matInput placeholder="PIN Partenaire" formControlName="organization" class="capitalize" required/>
            <mat-error *ngIf="organization.hasError('required')" i18n="@@homeGameIdRequiredError">
              Nom de votre organisation
            </mat-error>
          </mat-form-field>
          <br/>
          <div class="centered">
            <button mat-raised-button color="primary" type="submit" [disabled]="!orgForm.valid" i18n="@@homeLaunchDashboard">Accéder à mon espace
            </button>
          </div>
          <div [hidden]="!error" class="alert alert-danger" i18n="@@homeUnknownError">
            <br/>
            Une erreur s'est produite.<br/>Votre organisation est-elle valide ?
          </div>
        </form>
        <mat-card-footer>
          <a routerLink="/register">Créer un compte</a>
        </mat-card-footer>
      </mat-card>
    </div>
  </div>
</div>
