<div class="container">

    <div class="padded-container" *ngIf="lang">
        <h1 *ngIf="lang === 'fr'">La réponse était</h1>
        <h1 *ngIf="lang === 'en'">The answer was</h1>

        <div class="answer-container" *ngIf="song?.type === 0">
            <div class="answer">{{song?.artist}}</div>
            <div class="answer">{{song?.title}}</div>
        </div>
        <div class="answer-container" *ngIf="song?.type === 1">
            <div class="answer">{{song?.lyrics}}</div>
        </div>
    </div>

</div>
