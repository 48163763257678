import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Bundle} from '../../../../core/models/opportunity/bundle';
import {Article} from '../../../../core/models/opportunity/article';
import {MyErrorStateMatcher} from '../opportunity-edition.component';
import {OpportunityPanelUpdate} from './opportunityPanelUpdate';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {OrganizationService} from '../../../../core/services/organization/organization.service';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {Claims, RoleClaim, User} from '@frogconnexion/core-common';

@Component({
    selector: 'app-opportunity-main',
    templateUrl: './opportunity-main.component.html',
    styleUrls: ['./opportunity-main.component.scss']
})
export class OpportunityMainComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    opportunityCreationForm = new UntypedFormGroup({
        'exonerated': new UntypedFormControl(false, [])
    });

    @Input()
    user: User;
    @Output()
    quoteUpdated = new EventEmitter<OpportunityPanelUpdate>();
    @Input()
    exonerated: Boolean;

    bundles: Bundle[] = [];
    price = 0;
    options: Article[] = [];
    matcher = new MyErrorStateMatcher();
    private subscriptions: Subscription[] = [];

    constructor(private organizationService: OrganizationService) {

    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateView();
    }

    exoneratedForm() {
        return this.opportunityCreationForm.get('exonerated');
    }

    private updateView() {
        if (this.exoneratedForm().value !== this.exonerated) {
            this.exoneratedForm().setValue(this.exonerated);
        }
    }

    isAdminOrManager(): boolean {
        return this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_MANAGER, this.organizationService.getOrganizationTagSnapshot())]);
    }

    setVatExonerated(cb: MatCheckboxChange) {
        this.quoteUpdated.next(new OpportunityPanelUpdate(cb.checked));
    }
}
