import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spinner-square',
  templateUrl: './spinner-square.component.html',
  styleUrls: ['./spinner-square.component.scss']
})
export class SpinnerSquareComponent implements OnInit {

  @Input()
  online: boolean;

  constructor() { }

  ngOnInit() {
  }

}
