import {Component, Input, OnInit} from '@angular/core';
import {SectionImportDescriptor} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-blindtest-content-section',
  templateUrl: './blindtest-content-section.component.html',
  styleUrls: ['./blindtest-content-section.component.scss']
})
export class BlindtestContentSectionComponent implements OnInit {

  @Input()
  section: SectionImportDescriptor;

  constructor() { }

  ngOnInit(): void {
  }

}
