import {Constraint} from './constraint';
import {ConstraintType} from './constraintType';

export class ConstraintMinPax implements Constraint {
    type = ConstraintType.PAX_MIN;
    value: number;

    constructor(value: number) {
        this.value = value;
    }

    matches(value: number, ignorePaxWhenNull: boolean): boolean {
        return ignorePaxWhenNull || ((value || 0) >= this.value);
    }
}
