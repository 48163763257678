import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../../core/services/organization/organization.service';
import {OpportunityService} from '../../../../core/services/opportunity/opportunity.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {Claims, Organization, RoleClaim, User} from '@frogconnexion/core-common';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';
import {OrganizationType} from '../../../../core/models/organization/organizationType';

@Component({
    selector: 'app-quote-header',
    templateUrl: './quote-header.component.html',
    styleUrls: ['./quote-header.component.scss']
})
export class QuoteHeaderComponent implements OnInit, OnDestroy {

    opportunityCreationForm = new UntypedFormGroup({
        'company': new UntypedFormControl('', [Validators.required]),
        'date': new UntypedFormControl(undefined, []),
        'hour': new UntypedFormControl('', []),
        'minute': new UntypedFormControl('', []),
        'crmid': new UntypedFormControl('', []),
    });

    @Input()
    companyName: string;

    @Input()
    date: string;
    @Input()
    crmId: string;
    @Input()
    time: string;
    @Input()
    organization: Organization;

    @Output()
    saveQuote = new EventEmitter<boolean>();
    @Input()
    otherFormsValid = true;
    private subscriptions: Subscription[] = [];
    @Input()
    user: User;

    constructor(private organizationService: OrganizationService,
                private userService: FirebaseAuthService,
                private opportunityService: OpportunityService,
                private router: Router) {
    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {

    }

    onSubmit() {
        this.saveQuote.next(true);
    }

    onReturn() {
        this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
    }

    isAdminOrManager(): boolean {
        return this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_MANAGER, this.organizationService.getOrganizationTagSnapshot())]);
    }

    isInternalOrganization(): boolean {
        return this.organization?.getFrogManagerOrganizationType() === OrganizationType.PROVIDER;
    }

}
