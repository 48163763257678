import {NgModule} from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';

import {FooterComponent} from './components/footer/footer.component';
import {NavbarComponent} from './components/navbar/navbar.component';
import {ValidationDialogComponent} from './components/dialog/validation-dialog/validation-dialog.component';
import {LoggerConfig} from './services/logger/logger.config';
import {environment} from '../../../environments/environment';
import {AuthModule} from './auth/auth.module';
import {MaterialModule} from './material/material.module';
import {LoadingComponent} from './components/loading/loading.component';
import {parse as ParseLogLevel} from './services/logger/loglevel';
import {GameControlsService} from './services/gamecontrols/gamecontrols.service';

@NgModule({
    declarations: [
        FooterComponent,
        NavbarComponent,
        ValidationDialogComponent,
        LoadingComponent,
    ],
    exports: [
        FooterComponent,
        NavbarComponent,
        MaterialModule,
        ValidationDialogComponent,
        LoadingComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        AuthModule,
        RouterModule,
        HttpClientModule,
    ],
    providers: [
        { provide: LoggerConfig, useValue: ParseLogLevel(environment.loggerConfig) },
        GameControlsService
    ]
})
export class CoreModule {
}
