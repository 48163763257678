export class OpportunityHeaderUpdate {
    company: string;
    crmId: string;
    crmIdent: string;
    isFromCrm: boolean;
    dateIso: string;
    hour: number;
    minute: number;

    constructor(company: string, crmId: string, crmIdent: string, isFromCrm: boolean, date: string, hour: number, minute: number) {
        this.company = company;
        this.crmId = crmId;
        this.crmIdent = crmIdent;
        this.isFromCrm = isFromCrm;
        this.dateIso = date;
        this.hour = hour;
        this.minute = minute;
    }
}
