import {Article} from './article';
import {ExperienceType} from './experienceType';

export class QuoteDto {
    id: string;
    experienceTag: string;
    type: ExperienceType;
    bundleTag: string;
    paxCount: number;
    lang: string;
    articles: {
        mandatory: Article[],
        optional: {article: Article, selected: boolean}[],
        excluded: Article[]
    };

    constructor(id: string, experienceTag: string, type?: ExperienceType, bundleTag?: string, paxCount?: number, lang?: string,
                articles?: { mandatory: Article[]; optional: { article: Article; selected: boolean }[]; excluded: Article[] }) {
        this.id = id;
        this.experienceTag = experienceTag;
        this.type = type;
        this.bundleTag = bundleTag;
        this.paxCount = paxCount;
        this.lang = lang;
        this.articles = articles;
    }

    clone() {
        return new QuoteDto(this.id, this.experienceTag, this.type, this.bundleTag, this.paxCount, this.lang, this.articles);
    }
}
