<div class="container">
    <mat-card class="content">
        <mat-card-title>Une démo est en cours</mat-card-title>
        <mat-card-content>
            <p class="center">Pour une démo de qualité optimale, nous recommandons d'utiliser un ordinateur avec Chrome + <b>Google Meet</b> avec la fonction <b>Partager un onglet</b>.
                <br/>Alternativement, vous pouvez aussi utiliser Chrome + <b>Zoom</b> avec le partage d'écran + la checkbox <b>Optimiser pour le clip vidéo</b>
            </p>
            <div class="hint">
                <p>
                    Cette page vous permet de piloter la démo et d'avoir le pitch de vente sous les yeux.
                    <br/>
                    Vous pouvez l'utiliser sur mobile, tablette, ou sur ordi.
                </p>

            </div>
            <button mat-raised-button color="primary" routerLink="./control">Page Contrôle Démo/Pitch</button>
            <div class="hint hide-mobile">
                Le lien suivant ouvre un nouvel onglet de démo.
                <p>
                <br/>Pour que cela fonctionne, vous devez être sur un navigateur Google Chrome sur ordi.
                <br/>C'est cet onglet que vous devrez afficher via partage d'écran en visio.
                </p>
            </div>
            <a mat-raised-button color="primary" routerLink="./screen" target="_blank" class="hide-mobile">Onglet de Démo (à afficher)</a>
            <button mat-raised-button color="warn" (click)="stopDemo()">Stopper la Démo</button>
        </mat-card-content>
    </mat-card>
</div>
