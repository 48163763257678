<app-demo-summary-container [demoOngoing]="!!ctx"></app-demo-summary-container>
<br/>
<div class="container dark">
    <div class="header-container">
        <h1> Vos Opportunités </h1>
        <button class="hide-mobile" mat-raised-button color="accent" routerLink="opportunity/new">Nouvelle Opportunité</button>
    </div>

    <div class="show-mobile center">
        Les opportunités sont uniquement consultables sur Ordinateur
    </div>
    <div class="hide-mobile">
        <app-opportunity-pipeline></app-opportunity-pipeline>
    </div>

</div>
