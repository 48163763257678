import {Constraint, constraintMatch, copyConstraint} from './constraint';
import {Bundle} from './bundle';
import {ParseUtils} from '../../utils/parse-utils';
import {ConstraintType} from './constraintType';

export class BundleItem {

    constructor(ref: string, constraints?: Constraint[], optionConstraints?: Constraint[]) {
        this.ref = ref;
        this.constraints = constraints || [];
        this.optionConstraints = optionConstraints;
    }
    ref: string;
    constraints: Constraint[];
    optionConstraints: Constraint[];

    static copy(bi: BundleItem): BundleItem {
        return new BundleItem(bi.ref, bi.constraints?.map(c => copyConstraint(c)), bi.optionConstraints?.map(c => copyConstraint(c)));
    }

    isOptional(context: any): boolean {
        if (!this.optionConstraints) {
            return false;
        }
        return constraintMatch(context, ...this.optionConstraints);
    }
}
