import {Component, OnDestroy, OnInit} from '@angular/core';
import {DemoService} from '../../../core/services/demo/demo.service';
import {DemoContext} from '../../../core/models/demo/demoContext';
import {Observable, of, Subscription} from 'rxjs';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {DemoState} from '../../../core/models/demo/demoState';
import {DemoWidgetType} from '../../../core/models/demo/demoStepWidget';
import {DemoStepActionType} from '../../../core/models/demo/demoStepAction';
import {mergeMap, tap} from 'rxjs/operators';
import {WidgetDemoSetPickerData} from '../../../core/models/demo/widgets/data/widgetDemoSetPickerData';
import {WidgetDemoSetPickerDataValidator} from '../../../core/models/demo/widgets/data/widgetDemoSetPickerDataValidator';
import {GameService} from '../../../core/services/game/game.service';
import {Gameplay} from '@frogconnexion/blinding-common';
import {DemoStepWidgetDataValidator} from '../../../core/models/demo/demoStepWidgetDataValidator';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {DemoGameDescriptor} from '../../../core/models/demo/dtos/demoGameDescriptor';
import {GameControlsService} from '../../../core/services/gamecontrols/gamecontrols.service';
import {Song} from '@frogconnexion/blinding-common';
import {GameControls} from '@frogconnexion/blinding-common';
import {SongPendingReview} from '@frogconnexion/blinding-common';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {Blindtest} from '@frogconnexion/blinding-common';
import {BlindtestService} from '../../../core/services/blindtest/blindtest.service';
import {SetOfBlindtest} from '@frogconnexion/blinding-common';
import {GamePublicControlsService} from '../../../core/services/gamepubliccontrols/gamepubliccontrols.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ActionLaunchVideo} from '../../../core/models/demo/actions/actionLaunchVideo';
import {ShowtimeService} from '../screen/showtime.service';
import {computeKeyHashFromUrl} from '../models/BlobContentConfig';
import {NavigationService} from '../../../core/services/navigation/navigation.service';
import {WidgetQuizGameDataValidator} from '../../../core/models/demo/widgets/data/widgetQuizGameDataValidator';
import {WidgetQuizGameData} from '../../../core/models/demo/widgets/data/widgetQuizGameData';

@Component({
    selector: 'app-control',
    templateUrl: './control.component.html',
    styleUrls: ['./control.component.scss'],
})
export class ControlComponent implements OnInit, OnDestroy {

    private ctx: DemoContext;
    private state: DemoState;
    private ctxSubscription: Subscription;
    private currentBlindtestSubscription: Subscription;
    private stateSubscription: Subscription;
    private currentGameStateSubscription: Subscription;
    private currentGameMetadataSubscription: Subscription;
    private currentAdminSongSubscription: Subscription;
    private data: { [type: string]: { data: any, validator: DemoStepWidgetDataValidator<any> } } =
        {
            [DemoWidgetType.PRE_GAME_DEMO_SET_PICKER]: {data: {}, validator: new WidgetDemoSetPickerDataValidator()},
            [DemoWidgetType.QUIZ_GAME_WIDGET]: {data: {}, validator: new WidgetQuizGameDataValidator()}
        };
    private enableJokersSubscription: Subscription;

    currentGameState: GameControls;
    jokersEnabled: boolean;
    currentSong: Song;
    hasCurrentGame: boolean;
    currentGameMetadata: GameMetadata;
    blindtest: Blindtest;
    blindSets: SetOfBlindtest[];
    pendingReviews: SongPendingReview[];
    solvedPendingReviews: SongPendingReview[];
    showPitch = true;

    constructor(
        private organizationService: OrganizationService,
        private demoService: DemoService,
        private gameService: GameService,
        private gameControlsService: GameControlsService,
        private gamePublicControlsService: GamePublicControlsService,
        private router: Router,
        private navigationService: NavigationService,
        private dialog: MatDialog,
        private blindingService: OrganizationService,
        private blindtestService: BlindtestService,
        private showtimeService: ShowtimeService,
    ) {

    }

    ngOnInit(): void {

        this.navigationService.updateNavHeader('Contrôle Démo');

        // Get current blind test
        this.currentBlindtestSubscription = this.blindtestService.currentBlindtest()
            .subscribe(bt => {
                this.blindtest = bt;
                this.blindSets = bt ? bt.sets : [];
                this.updateHasCurrentGame();
            });

        this.ctxSubscription = this.demoService.currentDemoContext().subscribe(dc => {
            this.ctx = dc;
        });

        this.stateSubscription = this.demoService.currentDemoState().subscribe(ds => {
            this.state = ds;
        });

        this.currentGameStateSubscription = this.gameService.currentGameControls()
            .subscribe(state => {
                this.currentGameState = state;
                this.updateHasCurrentGame();
            });

        this.enableJokersSubscription = this.gamePublicControlsService.enableJokers().subscribe(show => {
            this.jokersEnabled = show;
        });

        this.currentAdminSongSubscription = this.gameService.currentSong().subscribe(
            song => {
                this.currentSong = song;
            });
        this.currentGameMetadataSubscription = this.gameService.currentGameMetadata()
            .subscribe(gm => {
                this.currentGameMetadata = gm;
            });
    }

    ngOnDestroy(): void {
        unsubscribe(this.ctxSubscription,
            this.stateSubscription,
            this.currentGameStateSubscription,
            this.enableJokersSubscription);
    }

    updateHasCurrentGame() {
        this.hasCurrentGame = !!(this.currentGameMetadata && this.currentGameState && this.blindtest);
    }

    get currentStep() {
        const step = this.state?.currentStep;
        if (!this.state || !this.ctx || !this.ctx.experience || step === undefined) {
            return null;
        }
        const preGameStepCount = this.ctx.experience.preGameSteps[this.ctx.metadata.lang].length;
        if (step < preGameStepCount) {
            return this.ctx?.experience.preGameSteps[this.ctx.metadata.lang][step];
        }
        if (!this.state.demoSetTag) {
            return null;
        }
        return this.ctx?.experience?.demoSets.find(set => set.tag === this.state.demoSetTag && set.lang === this.ctx.metadata.lang).steps[step - preGameStepCount];
    }

    get hasReviewedEverything() {
        return this.getUnsolvedPendingReviewCount() === 0;
    }

    getUnsolvedPendingReviewCount() {
        return this.pendingReviews?.map(pr => pr.key)
            .filter(prId => this.solvedPendingReviews?.map(pr => pr.key).indexOf(prId) === -1).length || 0;
    }

    isTextWidget(type: DemoWidgetType) {
        return type === DemoWidgetType.TEXT;
    }

    isDemoSetPickerWidget(type) {
        return type === DemoWidgetType.PRE_GAME_DEMO_SET_PICKER;
    }

    isDemoPlayerCounterWidget(type) {
        return type === DemoWidgetType.QUIZ_PLAYER_COUNTER;
    }

    isQuizGameWidget(type) {
        return type === DemoWidgetType.QUIZ_GAME_WIDGET;
    }

    isLeaderboardWidget(type) {
        return type === DemoWidgetType.LEADERBOARD_WIDGET;
    }

    isStat(type) {
        return type === DemoWidgetType.STATS_WIDGET;
    }


    goToNextStep(): Observable<void> {
        return this.demoService.goToNextStep();
    }

    startGame() {
        return this.gameService.startCurrentGame();
    }

    isActionEnabled(): boolean {
        let enabled = true;
        for (const a of this.currentStep.actions) {
            switch (a.type) {
                case DemoStepActionType.SET_GAME:
                    const t = DemoWidgetType.PRE_GAME_DEMO_SET_PICKER;
                    enabled = enabled && this.data[t]?.validator.validate(this.data[t].data);
                    break;
                case DemoStepActionType.NEXT:
                    const quizGameType = DemoWidgetType.QUIZ_GAME_WIDGET;
                    if (this.currentStep.widgets.find(w => w.type === quizGameType)) {
                        enabled = enabled && this.data[quizGameType]?.validator.validate(this.data[quizGameType].data);
                    }
                    break;

            }
        }
        return enabled;
    }

    processActions() {
        let o: Observable<any> = of(true);
        for (const a of this.currentStep.actions) {
            switch (a.type) {
                case DemoStepActionType.NEXT:
                    o = o.pipe(mergeMap(() => this.goToNextStep()));
                    break;
                case DemoStepActionType.NEXT_ITEM:
                    o = o.pipe(mergeMap(() => this.goToNextItem()));
                    break;
                case DemoStepActionType.SET_GAME:
                    o = o.pipe(mergeMap(() => this.setGame()));
                    break;
                case DemoStepActionType.ONBOARD_PLAYERS:
                    o = o.pipe(mergeMap(() => this.onboardPlayers()));
                    break;
                case DemoStepActionType.LAUNCH_VIDEO:
                    o = o.pipe(mergeMap(() => this.launchVideo((a as ActionLaunchVideo).url)));
                    break;
                case DemoStepActionType.START_GAME:
                    o = o.pipe(mergeMap(() => this.startGame()));
                    break;
                case DemoStepActionType.UNSET_GAME:
                    o = o.pipe(mergeMap(() => this.unsetGame()));
                    break;
                case DemoStepActionType.SHOW_LEADERBOARD:
                    o = o.pipe(mergeMap(() => this.showLeaderboard()));
                    break;
                case DemoStepActionType.TERMINATE_DEMO:
                    o = o.pipe(mergeMap(() => this.terminateDemo()));
                    break;
                case DemoStepActionType.ENABLE_JOKERS:
                    o = o.pipe(mergeMap(() => this.enableJokers()));
            }
        }
        o.subscribe(() => {

        });
    }

    updateDemoSetWidgetData(d: WidgetDemoSetPickerData) {
        this.data[DemoWidgetType.PRE_GAME_DEMO_SET_PICKER].data = d;
    }

    updateQuizGameWidgetData(data: WidgetQuizGameData) {
        this.data[DemoWidgetType.QUIZ_GAME_WIDGET].data = data;
    }

    private setGame(): Observable<void> {
        const d = new DemoGameDescriptor(
            this.data[DemoWidgetType.PRE_GAME_DEMO_SET_PICKER].data.blindtestId,
            this.ctx.metadata.company,
            Gameplay.FasterStrongerPerSet,
            this.ctx.metadata.lang,
            null,
            this.data[DemoWidgetType.PRE_GAME_DEMO_SET_PICKER].data.demoSetTag,
            this.ctx.experience.demoSets
                .find(ds => ds.tag === this.data[DemoWidgetType.PRE_GAME_DEMO_SET_PICKER].data.demoSetTag).steps.length
        );
        return this.demoService.activateGame(d);
    }

    private unsetGame() {
        return this.gameService.unsetCurrentGame();
    }

    private enableJokers() {
        return this.gameControlsService.setEnableJokers(!this.jokersEnabled);
    }

    private goToNextItem() {
        return this.gameControlsService.nextSong();
    }

    private showLeaderboard() {
        return this.gameControlsService.setShowLeaderboard(true);
    }

    private terminateDemo() {
        return this.demoService.deactivateDemo()
            .pipe(tap(() => {
                this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
            }));
    }

    private launchVideo(url: string): Observable<boolean> {
        const k = computeKeyHashFromUrl(url);
        return this.showtimeService.launchScreen(k);
    }

    private onboardPlayers() {
        return this.showtimeService.launchScreen('join-the-game');
    }

    isVideoControlWidget(type: DemoWidgetType) {
        return type === DemoWidgetType.VIDEO_CONTROL;
    }

    toggleShowPitch() {
        this.showPitch = !this.showPitch;
    }
}
