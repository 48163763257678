import {Component, Input, OnInit} from '@angular/core';
import {Player, SongResult} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-special-mentions',
  templateUrl: './special-mentions.component.html',
  styleUrls: ['./special-mentions.component.css']
})
export class SpecialMentionsComponent implements OnInit {
  @Input()
  soleWinner: Player;
  @Input()
  soleLoser: Player;
  @Input()
  fastest: Player;
  @Input()
  fastestResult: SongResult;
  @Input()
  slowest: Player;
  @Input()
  slowestResult: SongResult;

  constructor() { }

  ngOnInit(): void {
  }

}
