import {Component, OnDestroy, OnInit} from '@angular/core';
import {DemoService} from '../../../core/services/demo/demo.service';
import {of, Subscription} from 'rxjs';
import {DemoContext} from '../../../core/models/demo/demoContext';
import {DemoState} from '../../../core/models/demo/demoState';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {DemoStep} from '../../../core/models/demo/demoStep';
import {MediaLoaderService} from './utils/media-loader.service';
import {DemoStepAction, DemoStepActionType} from '../../../core/models/demo/demoStepAction';
import {ActionLaunchVideo} from '../../../core/models/demo/actions/actionLaunchVideo';
import {BlobContentConfig, computeKeyHashFromUrl} from '../models/BlobContentConfig';
import {BlobMediaLoading} from '../models/BlobMediaLoading';
import {catchError} from 'rxjs/operators';
import {MediaContentLoaded} from './utils/messaging/MediaContentLoaded';
import {ShowtimeService} from './showtime.service';
import {MediaSwitcherService} from './utils/media-switcher.service';
import {MediaPlayState} from '@frogconnexion/blinding-common';
import {GameService} from '../../../core/services/game/game.service';
import {GameMetadata, SetOfBlindtestStatus} from '@frogconnexion/blinding-common';
import {GameControls} from '@frogconnexion/blinding-common';
import {environment} from '../../../../../environments/environment';
import {BlobMediaDownloading} from '../models/BlobMediaDownloading';
import {NavigationService} from '../../../core/services/navigation/navigation.service';

@Component({
    selector: 'app-screen',
    templateUrl: './screen.component.html',
    styleUrls: ['./screen.component.scss']
})
export class ScreenComponent implements OnInit, OnDestroy {
    private ctxSubscription: Subscription;
    private stateSubscription: Subscription;
    private stepSubscription: Subscription;
    private currentlyOnScreenSubscription: Subscription;
    ctx: DemoContext;
    state: DemoState;
    step: DemoStep;
    showtimeLoaded: boolean;
    interactionMade = false;
    bmpl: BlobMediaLoading;
    bml: BlobMediaLoading;
    bmdl: BlobMediaDownloading;
    gm: GameMetadata;
    gc: GameControls;
    private subscriptions: Subscription[] = [];
    private latestMediaPlayStates: Map<string, MediaPlayState> = new Map();
    extensionId: string = environment.showtime.extensionId;
    private dlTimeout: any;

    constructor(private demoService: DemoService,
                private showtimeService: ShowtimeService,
                private gameService: GameService,
                private navigationService: NavigationService,
                private mediaLoaderService: MediaLoaderService,
                private mediaSwitcherService: MediaSwitcherService) {
    }

    ngOnInit(): void {
        this.navigationService.updateNavHeaderPrefix('Écran de démo à partager');
        this.mediaLoaderService.checkShowtimePresent()
            .pipe(catchError(() => of(false)))
            .subscribe(present => {
                this.showtimeLoaded = present;
                this.updateMediaLoading();
            });
        this.ctxSubscription = this.demoService.currentDemoContext().subscribe(dc => {
            this.ctx = dc;
            this.updateMediaLoading();
        });
        this.stateSubscription = this.demoService.currentDemoState().subscribe(ds => {
            this.state = ds;
            this.updateMediaLoading();
        });
        this.stepSubscription = this.demoService.currentDemoSetStep().subscribe(step => {
            this.step = step;
        });
        this.currentlyOnScreenSubscription = this.showtimeService.currentlyOnScreen().subscribe(key => {
            if (key) {
                if (!this.latestMediaPlayStates.has(key)) {
                    this.subscriptions.push(this.mediaSwitcherService.mediaStatus(key)
                        .subscribe(state => {
                            this.latestMediaPlayStates.set(key, state);
                        }));
                }
                this.mediaSwitcherService.mediaStatusNot(key).forEach(m => m.next(MediaPlayState.STOP_IMMEDIATELY));
                this.mediaSwitcherService.mediaStatus(key).next(MediaPlayState.START);
            }
        });
        this.subscriptions.push(this.gameService.currentGameMetadata().subscribe(gm => {
            this.gm = gm;
        }));
        this.subscriptions.push(this.gameService.currentGameControls().subscribe(gc => {
            this.gc = gc;
        }));
        this.showtimeService.listenToRemoteMediaStateChanges();
    }

    ngOnDestroy(): void {
        unsubscribe(this.ctxSubscription,
            this.stateSubscription,
            this.stepSubscription,
            this.currentlyOnScreenSubscription,
            ...this.subscriptions);
    }


    private prepareMediaForStep(config: BlobContentConfig, a: DemoStepAction) {
        if (a.type === DemoStepActionType.LAUNCH_VIDEO) {
            const v = a as ActionLaunchVideo;
            const key = computeKeyHashFromUrl(v.url);
            config.blobs.push({
                key: key,
                lang: this.ctx.metadata.lang,
                type: 'video',
                link: v.url,
                duration: -1,
                elementId: null
            });
            this.subscriptions.push(this.mediaSwitcherService.mediaStatus(key)
                .subscribe(state => this.latestMediaPlayStates.set(key, state)));
        }
    }

    private updateMediaLoading() {
        if (!this.ctx) {
            this.bml = null;
            this.bmdl = null;
        }
        if (this.ctx && !this.bmdl) {
            this.downloadMedia(this.ctx);
        }
        if (this.ctx && this.bmdl?.isCompleted() && this.state?.demoSetTag && !this.bml) {
            this.loadMedia(this.ctx);
        }
    }

    private loadMediaForPreSteps(ctx: DemoContext) {
        const config: BlobContentConfig = {
            organization: ctx.metadata.organization,
            blobs: [],
            lang: ctx.metadata.lang
        };
        ctx.experience.preGameSteps[ctx.metadata.lang].forEach(step => step.actions.forEach(a => this.prepareMediaForStep(config, a)));
        this.mediaLoaderService.loadMedia(config).subscribe(bml => {
            this.bmpl = bml;
            this.updateMediaLoading();
        });
    }

    private loadMedia(ctx: DemoContext) {
        const config: BlobContentConfig = {
            organization: ctx.metadata.organization,
            blobs: [],
            lang: ctx.metadata.lang
        };
        ctx.experience.demoSets.find(ds => this.state.demoSetTag === ds.tag && ds.lang === ctx.metadata.lang)?.steps.forEach(step => step.actions.forEach(a => {
                this.prepareMediaForStep(config, a);
            }));
        this.mediaLoaderService.loadMedia(config).subscribe(bml => {
            this.bml = bml;
        });
    }

    private downloadMedia(ctx: DemoContext) {
        const config: BlobContentConfig = {
            organization: ctx.metadata.organization,
            blobs: [],
            lang: ctx.metadata.lang
        };
        ctx.experience.preGameSteps[ctx.metadata.lang].forEach(step => step.actions.forEach(a => this.prepareMediaForStep(config, a)));
        ctx.experience.demoSets.forEach(ds => {
            ds.steps.forEach(step => step.actions.forEach(a => {
                this.prepareMediaForStep(config, a);
            }));
        });
        this.dlTimeout = setTimeout(() => {
            if (this.bmdl && !this.bmdl.isCompleted()) {
                window.location.reload();
            }
        }, 30000);
        this.mediaLoaderService.downloadMedia(config).subscribe(bml => {
            this.bmdl = bml;
            if (this.bmdl.isCompleted()) {
                clearTimeout(this.dlTimeout);
                this.loadMediaForPreSteps(this.ctx);
            }
        });
    }

    get videos(): { loaded: boolean, payload: MediaContentLoaded }[] {
        if (!this.bmpl) {
            return [];
        }
        const results = Object.keys(this.bmpl.state).map(k => this.bmpl.state[k]);
        if (!this.bml) {
            return results;
        }
        return [...results, ...Object.keys(this.bml.state).map(k => this.bml.state[k])];
    }

    fadeOutEnding(key) {
        return this.latestMediaPlayStates.get(key) === MediaPlayState.ENDING;
    }

    showVideo(key) {
        const s = this.latestMediaPlayStates.get(key);
        const r = this.latestMediaPlayStates.has(key) && (s === MediaPlayState.PLAYING
            || s === MediaPlayState.PAUSE
            || s === MediaPlayState.RESUME
            || s === MediaPlayState.FORWARD
            || s === MediaPlayState.BACKWARD
            || s === MediaPlayState.PAUSED
            || s === MediaPlayState.ENDING);
        return r || (s === MediaPlayState.END && this.getVideoAction(key)?.keepAfterEnd);
    }

    setInteractionDone() {
        this.interactionMade = true;
    }

    isGameStarted() {
        return this.gc?.blindtestControl?.status === SetOfBlindtestStatus.ONGOING;
    }

    get mediaLoadedCount(): number {
        return this.bml?.loadedCount() || 0;
    }

    get mediaTotalCount(): number {
        return this.bml?.totalCount() || 0;
    }

    get mediaPreLoadedCount(): number {
        return this.bmpl?.loadedCount() || 0;
    }

    get mediaPreTotalCount(): number {
        return this.bmpl?.totalCount() || 0;
    }

    get mediaDownloadedCount(): number {
        return this.bmdl?.loadedCount() || 0;
    }

    get mediaTotalDownloadCount(): number {
        return this.bmdl?.totalCount() || 0;
    }

    private getVideoAction(key): ActionLaunchVideo {
        if (!this.ctx?.experience) {
            return null;
        }
        let action = this.ctx.experience.preGameSteps[this.ctx.metadata.lang]
            .map(s => s.actions)
            .flat()
            .find(a => a.type === DemoStepActionType.LAUNCH_VIDEO && computeKeyHashFromUrl((a as ActionLaunchVideo).url) === key);
        if (!action) {
            action = this.ctx.experience.demoSets.map(ds => ds.steps)
                .flat()
                .map(s => s.actions)
                .flat()
                .find(a => a.type === DemoStepActionType.LAUNCH_VIDEO && computeKeyHashFromUrl((a as ActionLaunchVideo).url) === key);
        }
        return action as ActionLaunchVideo;
    }


    allIsGood() {
        return this.showtimeLoaded && ((!this.ctx && !this.bmdl && !this.bml) ||
            (this.ctx && !this.gm && this.bmdl?.isCompleted()) ||
            (this.ctx && this.gm && this.bml?.isCompleted()));
    }

    allIsGoodAndInteracted() {
        return this.interactionMade && this.allIsGood();
    }

    reload() {
        window.location.reload();
    }
}
