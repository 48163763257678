import {MediaContentLoaded} from '../screen/utils/messaging/MediaContentLoaded';

export class BlobMediaLoading {
    state: {[key: string]: {loaded: boolean, payload: MediaContentLoaded}} = {};

    isCompleted(): boolean {
        return Object.keys(this.state).filter(k => !this.state[k].loaded).length === 0;
    }

    isLoaded(key: string) {
        return !!this.state[key];
    }

    loadedCount(): number {
        return Object.keys(this.state).filter(k => this.state[k].loaded).length;
    }

    totalCount(): number {
        return Object.keys(this.state).length;
    }
}
