import {Injectable} from '@angular/core';

import {Observable, ReplaySubject, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Blindtest} from '@frogconnexion/blinding-common';
import {Build} from '@frogconnexion/core-common';
import {LoggerService} from '../logger/logger.service';
import {OrganizationService} from '../organization/organization.service';
import {ErrorHandler} from '../../handler/error-handler';
import {Database, object, ref} from '@angular/fire/database';
import {environment} from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BlindtestService {

    private readonly _blindtestSubject: Subject<Blindtest>;
    private _currentBlindTest: Blindtest;
    private _organization: string;

    constructor(
        private database: Database,
        private _blindingService: OrganizationService,
        private _http: HttpClient,
        private _logger: LoggerService,
        private _errorHandler: ErrorHandler) {

        this._blindtestSubject = new ReplaySubject(null);
        this._blindingService.organization().subscribe(b => {
            this._organization = b?.organization;
            // If blinding has changed, compute and emit next blind test value
            const shouldLoadBlindtest: boolean = b?.getBlindingHasCurrentGame();
            if (shouldLoadBlindtest) {
                this._loadCurrent().subscribe(bt => {
                    this._updateCurrent(bt);
                });
            } else {
                this._updateCurrent(null);
            }
        });
    }

    private _updateCurrent(value: Blindtest) {
        this._currentBlindTest = value;
        this._blindtestSubject.next(value);
    }

    private _loadCurrent(): Observable<Blindtest> {
        return object(ref(this.database, `${environment.globalNamespace}/games/${this._organization}/game/blindtest`))
            .pipe(map(sn => sn.snapshot.val())).pipe(take(1));
    }

    // Observables

    currentBlindtest(): Observable<Blindtest> {
        return this._blindtestSubject;
    }

    getWithTag(tag: string): Observable<Blindtest> {
        const organization = this._blindingService.getOrganizationTagSnapshot();
        return this._http.get<Blindtest>(`manager://manager/org/${organization}/demo/blindtest/byTag/${tag}`)
            .pipe(map(bt => Blindtest.fromDto(bt)))
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }

    build(): Observable<Build> {
        return this._http.get<Build>('manager://build')
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }


    importDefault(defaultAsset: any): Observable<Blindtest> {
        const organization = this._blindingService.getOrganizationTagSnapshot();
        return this._http.post<Blindtest>(`manager://manager/org/${organization}/demo/blindtest/import-default`, defaultAsset)
            .pipe(map(bt => Blindtest.fromDto(bt)))
            .pipe(this._errorHandler.retryThreeTimesOrError());
    }
}
