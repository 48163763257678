export enum LogLevel {
  VERBOSE = 0, DEBUG, INFO, WARNING, ERROR
}
export function parse(config) {
  return {
    level: parseLevel(config.level),
    toast: config.toast
  };
}

function parseLevel(level): LogLevel {
  switch (level.toLowerCase()) {
    case "verbose":
      return LogLevel.VERBOSE;
    case "debug":
      return LogLevel.DEBUG;
    case "info":
      return LogLevel.INFO;
    case "warning":
      return LogLevel.WARNING;
    case "error":
      return LogLevel.ERROR;
  }
  return LogLevel.INFO;
}
