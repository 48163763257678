import {ConstraintType} from './constraintType';
import {ConstraintMaxPax} from './constraintMaxPax';
import {ConstraintMinPax} from './constraintMinPax';
import {ConstraintIntlChecked} from './constraintIntlChecked';

export interface Constraint {
    type: string;
    value: any;
    matches(...value: any): boolean;
}

export function copyConstraint(c: Constraint): Constraint {
    switch (c.type) {
        case ConstraintType.PAX_MAX:
            return new ConstraintMaxPax(c.value);
        case ConstraintType.PAX_MIN:
            return new ConstraintMinPax(c.value);
        case ConstraintType.INTL_CHECKED:
            return new ConstraintIntlChecked(c.value);
        default:
            throw new Error('Unsupported constraint');
    }
}

export function constraintMatch(context: { intl: boolean, pax: number, ignorePaxWhenNull?: boolean }, ...constraint: Constraint[]) {
    return constraint.filter(c => {
        // Filter out all matched constraints
        switch (c.type) {
            case ConstraintType.PAX_MAX:
            case ConstraintType.PAX_MIN:
                return !c.matches(context.pax, context.ignorePaxWhenNull);
            case ConstraintType.INTL_CHECKED:
                return !c.matches(context.intl);
            default:
                throw new Error('Unsupported constraint');
        }
    }).length === 0;
}
