import {copyPricing, Pricing} from './pricing';

export class Article {
    ref: string;
    name: string;
    description: string;
    price: Pricing;


    constructor(ref: string, name: string, description: string, price: Pricing) {
        this.ref = ref;
        this.name = name;
        this.description = description;
        this.price = price;
    }

    static copy(a: Article) {
        return new Article(a.ref, a.name, a.description, copyPricing(a.price));
    }
}
