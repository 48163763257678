import {Chart, ChartMeta, UpdateMode, Plugin} from 'chart.js';

export class NumbersOnTopPlugin implements Plugin<'bar'> {
    id = 'numbersOnTop';

    afterInit(chart: Chart, args: {}, options: any) {
    }

    afterRender(chart: Chart, args: {}, options: any) {
        const width = window.screen.width;
        let fontSize = '36px';
        if (width > 1500) {
            fontSize = '44px';
        }
        chart.ctx.font = `bold ${fontSize} Roboto, \'Helvetica Neue\', sans-serif`;
        chart.ctx.fillStyle = 'white';
        chart.ctx.textAlign = 'center';
        chart.ctx.textBaseline = 'bottom';

        chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            meta.data.forEach(function(bar, index) {
                const data = dataset.data[index];
                chart.ctx.fillText(data.toString() + '%', bar.x, bar.y - 5);
            });
        });
    }

    afterDraw(chart: Chart, args: {}, options: any) {


    }

    afterDatasetsDraw(chart: Chart, args: {}, options: any, cancelable: false) {

    }

    afterDatasetsUpdate(chart: Chart, args: { mode: UpdateMode; cancelable: true }, options: any) {

    }

    afterDatasetUpdate(chart: Chart, args: { index: number; meta: ChartMeta; mode: UpdateMode; cancelable: false }, options: any) {


    }

}
