
export enum DemoStepActionType {
    NEXT = 'next',
    LAUNCH_VIDEO = 'launch_video',
    ONBOARD_PLAYERS = 'quiz_onboard_players',
    START_GAME = 'launch_game',
    NEXT_ITEM = 'quiz_next_item',
    ENABLE_JOKERS = 'quiz_enable_jokers',
    SHOW_LEADERBOARD = 'quiz_show_leaderboard',
    SET_GAME = 'set_game',
    UNSET_GAME = 'unset_game',
    TERMINATE_DEMO = 'terminate_demo',
}

export interface DemoStepAction {
    type: DemoStepActionType;
}
