import {Component, Input, OnInit} from '@angular/core';
import {Song} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-blindtest-content-song',
  templateUrl: './blindtest-content-song.component.html',
  styleUrls: ['./blindtest-content-song.component.scss']
})
export class BlindtestContentSongComponent implements OnInit {

  @Input()
  item: Song;

  constructor() { }

  ngOnInit(): void {
  }

}
