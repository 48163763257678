import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Quote} from '../../../../../core/models/opportunity/quote';
import {Status} from '../../../../../core/models/opportunity/status';

@Component({
  selector: 'app-opportunity-card-widget-canceled',
  templateUrl: './opportunity-card-widget-canceled.component.html',
  styleUrls: ['./opportunity-card-widget-canceled.component.scss']
})
export class OpportunityCardWidgetCanceledComponent implements OnInit {

  @Input()
  single: boolean;
  @Input()
  q: Quote;
  @Input()
  status: Status;
  @Input()
  index: number;
  @Input()
  isLast: boolean;
  @Input()
  xpName: string;
  @Input()
  bundleName: string;
  expanded = false;

  constructor() { }

  ngOnInit(): void {
  }

  canEdit() {
    return this.status === Status.DRAFT;
  }

  toggleExpand() {
    this.expanded = !this.expanded;
  }

}
