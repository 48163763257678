import {Constraint, copyConstraint} from './constraint';
import {BundleItem} from './bundleItem';
import {ParseUtils} from '../../utils/parse-utils';
import {ExperienceType} from './experienceType';

export class Bundle {
    tag: string;
    label: string;
    type: ExperienceType;
    items: BundleItem[];
    constraints: Constraint[];


    constructor(tag: string, type: ExperienceType, label: string, refs: BundleItem[], constraints: Constraint[]) {
        this.tag = tag;
        this.type = type;
        this.label = label;
        this.items = refs;
        this.constraints = constraints;
    }

    static copy(b: Bundle): Bundle {
        return new Bundle(b.tag, b.type, b.label, b.items?.map(bi => BundleItem.copy(bi)), b.constraints?.map(c => copyConstraint(c)));
    }

}
