export class QuoteFormUpdate {
    experienceTag: string;
    bundleTag: string;
    paxCount: number;
    lang: string;


    constructor(experienceTag: string, bundleTag: string, paxCount: number, lang: string) {
        this.experienceTag = experienceTag;
        this.bundleTag = bundleTag;
        this.paxCount = paxCount;
        this.lang = lang;
    }
}
