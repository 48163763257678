<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 512 512" xml:space="preserve">
<g id="stat">
	<circle id="axis" [attr.fill]="dark ? 'none' : '#022533'" [attr.stroke]="dark ? 'none' : '#82cdff'" stroke-width="10" stroke-miterlimit="10" cx="256" cy="256" r="220"/>
	<g id="bar">
		<path id="core" fill="none" [attr.stroke]="color" stroke-width="56" stroke-miterlimit="10" [attr.d]="'M256,36 A 220 220 0 ' + (isMoreThanHalf() ? '1' : '0') + ' 1 ' + getEndX() + ',' + getEndY()"/>
		<path id="edge" fill="none" [attr.stroke]="color" stroke-width="48" stroke-miterlimit="10" [attr.d]="'M256,36 A 220 220 0 ' + (isMoreThanHalf() ? '1' : '0') + ' 1 ' + getEndX() + ',' + getEndY()"/>
	</g>
</g>
	<g id="labels">
	<text id="title" text-anchor="middle" x="256" y="220" fill="#ffdf95" font-family="'Rockwell-Bold'" font-size="70px">{{label}}</text>
		<text id="value" text-anchor="middle" x="256" y="360" fill="#FFFFFF" font-family="'Rockwell-Bold'" font-size="160px">{{value}}</text>
</g>
</svg>
