import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';
import {
    getJokerAdvice, getLeaderboardAdvice,
    getPitchEndGoogleTranslate,
    getPitchGagnant,
    getPitchInscription,
    getPitchTakima,
    getSongAnswerAdvice
} from './fr-takima-18-30';

function getDefaultAsset() {
    return {
        'name': '18-30 - Set 1',
        'description': null,
        'tags': ['takima-forum-set-1-18-30'],
        'sets': [{
            'sections': [{
                'type': 'NORMAL',
                'label': 'J\'assume, mais j\'assume pas',
                'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                'songs': [
                    {
                        'title': 'Djadja',
                        'artist': 'Aya Nakamura',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {
                            'artist': ['Aya', 'Aia nakamoura'],
                            'title': ['Oh djadja', 'Oh jaja', 'Oh Djah djah', 'O djah djah', 'Djah Djah'],
                            'lyrics': []
                        },
                        'ageRange': {'start': 25, 'end': 55}
                    },
                    {
                        'title': 'On m\'appelle l\'ovni',
                        'artist': 'Jul',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {'artist': [], 'title': [], 'lyrics': []},
                        'ageRange': {'start': 20, 'end': 35}
                    }
                ]
            }, {
                'type': 'LYRICS',
                'label': 'Dessins animés',
                'altLabels': {'title': null, 'artist': null, 'lyrics': 'Nom du dessin animé'},
                'songs': [{
                    'title': 'Sponge Bob',
                    'artist': 'Sponge Bob',
                    'lyrics': 'Bob l\'éponge',
                    'type': 'LYRICS',
                    'alt': {'artist': [], 'title': [], 'lyrics': ['Sponge bob']},
                    'ageRange': {'start': 18, 'end': 35}
                },
                    {
                        'title': 'Comme un homme',
                        'artist': 'Patrick Fiori',
                        'lyrics': 'Mulan',
                        'type': 'LYRICS',
                        'alt': {'artist': [], 'title': [], 'lyrics': []},
                        'ageRange': {'start': 28, 'end': 45}
                    }]
            }, {
                'type': 'NORMAL',
                'label': 'Google Translate',
                'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                'songs': [{
                    'title': '...Baby one more time',
                    'artist': 'Britney Spears',
                    'lyrics': null,
                    'type': 'NORMAL',
                    'alt': {
                        'artist': ['Britney', 'Breatney'],
                        'title': ['Baby one more time', 'hit me baby one more time'],
                        'lyrics': []
                    },
                    'ageRange': {'start': 25, 'end': 35}
                }]
            }]
        }]
    };
}

function getPitchStartJassumeJassumePas() {
    return 'On attaque avec la section "J\'assume, mais j\'assume pas!", c\'est en hommage à toutes ces chansons que tu connais par coeur, sans oser le dire.\n\n' +
        'Bien penser à répondre dans l\'appli directement, tout le monde est prêt ? C\'est parti !\n' +
        '\n';
}

function getPitchAya() {
    return getSongAnswerAdvice() +
        '*Alors franchement, si celle-ci vous ne l\'avez pas, je vais me poser des questions... Chanteuse française très connue, une poête au vocabulaire parfois difficile à comprendre...*\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getPitchJul() {
    return getSongAnswerAdvice() +
        '*Lui aussi, poête des temps modernes et fervent utilisateur de l\'auto-tune, il débarque en Twingo à ses concerts et on peut le mimer avec les mains*\n\n\n\n' +
        'On continue avec une section Dessins animés : Il va falloir cette fois retrouver seulement le nom du dessin animé !\n\n';
}

function getPitchSpongeBob() {
    return getSongAnswerAdvice() +
        '*C\'est un dessin animé assez connu des français, il vit dans les fonds marins, est tout jaune, et plutôt carré en forme... Je dirais même qu\'il est un peu... spongieux... *\n\n' +
        '\n\nC\'est parti pour la suivante';
}


function getPitchStartJokers() {
    return getSongAnswerAdvice() +
        '*C\'est un grand classique de Disney, l\'histoire d\'un petit dragon rouge et d\'un criquet porte bonheur\n\n' +
        + getJokerAdvice();
}

function getPitchBritney() {
    return '[Fin de la chanson]\n' +
        'L\'artiste pop américaine la plus connue des années 2000. Elle est notamment sortie avec Justin Timberlake, avait promis qu\'elle resterait vierge jusqu\'au mariage, et a fini par se raser la tête...' +
        getLeaderboardAdvice();

}

export function computeTakimaDemoSetForum1() {
    return new DemoSet('takima-forum-set-1-18-30',
        'fr',
        'Jeune (18-30 ans) Set 1',
        getDefaultAsset(),
        [
            new DemoStep(2,
                [new WidgetText(getPitchInscription()), new WidgetVideoControl()],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Faire rejoindre la partie'),
            new DemoStep(3,
                [new WidgetText(getPitchStartJassumeJassumePas()), new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576244626.hd.mp4?s=dc7063e87ffe0ed8ec654f27f989f1059438ec9f&profile_id=175&download=1', true),
                    new ActionNext()
                ],
                'Lancer la Partie'),
            new DemoStep(4,
                [new WidgetText(getPitchAya()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691003163/rendition/720p/jul_-_l%27ovni%20%28720p%29.mp4?loc=external&signature=4cc52403de40b39fd52a7f30b34eb3cc4062c588fb0097ef69ca764f1efa970c', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(5,
                [new WidgetText(getPitchJul()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576244688.hd.mp4?s=8794f119314b707d17574cc070c1ccfa4bde080d&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(6,
                [new WidgetText(getPitchSpongeBob()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    // MULAN
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691473517/rendition/720p/set_2_-_02_mulan%20%28720p%29.mp4?loc=external&signature=84d853f9488b34a3ad608a08dcd70510571b7e72b630b1fd465c8c668819ba96', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(7,
                [new WidgetText(getPitchStartJokers()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionEnableJokers(),
                    new ActionNextItem(),
                    new ActionNext(),
                ],
                'Activer les jokers'),
            new DemoStep(8,
                [new WidgetText(getPitchEndGoogleTranslate())],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/576259996.hd.mp4?s=3bc40a5457c7f53d4ed006dcd2ac39a34ed434b5&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Chanson suivante'),
            new DemoStep(9,
                [new WidgetText(getPitchBritney()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Montrer les scores'),
            new DemoStep(10,
                [new WidgetText(getPitchGagnant()), new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionNext()
                ],
                'Closing'),
            new DemoStep(11,
                [new WidgetText(getPitchTakima())],
                [
                    new ActionTerminateDemo()
                ],
                'Terminer la démo'),
        ]
    );
}
