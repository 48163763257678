<div class="container">
    <table class="table">
        <tr>
            <td class="subcontainer">
                <h3 class="title">Nouvelle</h3>
                <div class="subtitle">Prêt à être envoyé</div>
                <br/>
                <app-opportunity-card *ngFor="let opportunity of opNewList" [op]="opportunity"
                                      [user]="user"
                                      (quoteDelete)="deleteQuote($event)"
                                      (quoteDuplicate)="duplicateQuote($event)"
                                      (quoteEdit)="editQuote($event)"
                                      (editDate)="editDate($event)"
                                      (delete)="delete($event)" (edit)="edit($event)" (duplicate)="duplicate($event)">
                </app-opportunity-card>
            </td>

            <td class="subcontainer">
                <h3 class="title">Demande Validée Frog</h3>
                <div class="subtitle">En attente de votre feu vert</div>
                <br/>
                <app-opportunity-card *ngFor="let opportunity of opCrmList" [op]="opportunity"
                                      [user]="user"
                                      (lose)="lose($event)"
                                      (edit)="edit($event)"
                                      (editDate)="editDate($event)"
                                      (confirm)="confirmOpportunity($event)"
                                      (duplicate)="duplicate($event)"
                                      [loading]="loading[opportunity.id]">
                </app-opportunity-card>
            </td>

            <td class="subcontainer">
                <h3 class="title">Prestation Confirmée</h3>
                <div class="subtitle">Votre client a signé</div>
                <br/>
                <cdk-drop-list #confirmedList="cdkDropList"
                               [cdkDropListData]="opConfirmedList" class="listContainer">
                    <app-opportunity-card *ngFor="let opportunity of opConfirmedList" [op]="opportunity"
                                          [user]="user"
                                          [loading]="loading[opportunity.id]"
                                          (duplicate)="duplicate($event)">
                    </app-opportunity-card>
                </cdk-drop-list>
            </td>
            <td class="subcontainer">
                <h3 class="title">Annulé / Perdu</h3>
                <div class="subtitle">N'a pas signé</div>
                <br/>
                <cdk-drop-list #canceledList="cdkDropList"
                               [cdkDropListData]="opCanceledList" class="listContainer">
                    <app-opportunity-card *ngFor="let opportunity of opCanceledList" [op]="opportunity"
                                          [user]="user"
                                          (duplicate)="duplicate($event)">
                    </app-opportunity-card>
                </cdk-drop-list>
            </td>
        </tr>
    </table>
</div>
