import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PlayerService} from '../../../../core/services/player/player.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {MediaPlayState} from '@frogconnexion/blinding-common';
import {ShowtimeService} from '../showtime.service';

@Component({
  selector: ' app-join-the-game',
  templateUrl: './join-the-game.component.html',
  styleUrls: ['./join-the-game.component.scss']
})
export class JoinTheGameComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input()
  gm: GameMetadata;
  playerCount: number;
  private subscription: Subscription;
  private status: MediaPlayState;

  constructor(private playerService: PlayerService, private showtimeService: ShowtimeService) { }

  ngOnInit(): void {
    this.subscription = this.playerService.playerCount()
        .subscribe((pc) => this.playerCount = pc);
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }


  ngAfterViewInit(): void {
    this.subscription = this.showtimeService.mediaStatusObservable('join-the-game').subscribe(s => {
      this.status = s;
      if (s === MediaPlayState.START) {
        setTimeout(() => this.showtimeService.mediaStatus('join-the-game').next(MediaPlayState.PLAYING), 0);
      } else if (s === MediaPlayState.STOP_IMMEDIATELY) {
      }
    });
  }
}
