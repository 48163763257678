import {Opportunity} from './opportunity';
import {QuoteDescriptor} from './quoteDescriptor';

export class OpportunityCreationDescriptor {


    constructor(company: string, crmId?: string, crmIdent?: string, fromCrm?: boolean, date?: string, hour?: number, minute?: number,
                exonerated?: boolean,
                quoteCreator?: QuoteDescriptor,
                comment?: string) {
        this.company = company;
        this.crmId = crmId;
        this.crmIdent = crmIdent;
        this.fromCrm = fromCrm;
        this.date = date;
        this.hour = hour;
        this.minute = minute;
        this.quote = quoteCreator;
        this.exonerated = exonerated;
        this.comment = comment;
    }
    company: string;
    crmId: string;
    crmIdent: string;
    fromCrm: boolean;
    partner: boolean;
    exonerated: boolean;
    date: string;
    hour: number;
    minute: number;
    quote: QuoteDescriptor;
    comment: string;

    static map(op: Opportunity): OpportunityCreationDescriptor {
        const qc = QuoteDescriptor.fromQuote(op.quotes[0]);
        const hour = op.time ? Number(op.time?.split(':')[0]) : undefined;
        const minute = op.time ? Number(op.time?.split(':')[1]) : undefined;
        return new OpportunityCreationDescriptor(op.company, op.crmId, op.crmIdent, op.fromCrm, op.date, hour, minute,
            op.exonerated,
            qc, op.comment);
    }

    clone(): OpportunityCreationDescriptor {
        return new OpportunityCreationDescriptor(this.company, this.crmId, this.crmIdent, this.fromCrm, this.date, this.hour, this.minute,
            this.exonerated,
            this.quote, this.comment);
    }
}
