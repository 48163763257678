<hr/>
<mat-list-item *ngIf="entry" class="item-container">
  <div class="ranking">{{ranking + 1}}</div>
  <div class="avatar">
    <img src="{{entry.player.avatar}}" alt="{{entry.player.name}}"/>
  </div>
  <div class="metadata">
    <div class="player-name">
      <h4 class="player">{{entry.player.name}}</h4>
    </div>
  </div>
  <div class="score">{{entry.score}}</div>
</mat-list-item>

<mat-list-item *ngIf="!entry">
  <h4 matLine class="player">--</h4>
</mat-list-item>
