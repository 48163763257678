import {Component, Inject, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-opportunity-edit-date-dialog',
    templateUrl: './opportunity-edit-date-dialog.component.html',
    styleUrls: ['./opportunity-edit-date-dialog.component.scss']
})
export class OpportunityEditDateDialogComponent implements OnInit, OnChanges {

    opportunityCreationForm;

    date: string;
    time: string;
    dateSet: boolean;
    timeSet: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialog: MatDialogRef<OpportunityEditDateDialogComponent>,
                private router: Router) {
        this.dateSet = !data.canEdit && data.dateSet?.length > 0;
        this.timeSet = !data.canEdit && data.timeSet?.length > 0;
        this.date = data.dateSet;
        this.time = data.timeSet;
        this.opportunityCreationForm = new UntypedFormGroup({
            'date': new UntypedFormControl({value: this.date?.length > 0 ? new Date(this.date) : undefined, disabled: this.dateSet}, [Validators.required]),
            'hour': new UntypedFormControl({value: this.time?.split(':')[0], disabled: this.timeSet}, [Validators.required]),
            'minute': new UntypedFormControl({value: this.time?.split(':')[1], disabled: this.timeSet}, [Validators.required])
        });
    }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.dateForm.value?.toISOString()?.substr(0, 10) !== this.date) {
            this.dateForm.setValue(this.date ? new Date(this.date) : undefined);
        }
        const hour = Number(this.time?.split(':')[0]);
        if (this.hourForm.value !== hour) {
            this.hourForm.setValue(hour);
        }
        const minute = Number(this.time?.split(':')[1]);
        if (this.minuteForm.value !== minute) {
            this.minuteForm.setValue(minute);
        }
    }

    get dateForm() {
        return this.opportunityCreationForm.get('date');
    }

    get hourForm() {
        return this.opportunityCreationForm.get('hour');
    }

    get minuteForm() {
        return this.opportunityCreationForm.get('minute');
    }

    hours() {
        return [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8];
    }

    minutes() {
        return [0, 15, 30, 45];
    }

    onSubmit() {
        const hour = parseInt(this.hourForm.value, 10) || undefined;
        const minute = parseInt(this.minuteForm.value, 10) || 0;
        let date;
        if (this.dateForm.value) {
            const dateFormValue = this.dateForm.value as Date;
            let m = '' + (dateFormValue.getMonth() + 1);
            if (m.length === 1) {
                m = '0' + m;
            }
            let d = '' + dateFormValue.getDate();
            if (d.length === 1) {
                d = '0' + d;
            }
            date = `${dateFormValue.getFullYear()}-${m}-${d}`;
        }
        let timeOfShow;
        if (hour !== undefined) {
            let h = '' + hour;
            if (h.length === 1) {
                h = '0' + h;
            }
            let m = '' + minute;
            if (m.length === 1) {
                m = '0' + m;
            }
            timeOfShow = `${h}:${m}`;
        }
        this.opportunityCreationForm.updateValueAndValidity();
        this.dialog.close({
            dateOfShow: date,
            timeOfShow: timeOfShow
        });
    }

    onCancel() {
        this.dialog.close();
    }

}
