import {BrandingDescriptor, Gameplay} from '@frogconnexion/blinding-common';


export class DemoGameDescriptor {
    constructor(blindtestId: string,
                company: string,
                gameplay: Gameplay,
                lang: string,
                branding: BrandingDescriptor,
                demoSetTag: string,
                demoSetStepCount: number) {
        this.blindtestId = blindtestId;
        this.company = company;
        this.gameplay = gameplay;
        this.lang = lang || 'fr';
        this.branding = branding;
        this.demoSetTag = demoSetTag;
        this.demoSetStepCount = demoSetStepCount;
    }

    demoSetStepCount: number;
    blindtestId: string;
    company: string;
    gameplay: Gameplay;
    branding: BrandingDescriptor;
    lang: string;
    demoSetTag: string;
}
