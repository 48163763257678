import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';

import {Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {LoggerService} from '../../../services/logger/logger.service';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';
import {Claims, RoleClaim} from '@frogconnexion/core-common';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private _authService: FirebaseAuthService, private _logger: LoggerService, private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this._authService.currentUserObservable().pipe(mergeMap(user => {
      if (!user || !user.authenticated) {
        this._logger.debug('Admin Guard Failed. Authenticating first.');
        this._router.navigate([`/`]);
        return;
      }
      if (user.anonymous) {
        this._logger.debug('Admin Guard Failed. Anonymous user.');
        this._router.navigate([`/`]);
        return of(false);
      }
      if (!user.hasAnyRole([RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN)])) {
        this._logger.debug(`Auth Guard Failed. User: ${user.id} - ${user.displayName} doesn\'t have the appropriate role.`);
        this._router.navigate([`/org/global/unauthorized`]);
        return of(false);
      }
      this._logger.debug('Auth Guard Success.');
      return of(true);
    }));
  }
}
