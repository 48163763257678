<div class="container">
  <mat-card>
    <mat-card-content>
      <div fxLayout="column"
           fxLayoutAlign="space-between center"
           fxLayoutGap="20px">
        <mat-icon>lock</mat-icon>
        <p>Désolé. Ce site est réservé aux utilisateurs autorisés de <b>DemoTime</b>.</p>
      </div>
    </mat-card-content>
  </mat-card>
</div>
