import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';

export function computeDemoSetInternationalClassicsEn() {
    return new DemoSet('any-english-person',
        'en',
        'International all-time classics',
        {
            'name': 'All Time Classics EN',
            'description': null,
            'tags': ['any-english-person-en'],
            'sets': [{
                'sections': [{
                    'type': 'NORMAL',
                    'label': 'Admit it, you know it',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [{
                        'title': 'Wannabe',
                        'artist': 'Spice Girls',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {
                            'artist': ['The Spice Girls', 'spice girl', 'space girls'],
                            'title': ['Wanabee', 'wanabee', 'wanna be', 'wanna bee', 'want to be', 'wanabe', 'if you wanna be'],
                            'lyrics': []
                        },
                        'ageRange': {'start': 25, 'end': 55}
                    }]
                }, {
                    'type': 'NORMAL',
                    'label': 'Rock anthology',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [{
                        'title': 'Another one bites the dust',
                        'artist': 'Queen',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {'artist': [], 'title': ['an other 1 bite the dust', 'an other one bites a dust'], 'lyrics': []},
                        'ageRange': {'start': 25, 'end': 55}
                    }]
                }, {
                    'type': 'NORMAL',
                    'label': 'Mixology',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [{
                        'title': 'Stayin alive',
                        'artist': 'Bee Gees',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {
                            'artist': ['BGs', 'BJs', 'Be Jiz', 'Be Geez', 'Bee Jeez', 'Bee Jees'],
                            'title': ['Staying alive', 'Stay in alive'],
                            'lyrics': []
                        },
                        'ageRange': {'start': 25, 'end': 55}
                    }]
                }]
            }]
        },
        [
            new DemoStep(2,
                [new WidgetText(
                    '**[Once the trailer starts]**\n' +
                    '\n' +
                    'And we\'re off...  \r\n\r\n' +
                    '**[Artists arrive on stage]**\n\n' +
                    '**[End of video clip]**\n\n' +
                    ' * People are used to virtual events that are a little "rustic"\n' +
                    ' * Now they see a TV trailer, a band that arrives on stage screaming on a stage full of cameras...\n' +
                    ' * This creates the first impression where people are hanging on the edge of their seat and they\re **"ok, what is going on? this is going to be crazy"** \n\n' +
                    ' * We work hard for the entrance so that it shocks and fires up the audience!\n' +
                    ' * Right after their entrance, the artists briefly explain what they are doing here: **"you\'re about to play in a Live Quiz, where you will have to guess the title and artist of songs we play."**\n' +
                    '\n' +
                    ' * The main differences: Everything will be played live, and you\'ll answer with an app!  \n\n' +
                    'And to introduce you the app, we found the perfect person...' +
                    'Launch Morpheus video'),
                    new WidgetVideoControl()
                ],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/583435610.hd.mp4?s=ff4e0b82f8d4ea960af80a4a3c93e150f7688aa1&profile_id=175&download=1'),
                    new ActionNext()
                ],
                'Lancer la vidéo Morphéus'),
            new DemoStep(3,
                [new WidgetText(
                    '**[Video starts]**  \n\n' +
                    'Yes, huge trademark violation here ^^!  \n\n' +
                    'We have a **voice-over**, people are immersed in the concept and it sets the tone\n\n' +
                    'I will spare you the details, what\'s important now is to explain how people can join the game\n' +
                    '\n' +
                    '\n' +
                    '**[End of video]**'),
                    new WidgetVideoControl(),
                ],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Join the Game'),
            new DemoStep(4,
                [new WidgetText(
                    'I\'ll stop here, and now **it\'s your turn**  \n\n' +
                    'That\'s right, please grab your phones and head over to **www.goblindtest.com**\n\n' +
                    'Your game pin today is *[YOUR ORGANIZATION]*\n\n' +
                    'In general, it\'s the **name of your company**, ' +
                    'and we can also **brand** the app with your colors\n\n' +
                    '**[We let them sign up]**\n\n' +
                    'This onboarding process roughly takes 3 to 5 minutes, depending on the number of connected people.\n\n' +
                    'The band will usually remind how to connect to the game, and play a song while waiting for people to join etc...\n' +
                    'Right before we start, we will usually warm-up and energize the crowds...\n'),
                    new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/583435270.hd.mp4?s=ebc57c859a4d4d87dad305d124d23c71ef13b947&profile_id=175&download=1', true),
                    new ActionNext()
                ],
                'Warm-up the crowds'),
            new DemoStep(5,
                [new WidgetText(
                    'Right before the game starts, we\'ll usually warm up the audience :  \n' +
                    'The participants will have to unmute their mics and yell with everyone.' +
                    '[Video plays]\n\n' +
                    'we realise that some people have not laughed or yelled like that in a long time, so it\'s a huge release of energy,\n' +
                    '(laughter) in general, people already think we\'re a little crazy at this stage, which is great because they will lower their guard! \n' +
                    'now we can attack!\n\n' +
                    'Pitch \n\n Tips: Be engaging, funny, crack some jokes, explain each section clearly with a little humor\n' +
                    'We start with the section "J\'assume, mais j\'assume pas!", "Admit it, you know it", as a tribute to all the songs you know by heart but won\'t admit it.\n\n' +
                    'Remember to answer through the app, everyone ready? Let\'s go!'
                ),
                    new WidgetVideoControl(),
                    new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576281463.hd.mp4?s=b953f6c49bd364f3ea6a3c28342c4cf8570511f3&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Launch the Game'),
            new DemoStep(6,
                [new WidgetText(
                    '[Wait for all the answers]\n\n' +
                    '[If you see them struggling, offer some hints. We want them to find the answer!]\n\n' +
                    '*Ok now, if you guys don\'t get this next one, I\'m going to have some questions... ' +
                    'Girls-Band Ango-Saxon very famous, ' +
                    'they actually played at the opening ceremony of the olympics a few years ago...*\n\n' +
                    '[Lock response once everything is Ok. Do not wait too long after the video]\n\n' +
                    '[the stats will be displayed, which you can comment on before you...]\n\n' +
                    '[Reveal the  answer. This is where the correct answer will be revealed to the player]\n\n'),
                    new WidgetVideoControl(),
                    new WidgetQuizGame(),
                ],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/576277053.hd.mp4?s=a4779848933b2800e484ffc1f644d4101b9bcf9b&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Next'),
            new DemoStep(7,
                [new WidgetText(
                    'Pitch \n\n Tip : Be engaging, funny, crack some jokes, esplain each section clearly with a little humor\n' +
                    'Moving along with the section Rock anthology : The great classics of rock!\n' +
                    '\n' +
                    '[Wait for all responses]\n\n' +
                    '[If you see them struggling, offer some hints. We want them to find the answer!]\n\n' +
                    '*He\'s one of the most famous singers of his time, he even has a movie to his name... *\n\n' +
                    '[Lock response once everything is Ok. Do not wait too long after the video]\n\n' +
                    '[the stats will be displayed, which you can comment on before you...]\n\n' +
                    '[Reveal the  answer. This is where the correct answer will be revealed to the player]\n\n' +
                    'We\'re going to move on to the last one, but this time I\'m going to help you guys out\n\n' +
                    'We\'re going to activate the jokers!\n\n' +
                    '\n' +
                    '\n' +
                    'In general we announce them with a video of the Joker in person (the Batman Character)'),
                    new WidgetVideoControl(),
                    new WidgetQuizGame(),
                ],
                [
                    new ActionEnableJokers(),
                    new ActionNextItem(),
                    new ActionNext(),
                ],
                'Enable jokers'),
            new DemoStep(8,
                [new WidgetText(
                    'It\'s a bit like who wants to be a millionaire, you have a double or nothing, a spy, or a 50-50 \n' +
                    '\n' +
                    '\n' +
                    'The best is the middle one, it allows you to steal the answer from another player!\n' +
                    'Don\'t forget to submit your answer first, that\'s a must!' +
                    '\n\nWe wrap up with our signature section: "Mixology"\n' +
                    '\n' +
                    '\n' +
                    'It\'s got nothing to do with cocktails, we simply sing the lyrics of a song over the intstrumentation of a different song. Your job is to find the title and artist of the words, let\'s go!\n' + '\n')
                ],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/external/576281193.hd.mp4?s=9809e6232a27cba19cde754d60f122b7cf22044d&profile_id=175&download=1', true),
                    new ActionNext(),
                ],
                'Play Last Song'),
            new DemoStep(9,
                [new WidgetText(
                    '[End of the song]\n\n' +
                    'It was the Saturday Night Fever group.\n\n' +
                    'Bravo! I see there\'s some competition!\n' +
                    '\n' +
                    '\n' +
                    '\n' +
                    'Shall we take a look at the scores?\n' +
                    '\n' +
                    '\n' +
                    '\n' +
                    'Let\'s see who won...'
                ),
                    new WidgetVideoControl(),
                    new WidgetQuizGame()],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Show the scores'),
            new DemoStep(10,
                [new WidgetText(
                    'Pitch \n\n [praise the winner!]\n' +
                    '\n' +
                    '\n' +
                    '\n' +
                    'Just so you konw, you can play solo or in teams if you guys have departments.\n' +
                    '\n' +
                    '\n' +
                    '\n' +
                    'Whatever happens, you will receive a detailed score sheet right after the end of the show.\n' +
                    '\n' +
                    '\n' +
                    '\n' +
                    'You guys have the basic gist of the concept.' +
                    '\n\n' +
                    'What more could I show you?\n\n' +
                    'Oh yes, depending on your team and the vibe you bring to your show, we\'ll sprinkle in some hightlights along the show\n\n' +
                    'For example, the prop song...'),
                    new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/external/583435367.hd.mp4?s=da36a69253032b5118ab623c912df52e5e3e1f89&profile_id=175&download=1'),
                    new ActionNext()
                ],
                'Show an example'),
            new DemoStep(11,
                [
                    new WidgetText(
                        'Here, we\'re going to ask people to get up from their chairs and run grab the craziest prop they can find for an extra 50 points\n\n' +
                        '\n' +
                        '\n' +
                        '\n' +
                        '[Let the video start]' +
                        '\n' +
                        '\n' +
                        'People will move outside of their cameras, there is nobody left in the webcams\n\n' +
                        'I\'ll spare you the details, just watch what the players bring back :)\n\n' +
                        'We\'ve got animals, live and stuffed, kids with makeup, we\'ve really seen some crazy things these past months.\n\n' +
                        'Here is where the magic happens. It\'s when people aren\'t in their living rooms but elsewhere, together.\n\n' +
                        'It\'s when nobody takes the game seriously, because even those who aren\'t playing will find it hilarious\n\n' +
                        'We\'ve seen some failed dirty dancing reenactments, \n\nWe\'ve seen dads carry their newborn Lion King style\n\n' +
                        'When people live this experience together, they reconnect to their company culture, and this is where the magic happens.\n\n' +
                        'We rediscover ourselves, different, and I guarantee you, they will remember this for a long time!\n\n'
                    ),
                    new WidgetVideoControl(),
                ],
                [
                    new ActionNext()
                ],
                'Closing'),
            new DemoStep(12,
                [new WidgetText(
                    'Do you think this is an experience that your team might like?\n\n' +
                    'Know that we have multiple variations of this animation, including virtual (like this), in person, or even a hybrid of the two, etc...\n\n' +
                    'If this is something that speaks to you, the first order of business is to lock in a date so as to make sure a band is available for you.\n\n' +
                    'Usually, we ask for at least three weeks to be able to organize your event just the way you want it\n\n' +
                    'Once we have a date set, we do a little "briefing", where you will decide the direction you want the show to take.\n\n' +
                    'Depending on the team, we will offer you themes, we\'ll ask for the age distribution, we\'ll guide you on communicating the event to your team (we\'ve got some plans to maximize engagement from your team.)\n\n' +
                    'Once the briefing is done, it\'s in the bag and we handle the rest.\n\n' +
                    'Do you have an idea of what date you would want?\n\n' +
                    'Is it during the day? Or maybe end of the afternoon?\n' +
                    '\n' +
                    'Early in the week? End of the week?\n' +
                    '\n' +
                    'Depending on how many people you have in mind, we have a few options. Given what you\'ve told me, we would usually orient you towards experience X.\n\n' +
                    'Now usually, this is the time where you ask the billion dollar question...' +
                    '[See if the person is responsive... they will usually say "Yeah, how much!"]' +
                    'Would you like to see how much it will cost you?\n' +
                    '\n' +
                    '\n' +
                    'Let\'s take a look')],
                [
                    new ActionTerminateDemo()
                ],
                'End Demo'),
        ]
    )
        ;
}
