import {Component, Input, OnInit} from '@angular/core';
import {GameControls} from '@frogconnexion/blinding-common';
import {Song} from '@frogconnexion/blinding-common';

@Component({
  selector: 'app-showtime-overlay-revealed',
  templateUrl: './showtime-overlay-revealed.component.html',
  styleUrls: ['./showtime-overlay-revealed.component.scss']
})
export class ShowtimeOverlayRevealedComponent implements OnInit {

  @Input()
  gc: GameControls;
  @Input()
  lang: string;

  constructor() { }

  ngOnInit(): void {

  }

  get song(): Song | undefined {
    return this.gc?.songControl?.answer;
  }

}
