import {Injectable} from '@angular/core';
import {LogLevel} from './loglevel';
import {LoggerConfig} from './logger.config';

type LogFuncType = (message?: any, ...optionalParams: any[]) => void;

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor(private _logConfig: LoggerConfig) {
  }

  private static getLogLevel(level: LogLevel) {
    return `[${LogLevel[level]}]`;
  }

  private loggingOnLevel(level: LogLevel, consoleFunc: LogFuncType, ...information: any[]) {
    if (this._logConfig.level <= level) {
      consoleFunc(LoggerService.getLogLevel(level), ...information);
    }
  }

  public verbose(...information: any[]) {
    this.loggingOnLevel(LogLevel.VERBOSE, console.log, information);
  }

  public debug(...information: any[]) {
    this.loggingOnLevel(LogLevel.DEBUG, console.log, information);
  }

  public info(...information: any[]) {
    // tslint:disable-next-line:no-console
    this.loggingOnLevel(LogLevel.INFO, console.info || console.log, information);
  }

  public warn(...information: any[]) {
    this.loggingOnLevel(LogLevel.WARNING, console.warn || console.log, information);
  }

  public error(...information: any[]) {
    this.loggingOnLevel(LogLevel.ERROR, console.error, information);
  }
}

