<div>
    <div class="quote-header">
        <div class="quote-index-title" (click)="toggleExpand()">
            <ng-container *ngIf="!expanded"> +</ng-container>
            <ng-container *ngIf="expanded"> -</ng-container>
            Devis N° {{index + 1}}
        </div>
        <div class="price">{{q.price | currency:'EUR':'':'3.0-0'}} € HT</div>
        <div class="edit">
            <button mat-button [matMenuTriggerFor]="menuItem" class="edit">
                <mat-icon>read_more</mat-icon>
            </button>
            <mat-menu #menuItem="matMenu">
                <a mat-menu-item [href]="q?.crmMetadata?.crmLink" target="_blank">
                    <mat-icon>request_quote</mat-icon>
                    Voir le devis
                </a>
            </mat-menu>
        </div>
    </div>
    <ng-container *ngIf="expanded">
        <div class="bundle">
            <span class="bundle-title">Animation :</span>
            <br/>{{xpName}}
            <br/>
            <span class="bundle-title">Formule :</span>
            <br/>{{bundleName}}
        </div>
        <br/>
        <div *ngFor="let article of q.crmMetadata?.crmArticles" class="article">
            <div>{{article.ref}}</div>
            <div>{{article.name}}</div>
        </div>
        <br/>
    </ng-container>
    <hr *ngIf="!isLast"/>
</div>
