import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import {LoggerService} from '../../services/logger/logger.service';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  email = '';
  password = '';
  error: any;
  hide: boolean;

  emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);

  passwordFormControl = new UntypedFormControl('', [Validators.required]);
  matcher = new MyErrorStateMatcher();

  constructor(private _authService: FirebaseAuthService,
              private _router: Router,
              private _logger: LoggerService) {}

  ngOnInit() {
  }

  onSubmit() {
    this._authService.emailSignIn(this.email, this.password)
    .then(
      (user) => {
        this._logger.debug('User authenticated successfully:');
        this._router.navigate(['/']);
    }).catch(
      (err) => {
        this._logger.error(err);
        this.error = err;
    });
  }

}
