<div class="container">
    <h1>Infos générales</h1>
    <div class="main-container">
        <form [formGroup]="opportunityCreationForm" class="full-width">
            <div *ngIf="isAdminOrManager() && isInternalOrganization()">
                <mat-form-field class="internal-crm" appearance="fill">
                    <input type="text"
                           placeholder="Ex OPP-00011"
                           matInput
                           formControlName="crmident"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.ident">
                            <span class="opp-ident">
                                {{option.ident}}
                            </span>
                            <span class="opp-name">{{option.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint>Opportunité CRM Frog</mat-hint>
                </mat-form-field>
            </div>
            <mat-form-field appearance="fill" class="company-name">
                <input matInput formControlName="company">
                <mat-hint>Nom de l'opportunité</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="fill" class="field-sm">
                <input matInput [matDatepicker]="picker1" formControlName="date">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-hint>Date prestation</mat-hint>
            </mat-form-field>
            <div class="time">
                <mat-form-field appearance="fill" class="field-time">
                    <mat-select formControlName="hour">
                        <mat-option *ngFor="let h of hours()" [value]="h">
                            {{h}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <span class="hour">h</span>
                <mat-form-field appearance="fill" class="field-time">
                    <mat-select formControlName="minute">
                        <mat-option *ngFor="let m of minutes()" [value]="m">
                            {{m}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div class="time-footer">Heure prestation</div>
            </div>
            <mat-form-field appearance="fill" *ngIf="!isAdminOrManager() || !isInternalOrganization()">
                <input matInput formControlName="crmident" placeholder="Id CRM">
                <mat-hint>Identifiant interne</mat-hint>
            </mat-form-field>
        </form>
    </div>
</div>
<div class="container">
    <div class="actions">
        <button mat-raised-button class="saveButton" color="primary" (click)="onSubmit()" [disabled]="!opportunityCreationForm.valid || !otherFormsValid">Enregistrer</button>
        <button mat-raised-button color="accent" (click)="onReturn()">Retour</button>
    </div>
</div>
