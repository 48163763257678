<div class="main-container">
    <div class="section-container">
        <app-quote-header class="container recap-container dark"
                          [companyName]="opportunity?.company"
                          [time]="opportunity?.time"
                          [crmId]="opportunity?.crmId"
                          [date]="opportunity?.date"
                          [user]="user"
                          [organization]="organization"
                          [otherFormsValid]="otherFormsValid"
                          (saveQuote)="saveQuote()">
        </app-quote-header>
    </div>
    <div class="section-container">
        <app-quote-form class="container quote-form-container dark" [catalog]="catalog"
                        [user]="user"
                        [quote]="currentQuote"
                        [opQuote]="quote" (quoteUpdated)="onQuoteUpdated($event)"
                        (validityChanged)="validityChanged($event)"
        >
        </app-quote-form>
        <div class="section-subcontainer">
            <app-quote-details class="container quote-details-container dark"
                               [quote]="currentQuote"
                               [exonerated]="opportunity?.exonerated"
                               (optionChecked)="onQuoteOptionsChecked($event)"></app-quote-details>
            <div class="container quote-comment-container dark">
                <div class="comment-section">
                    <h1>Détails / Contexte</h1>
                    <textarea #comment [ngModel]="opportunity?.comment" class="textarea dark" cdkTextareaAutosize
                              rows="5"
                              placeholder="Aucun commentaire pour le moment" disabled></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
