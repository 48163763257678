import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {GameService} from '../../../../core/services/game/game.service';
import {GameControls, SetOfBlindtestStatus} from '@frogconnexion/blinding-common';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {MediaPlayState} from '@frogconnexion/blinding-common';
import {ShowtimeService} from '../showtime.service';

@Component({
    selector: 'app-livefeed',
    templateUrl: './livefeed.component.html',
    styleUrls: ['./livefeed.component.scss']
})
export class LivefeedComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input()
    lang: string;
    private subscription: Subscription;
    gc: GameControls;
    @Input()
    gm: GameMetadata;
    private gcSubscription: Subscription;
    private btVolumeSubscription: Subscription;
    private btMutedSubscription: Subscription;
    private status: MediaPlayState;

    constructor(private showtimeService: ShowtimeService, private gameService: GameService) {
    }

    ngOnInit(): void {
        this.gcSubscription = this.gameService.currentGameControls().subscribe(gc => {
            this.gc = gc;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.initFeed();
    }

    ngOnDestroy(): void {
        unsubscribe(this.subscription,
            this.gcSubscription,
            this.btMutedSubscription,
            this.btVolumeSubscription);
    }

    async openDevice(constraints) {
        return await navigator.mediaDevices.getUserMedia(constraints);
    }

    ngAfterViewInit(): void {
        this.subscription = this.showtimeService.mediaStatusObservable('feed')
            .subscribe((status) => {
                this.status = status;
                if (status === MediaPlayState.START) {
                    this.showtimeService.mediaStatus('feed').next(MediaPlayState.PLAYING);
                } else if (status === MediaPlayState.STOP_IMMEDIATELY) {
                }
            });
        this.initFeed();
    }

    private initFeed() {
    }

    showLocked() {
        return this.gc?.songControl?.locked && !this.showRevealed() && !this.showLeaderboard();
    }

    showRevealed() {
        return this.gc?.songControl?.revealed && !this.showLeaderboard();
    }

    showPlaying() {
        return !this.showLobby() && !this.gc?.songControl?.locked && !this.showLeaderboard();
    }

    showLeaderboard() {
        return this.gc?.blindtestControl?.showLeaderboard;
    }

    showLobby() {
        return (this.gc?.blindtestControl.status === SetOfBlindtestStatus.READY) || (this.gc?.blindtestControl?.status ===  SetOfBlindtestStatus.PAUSED);
    }
}
