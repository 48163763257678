<div class="container">
    <div class="screen-container">
        <app-screen-welcome class="screen" screen="welcome" [showtimeLoaded]="showtimeLoaded" [interactionMade]="interactionMade" [loading]="bml || bmpl || bmdl" [lang]="ctx?.metadata?.lang"></app-screen-welcome>
    </div>
    <ng-container *ngFor="let v of videos">
        <ng-container *ngIf="v.loaded && interactionMade">
            <div class="screen-container" [ngClass]="{'hidden': !showVideo(v.payload.key), 'fadeout': fadeOutEnding(v.payload.key)}">
                <app-video class="screen" [key]="v.payload.key" [link]="v.payload.url" [fadeOutTimeInSeconds]="3"></app-video>
            </div>
        </ng-container>
    </ng-container>
    <div class="screen-container" [ngClass]="{'hidden': !showVideo('join-the-game')}">
        <app-join-the-game class="screen" [lang]="ctx?.metadata?.lang" [gm]="gm"></app-join-the-game>
    </div>
    <div class="screen-container transparent" *ngIf="isGameStarted()">
        <app-livefeed class="screen" [gm]="gm" [lang]="ctx?.metadata?.lang"></app-livefeed>
    </div>
    <div class="screen-container interaction-container" *ngIf="!interactionMade">
        <div class="loading-container" *ngIf="showtimeLoaded === false">
            <div>
                <span>
                    <h1>Bienvenue !</h1>
                    <br/>
                    C'est la première fois que vous arrivez ici ?
                    <ul>
                        <li>Vérifiez que vous êtes bien sur Google Chrome</li>
                        <li>Installer l'extension Chrome Showtime pour GoBlindtest avant de continuer.</li>
                    </ul>
                </span>
                <a mat-raised-button [href]="'https://chrome.google.com/webstore/detail/' + extensionId" id="install-button" target="_blank" color="accent">Installer l'extension</a>
                <p>
                    Une fois que l'extension est installée, <a class="reload-link" (click)="reload()">cliquez ici pour rafraichir</a>
                </p>
            </div>
        </div>
        <div class="loading-container" *ngIf="!(showtimeLoaded === false)">
            <ng-container *ngIf="showtimeLoaded && bmdl && !bmdl.isCompleted()">
                <div class="loading" *ngIf="!bmdl?.isCompleted()">
                    <br/>
                    Téléchargement des vidéos <span *ngIf="mediaTotalDownloadCount > 0">({{mediaDownloadedCount}} / {{mediaTotalDownloadCount}})</span>
                    <div class="hint">Cette opération peut prendre un certain temps et rafraichir la page automatiquement</div>
                </div>
            </ng-container>
            <ng-container *ngIf="showtimeLoaded && !state?.demoSetTag && bmpl && !bmpl.isCompleted()">
                <div class="loading" *ngIf="!bmpl?.isCompleted()">
                    <br/>
                    Chargement des premières vidéos<span *ngIf="mediaPreTotalCount > 0">({{mediaPreLoadedCount}} / {{mediaPreTotalCount}})</span>
                </div>
            </ng-container>
            <ng-container *ngIf="showtimeLoaded && bml && !bml.isCompleted()">
                <div class="loading" *ngIf="!bml?.isCompleted()">
                    Mise en cache des vidéos <span *ngIf="mediaTotalCount > 0">({{mediaLoadedCount}} / {{mediaTotalCount}})</span>
                    <div class="hint">Cette opération peut prendre un certain temps et rafraichir la page automatiquement</div>
                </div>
            </ng-container>
            <ng-container *ngIf="showtimeLoaded === undefined">
                <span class="loading">Recherche de l'Extension Showtime</span>
            </ng-container>
            <span *ngIf="allIsGood()"><br/><br/></span>
            <button mat-raised-button color="accent" (click)="setInteractionDone()" [disabled]="!allIsGood()">Commencer</button>
        </div>
    </div>
</div>
