import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {DemoContext} from '../../../../../core/models/demo/demoContext';
import {WidgetLeaderboard} from '../../../../../core/models/demo/widgets/widgetLeaderboard';
import {PlayerService} from '../../../../../core/services/player/player.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../../../core/handler/subscription-handler';
import {LeaderboardEntry} from '../../../../../core/models/leaderboard/leaderboardentry';

@Component({
    selector: 'app-widget-leaderboard',
    templateUrl: './widget-leaderboard.component.html',
    styleUrls: ['./widget-leaderboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class WidgetLeaderboardComponent implements OnInit {

    @Input()
    ctx: DemoContext;
    @Input()
    widget: WidgetLeaderboard;
    @Input()
    enableServices: boolean;

    private playerCountSubscription: Subscription;
    playerCount = 2;


    constructor(private playerService: PlayerService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.playerCountSubscription = this.playerService.playerCount().subscribe((playerCount) => {
            this.playerCount = playerCount;
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnDestroy() {
        unsubscribe(this.playerCountSubscription);
    }


}

