import {DemoStepWidget, DemoWidgetType} from '../demoStepWidget';

export class WidgetText implements DemoStepWidget {
    type = DemoWidgetType.TEXT;
    textMarkdown: string;

    constructor(textMarkdown:  string) {
        this.textMarkdown = textMarkdown;
    }
}
