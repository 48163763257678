import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-round-progress-widget',
  templateUrl: './round-progress-widget.component.html',
  styleUrls: ['./round-progress-widget.component.scss']
})
export class RoundProgressWidgetComponent implements OnInit {

  readonly centerX: number = 256;
  readonly centerY: number = 256;
  readonly radius: number = 220;
  readonly twopi: number = Math.PI * 2;

  @Input()
  value = 90;
  @Input()
  total = 100;
  @Input()
  label = 'Title';
  @Input()
  color = '#FFD167';
  @Input()
  dark = false;

  constructor() { }

  ngOnInit() {

  }

  getEndX(): number {
    if (!this.total || this.total === 0) {
      return this.centerX - 1;
    }
    return Math.floor(this.centerX + this.radius * Math.sin(this.twopi * this.value / this.total));
  }

  getEndY(): number {
    if (!this.total || this.total === 0) {
      return this.centerY - this.radius;
    }
    return Math.floor(this.centerY - this.radius * Math.cos(this.twopi * this.value / this.total));
  }

  isMoreThanHalf() {
    if (!this.total || this.total === 0) {
      return true;
    }
    return this.value > this.total / 2;
  }
}
