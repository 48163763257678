// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    envName: window['env']['envName'] || 'staging',
    production: !(window['env']['production'] === 'false'),
    hmr: window['env']['hmr'] === 'true',
    globalNamespace: 'blinding',
    useEmulators: false,
    version: window['env']['version'] || 'unknown',
    services: {
        managerApiBaseUrl: window['env']['frogManagerApiBaseUrl'] || 'https://api.manager.frogconnexion.cloud',
    },
    loggerConfig: {
        level: window['env']['loggerLevel'] || 'INFO',
        toast: window['env']['loggerToastEnabled'] === 'true'
    },
    firebase: {
        apiKey: window['env']['firebaseApiKey'] || 'AIzaSyC4njCdg8CZCp4Ernk4_KfMmS7UPgXKCD8',
        authDomain: window['env']['firebaseAuthDomain'] || 'blinding-io-staging.firebaseapp.com',
        databaseURL: window['env']['firebaseDatabaseUrl'] || 'https://blinding-io-staging.firebaseio.com',
        projectId: window['env']['firebaseProjectId'] || 'blinding-io-staging',
        storageBucket: window['env']['firebaseStorageBucket'] || 'blinding-io-staging.appspot.com',
        messagingSenderId: window['env']['firebaseMessagingSenderId'] || '1012976060463',
        appId: window['env']['firebaseAppId'] || '1:1012976060463:web:ef217928582d10bafadeda'
    },
    showtime: {
        extensionId: 'glnibhmamdeaglkkgogljknibmkolljd',
        media: [
            {key: 'intro', lang: 'fr', type: 'video', link: 'https://player.vimeo.com/external/538018295.hd.mp4?s=bf9b4efe06d597745ac197f283242a972d4a4d14&profile_id=175&download=1', duration: 25},
            {key: 'connect', lang: 'fr', type: 'video', link: 'https://player.vimeo.com/external/538020328.hd.mp4?s=2d9d2bd09a6436c1c003b393064c17763d1a2f56&profile_id=175&download=1', duration: 42},
            {key: 'rules', lang: 'fr', type: 'video', link: 'https://player.vimeo.com/external/538020361.hd.mp4?s=d222d9b20a27d21c03ad5b9a5d044743569ec43e&profile_id=175&download=1', duration: 51},
            {key: 'jokers', lang: 'fr', type: 'video', link: 'https://player.vimeo.com/external/538020675.hd.mp4?s=634fc00ac3655f2c24b45e9173c03c84cc32b55f&profile_id=175&download=1', duration: 55},
            {key: 'intro', lang: 'en', type: 'video', link: 'https://player.vimeo.com/external/559401186.hd.mp4?s=2aed5c84419b71ad1ed273dd57d70f92d135c95c&profile_id=175&download=1', duration: 25},
            {key: 'connect', lang: 'en', type: 'video', link: 'https://player.vimeo.com/external/559401697.hd.mp4?s=938b19feba02e51f94ff4313b7e767c8114a7dc3&profile_id=175&download=1', duration: 43},
            {key: 'rules', lang: 'en', type: 'video', link: 'https://player.vimeo.com/external/559401513.hd.mp4?s=c96df43d9dd291fec9a713f7814f15ef83a24fad&profile_id=175&download=1', duration: 56},
            {key: 'jokers', lang: 'en', type: 'video', link: 'https://player.vimeo.com/external/559401302.hd.mp4?s=b58c89df1b4d4a531c57a984be2ee4585e6309ed&profile_id=169&download=1', duration: 57},
            {key: 'main-waiting-theme', lang: '*', type: 'sound', link: 'https://firebasestorage.googleapis.com/v0/b/com-goblindtest.appspot.com/o/start-and-end.mp3?alt=media&token=ef444f30-726d-4abf-a055-0f3f2dfa0c56'},
            {key: 'main-backtrack-theme', lang: '*', type: 'sound', link: 'https://firebasestorage.googleapis.com/v0/b/com-goblindtest.appspot.com/o/idle.mp3?alt=media&token=6c2442d3-720b-4192-a3ff-847a5d9ed89d'},
        ]
    }
};
