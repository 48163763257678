import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    CdkDragDrop,
    moveItemInArray,
    transferArrayItem,
} from '@angular/cdk/drag-drop';
import {Opportunity} from '../../../core/models/opportunity/opportunity';
import {OpportunityService} from '../../../core/services/opportunity/opportunity.service';
import {ToastrService} from 'ngx-toastr';
import {Status} from '../../../core/models/opportunity/status';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../core/services/organization/organization.service';
import {QuoteNOpportunity} from '../../../core/models/opportunity/quoteNOpportunity';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../../core/handler/subscription-handler';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';
import {User} from '@frogconnexion/core-common';

@Component({
    selector: 'app-opportunity-pipeline',
    templateUrl: './opportunity-pipeline.component.html',
    styleUrls: ['./opportunity-pipeline.component.scss'],
})
export class OpportunityPipelineComponent implements OnInit, OnDestroy {
    opList: Opportunity[];
    opNewList: Opportunity[] = [];
    opCrmList: Opportunity[] = [];
    opConfirmedList: Opportunity[] = [];
    opCanceledList: Opportunity[] = [];
    user: User;
    private subscriptions: Subscription[] = [];
    loading: { [id: string]: boolean } = {};

    constructor(
        private opportunityService: OpportunityService,
        private toastr: ToastrService,
        private authService: FirebaseAuthService,
        private organizationService: OrganizationService,
        private router: Router
    ) {
    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {
        this.subscriptions.push(this.authService.currentUserObservable().subscribe(u => {
            this.user = u;
        }));
        this.refreshPipeline();
    }

    splitOpportunityList(opList: Opportunity[]) {
        for (const opportunity of opList) {
            switch (opportunity.status) {
                case Status.DRAFT:
                case Status.NEW:
                    this.opNewList.push(opportunity);
                    break;
                case Status.CREATED_IN_CRM:
                    this.opCrmList.push(opportunity);
                    break;
                case Status.CONFIRMED:
                    this.opConfirmedList.push(opportunity);
                    break;
                case Status.CANCELED:
                    this.opCanceledList.push(opportunity);
                    break;
            }
        }
    }

    dropList() {
        this.opNewList = [];
        this.opCrmList = [];
        this.opConfirmedList = [];
        this.opCanceledList = [];
    }

    refreshPipeline() {
        this.opportunityService.findAll().subscribe(
            os => {
                this.opList = os;
                this.dropList();
                this.splitOpportunityList(this.opList);
            });
    }

    duplicate(op: Opportunity) {
        this.opportunityService.duplicate(op.id).subscribe(() => {
                this.toastr.success('Opportunité dupliquée !');
                this.refreshPipeline();
            },
            () => {
                this.toastr.error('Erreur lors de la duplication !');
            }
        );
    }

    lose(op: Opportunity) {
        this.loading[op.id] = true;
        this.opportunityService.lose(op.id).subscribe(() => {
                this.toastr.success('Opportunité mise à jour, dommage !');
                this.loading[op.id] = false;
                this.refreshPipeline();
            },
            () => {
                this.loading[op.id] = false;
                this.toastr.error('Erreur lors de la suppression !');
            }
        );
    }

    delete(op: Opportunity) {
        this.opportunityService.delete(op.id).subscribe(() => {
                this.toastr.success('Opportunité supprimée !');
                this.refreshPipeline();
            },
            () => {
                this.toastr.error('Erreur lors de la suppression !');
            }
        );
    }

    edit(op: Opportunity) {
        this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard/opportunity/edit`],
            {queryParams: {edit: true, opportunityId: op.id}});
    }

    editDate(o: { id: string, dateOfShow: string, timeOfShow: string }) {
        this.opportunityService.editDate(o.id, {dateOfShow: o.dateOfShow, timeOfShow: o.timeOfShow}).subscribe(() => {
                this.toastr.success('Opportunité mise à jour !');
                this.refreshPipeline();
            },
            () => {
                this.toastr.error('Erreur lors de la mise à jour !');
            }
        );
    }

    confirmOpportunity(q: QuoteNOpportunity) {
        this.loading[q.opportunityId] = true;
        this.opportunityService.confirm(q.opportunityId, q.quote.crmMetadata.crmId).subscribe(() => {
                this.toastr.success('L\'opportunité a été confirmée');
                this.loading[q.opportunityId] = false;
                this.refreshPipeline();
            },
            () => {
                this.loading[q.opportunityId] = false;
                this.toastr.error('Erreur lors de la suppression !');
            });
    }


    deleteQuote(q: QuoteNOpportunity) {
        this.opportunityService.deleteQuote(q.opportunityId, q.quote.id).subscribe(() => {
                this.toastr.success('Devis supprimé !');
                this.refreshPipeline();
            },
            () => {
                this.toastr.error('Erreur lors de la suppression !');
            }
        );
    }

    duplicateQuote(q: QuoteNOpportunity) {
        this.opportunityService.duplicateQuote(q.opportunityId, q.quote.id).subscribe(() => {
                this.toastr.success('Devis Dupliqué !');
                this.refreshPipeline();
            },
            () => {
                this.toastr.error('Erreur lors de la suppression !');
            }
        );
    }

    editQuote(q: QuoteNOpportunity) {
        this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard/quote/edit`],
            {queryParams: {edit: true, opportunityId: q.opportunityId, quoteId: q.quote.id}});
    }
}
