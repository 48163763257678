import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {OrganizationService} from '../../../../core/services/organization/organization.service';
import {OpportunityService} from '../../../../core/services/opportunity/opportunity.service';
import {Observable, of, Subscription} from 'rxjs';
import {mergeMap, startWith} from 'rxjs/operators';
import {CrmOpportunity} from '../../../../core/models/opportunity/crmOpportunity';
import {tap} from 'rxjs/operators';
import {unsubscribe} from '../../../../core/handler/subscription-handler';
import {OrganizationType} from '../../../../core/models/organization/organizationType';
import {OpportunityHeaderUpdate} from './opportunityHeaderUpdate';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';
import {Claims, Organization, RoleClaim, User} from '@frogconnexion/core-common';

@Component({
    selector: 'app-opportunity-header',
    templateUrl: './opportunity-header.component.html',
    styleUrls: ['./opportunity-header.component.scss']
})
export class OpportunityHeaderComponent implements OnInit, OnChanges, OnDestroy {

    opportunityCreationForm = new UntypedFormGroup({
        'company': new UntypedFormControl('', [Validators.required]),
        'date': new UntypedFormControl(undefined, []),
        'hour': new UntypedFormControl('', []),
        'minute': new UntypedFormControl('', []),
        'crmid': new UntypedFormControl('', []),
        'crmident': new UntypedFormControl('', []),
    });

    @Input()
    companyName: string;
    @Input()
    otherFormsValid = true;
    @Input()
    date: string;
    @Input()
    crmId: string;
    @Input()
    crmIdent: string;
    @Input()
    exonerated: boolean;
    @Input()
    time: string;
    @Input()
    organization: Organization;

    @Output()
    saveOpportunity = new EventEmitter<OpportunityHeaderUpdate>();
    filteredOptions: Observable<CrmOpportunity[]>;
    crmOpportunities: CrmOpportunity[] = [];
    private subscriptions: Subscription[] = [];
    @Input()
    user: User;
    internalAdminOrManager: boolean;

    constructor(private organizationService: OrganizationService,
                private userService: FirebaseAuthService,
                private opportunityService: OpportunityService,
                private router: Router) {
    }

    ngOnDestroy(): void {
        unsubscribe(...this.subscriptions);
    }

    ngOnInit(): void {
        this.filteredOptions = this.crmidentForm.valueChanges
            .pipe(
                startWith(''),
                mergeMap(value => this.filter(value as string))
            );
        this.subscriptions.push(this.crmidentForm.valueChanges.subscribe(vc => {
            const opp = this.crmOpportunities.find(o => o.ident === vc);
            if (opp && this.opportunityCreationForm.get('company').value?.trim().length === 0) {
                this.opportunityCreationForm.get('company').setValue(opp.name);
            }
            this.crmidForm.setValue(opp?.id);
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.companyForm.value === '') {
            this.companyForm.setValue(this.companyName || '');
        }
        if (this.date && this.dateForm.value?.toISOString()?.substr(0, 10) !== this.date) {
            this.dateForm.setValue(new Date(this.date));
        }
        const hour = Number(this.time?.split(':')[0]);
        if (this.time && this.hourForm.value !== hour) {
            this.hourForm.setValue(hour);
        }
        const minute = Number(this.time?.split(':')[1]);
        if (this.time && this.minuteForm.value !== minute) {
            this.minuteForm.setValue(minute);
        }
        if (this.crmId && this.crmidForm.value !== this.crmId) {
            this.crmidForm.setValue(this.crmId || '');
        }
        if (this.crmIdent && this.crmidentForm.value !== this.crmIdent) {
            this.crmidentForm.setValue(this.crmIdent);
        }
        if (this.isAdminOrManager() && this.isInternalOrganization() && !this.internalAdminOrManager) {
            this.internalAdminOrManager = true;
            setTimeout(() => {
                this.crmidentForm.setValidators(this.validateFromCrm());
            }, 0);
        } else if (this.internalAdminOrManager) {
            this.internalAdminOrManager = false;
        }
    }

    validateFromCrm(): ValidatorFn {
        return (c): { [key: string]: boolean } => {
            if (!!this.crmOpportunities.find(o => o.ident === c.value)) {
                return null;
            }
            return {
                crmident: false
            };
        };
    }

    get companyForm() {
        return this.opportunityCreationForm.get('company');
    }

    get dateForm() {
        return this.opportunityCreationForm.get('date');
    }

    get hourForm() {
        return this.opportunityCreationForm.get('hour');
    }

    get minuteForm() {
        return this.opportunityCreationForm.get('minute');
    }

    get crmidForm() {
        return this.opportunityCreationForm.get('crmid');
    }

    get crmidentForm() {
        return this.opportunityCreationForm.get('crmident');
    }

    hours() {
        return [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6, 7, 8];
    }

    minutes() {
        return [0, 15, 30, 45];
    }

    onSubmit() {
        const o = new OpportunityHeaderUpdate(
            this.companyForm.value,
            this.crmidForm.value,
            this.crmidentForm.value,
            this.isFromCrm(),
            null,
            parseInt(this.hourForm.value, 10) || undefined, parseInt(this.minuteForm.value, 10) || undefined);
        if (this.dateForm.value) {
            const dateFormValue = this.dateForm.value as Date;
            let m = '' + (dateFormValue.getMonth() + 1);
            if (m.length === 1) {
                m = '0' + m;
            }
            let d = '' + dateFormValue.getDate();
            if (d.length === 1) {
                d = '0' + d;
            }
            o.dateIso = `${dateFormValue.getFullYear()}-${m}-${d}`;
        }
        this.opportunityCreationForm.updateValueAndValidity();
        this.saveOpportunity.next(o);
    }

    onReturn() {
        this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
    }

    isAdminOrManager(): boolean {
        return this.user?.hasAnyRole([
            RoleClaim.parse(Claims.User.FROG_MANAGER_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.FROG_MANAGER_ORG_ROLE_MANAGER, this.organizationService.getOrganizationTagSnapshot())]);
    }

    isInternalOrganization(): boolean {
        return this.organization?.getFrogManagerOrganizationType() === OrganizationType.INTERNAL;
    }

    private filter(value: string): Observable<CrmOpportunity[]> {
        if (!(value?.length > 0) || !this.isAdminOrManager()) {
            return of([]);
        }
        return this.opportunityService.searchCrm(value || '').pipe(tap((opps => this.crmOpportunities = opps)));
    }

    private isFromCrm(): boolean {
        return this.isAdminOrManager() && this.isInternalOrganization() && this.crmidForm.value?.trim().length > 0 &&
            !!this.crmOpportunities.find(o => o.id === this.crmidForm.value);
    }
}
