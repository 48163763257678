import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';

export function getPitchInscription() {
    return 'Et du coup, c\'est là que je vais m\'arrêter, puisque **c\'est à vous**  \n\n' +
        'Ehh oui, je vous invite à tous prendre vos téléphones, et vous connecter sur **www.GoBlindTest.fr**\n\n' +
        'Le pin d\'aujourd\'hui c\'est *[NOM DU FORUM]*\n\n' +
        '**[On les laisse s\'inscrire]**\n\n';
}

export function getPitchGagnant() {
    return 'Pitch \n\n [saucer le gagnant !]\n';
}

export function getPitchTakima() {
    return 'Les stagiaires de Takima ont développé en équipe tout ce module de démo.\n' +
        'Ils ont tout fait : l\'interface, le backend, l\'UX, et c\'est le démonstrateur utilisé tous les jours par l\'équipe de vente de l\'entreprise en question\n' +
        '\nLes technos: Angular, Kotlin, Spring Web Flux, Firebase, Websockets, une extension Chrome.\n\nCôté Cloud :' +
        'Docker, Ansible, Terraform, Kubernetes, ArgoCD, Helm. \n\nCôté Cloud: AWS S3, Route 53, Cloudfront, Lambda, Cert & Secrets Manager';
}


export function getPitchEndGoogleTranslate() {
    return 'On termine avec une de nos sections signatures c\'est la section "Google Translate"\n\n\n\n' +
        'Nous avons pris des chansons en langue étrangère, et on les a traduit de manière dé-gueu-lasse en Français. On les chante telles quelles, il va falloir retrouver le titre et l\'artiste originaux, c\'est PARTI !\n';
}

export function getSongAnswerAdvice() {
    return 'Conseils : Soyez engageant, drôle, faites des blagues, expliquez bien chaque section avec un peu d\'humour\n\n' +
        '[Attendre toutes les réponses]\n\n' +
        '[Si vous voyez qu\'ils galèrent : proposer des indices. On veut qu\'ils trouvent !]\n\n' +
        '[Verrouiller les réponses une fois que c\'est OK. Ne pas attendre trop longtemps après l\'extrait]\n\n' +
        '[les statistiques seront affichées, vous pouvez les commenter librement avant de...]\n\n' +
        '[Révéler la réponse. Ici la bonne réponse sera révélée au joueur.]\n\n\n';
}

export function getJokerAdvice() {
    return 'On va continuer avec la dernière section, mais j\'ai décidé de vous aider un peu\n\n' +
        'On va vous activer les jokers !\n\n' +
        '\n' +
        'C\'est un peu comme qui veut gagner des millions, vous avez un 50-50, un Quitte ou double\n' +
        '\n' +
        '\n' +
        'Le meilleur c\'est celui du milieu, il permet de piquer la réponse d\'une autre équipe ^^\n' +
        'Attention, pensez bien à soumettre d\'abord votre réponse, c\'est obligatoire !\n\n';
}

export function getLeaderboardAdvice() {
    return '\n\n\n\nBravo ! je vois qu\'il y a du niveau ^^\n\n\n\n' +
    'Est-ce qu\'on jette un oeil aux scores ?\n\n\n\n' +
    'Regardons qui a gagné...';
}
