<div *ngIf="!isLogged; else alreadyLoggedIn" class="container">
  <mat-card class="login">
    <mat-card-content>
      <div fxLayout="column"
           fxLayoutAlign="space-between center"
           fxLayoutGap="20px">
        <mat-icon>lock</mat-icon>
        <p>Bienvenue sur DemoTime, le démonstrateur de <b>Frog Connexion</b></p>
        <button mat-raised-button (click)="loginFb()" id="fb">Connecter via Facebook</button>
        <button mat-raised-button (click)="loginGoogle()" id="google">Connecter via Google</button>
        <button mat-raised-button routerLink="../login-email" id="email">Avec ton Email</button>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <a routerLink="/register">Créer un compte</a>
    </mat-card-footer>
  </mat-card>
</div>

<ng-template #alreadyLoggedIn>
  <div class="container">
    <mat-card class="login">
      <mat-card-content>
        <div fxLayout="column"
             fxLayoutAlign="space-between center"
             fxLayoutGap="20px">
          <h2 style="text-align:center">Tu es déjà connecté(e) !</h2>
          <button mat-raised-button routerLink="/dashboard">Je veux jouer!</button>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-template>
