import {DemoSet} from '../../../models/demo/demoSet';
import {DemoStep} from '../../../models/demo/demoStep';
import {WidgetText} from '../../../models/demo/widgets/widgetText';
import {WidgetVideoControl} from '../../../models/demo/widgets/widgetVideoControl';
import {ActionLaunchVideo} from '../../../models/demo/actions/actionLaunchVideo';
import {ActionNext} from '../../../models/demo/actions/actionNext';
import {ActionOnboardPlayers} from '../../../models/demo/actions/actionOnboardPlayers';
import {WidgetDemoPlayerCounter} from '../../../models/demo/widgets/widgetDemoPlayerCounter';
import {ActionLaunchGame} from '../../../models/demo/actions/actionLaunchGame';
import {WidgetQuizGame} from '../../../models/demo/widgets/widgetQuizGame';
import {ActionNextItem} from '../../../models/demo/actions/actionNextItem';
import {ActionEnableJokers} from '../../../models/demo/actions/actionEnableJokers';
import {ActionShowLeaderboard} from '../../../models/demo/actions/actionShowLeaderboard';
import {WidgetLeaderboard} from '../../../models/demo/widgets/widgetLeaderboard';
import {ActionUnsetGame} from '../../../models/demo/actions/actionUnsetGame';
import {ActionTerminateDemo} from '../../../models/demo/actions/actionTerminateDemo';
import {
    getJokerAdvice, getLeaderboardAdvice,
    getPitchEndGoogleTranslate,
    getPitchGagnant,
    getPitchInscription,
    getPitchTakima,
    getSongAnswerAdvice
} from './fr-takima-18-30';

function getDefaultAsset() {
    return {
        'name': '18-30 - Set 2',
        'description': null,
        'tags': ['takima-forum-set-2-18-30'],
        'sets': [{
            'sections': [
                {
                    'type': 'NORMAL',
                    'label': 'Grands classiques',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [
                        {
                            'title': 'Another one bites the dust',
                            'artist': 'Queen',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': [], 'title': ['an other 1 bite the dust', 'an other one bites a dust'], 'lyrics': []},
                            'ageRange': {'start': 25, 'end': 55}
                        },
                        {
                            'title': 'Belinda',
                            'artist': 'Claude François',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': ['Cloclo'], 'title': [], 'lyrics': []},
                            'ageRange': {'start': 30, 'end': 60}
                        },
                        {
                            'title': 'Wannabe',
                            'artist': 'Spice Girls',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {
                                'artist': ['The Spice Girls', 'spice girl', 'space girls'],
                                'title': ['Wanabee', 'wanabee', 'wanna be', 'wanna bee', 'want to be', 'wanabe', 'if you wanna be'],
                                'lyrics': []
                            },
                            'ageRange': {'start': 25, 'end': 55}
                        }
                    ]
                }, {
                    'type': 'NORMAL',
                    'label': 'Mixology',
                    'altLabels': {'title': null, 'artist': null, 'lyrics': null},
                    'songs': [{
                        'title': 'Stayin alive',
                        'artist': 'Bee Gees',
                        'lyrics': null,
                        'type': 'NORMAL',
                        'alt': {
                            'artist': ['BGs', 'BJs', 'Be Jiz', 'Be Geez', 'Bee Jeez', 'Bee Jees'],
                            'title': ['Staying alive', 'Stay in alive'],
                            'lyrics': []
                        },
                        'ageRange': {'start': 25, 'end': 55}
                    },
                        {
                            'title': 'Ma gueule',
                            'artist': 'Johnny Halliday',
                            'lyrics': null,
                            'type': 'NORMAL',
                            'alt': {'artist': ['Johnny'], 'title': ['quoi ma gueule'], 'lyrics': []},
                            'ageRange': {'start': 35, 'end': 60}
                        }
                    ]
                },
            ]
        }]
    };
}

function getPitchStartGrandsClassiques() {
    return 'On attaque avec la section "Grands classiques !", on parle de chansons anthologiques qui n\'ont pas de valeur temporelle.\n\n' +
        'Bien penser à répondre dans l\'appli directement, tout le monde est prêt ? C\'est parti !\n' +
        '\n';
}

function getPitchQueen() {
    return getSongAnswerAdvice() +
        '*Un artiste ultra connu, un film a même été fait en son nom, avec un célèbre concert à AIDS, le grand festival aux US contre le sida...*\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getPitchCloclo() {
    return getSongAnswerAdvice() +
        '*Un artiste qui n\'était pas électricien, français, hyper connu*\n\n\n\n' +
        'C\'est parti pour la suivante, toujours la même section !\n\n';
}

function getSectionMixology() {
    return getSongAnswerAdvice() +
        'On continue avec une section Signature pour nous : C\'est la section Mixologie. Accrochez vous !\n\n' +
        'Ça n\'a rien à voir avec l\'alcool ou les cocktails, au risque de vous décevoir\n\n.' +
        'On a pris des chansons, et on a mis les paroles d\'une autre chanson dessus. Il va falloir entrer le titre et l\'artiste des paroles chantées (et non de la musique)';
}

function getPitchStayingAlive() {
    return getSongAnswerAdvice() +
        '*Mixologie, c\'est pas facile. On avait ici un grand classique du rock, et un grand classique pop.*\n\n' +
        '\n\nC\'est parti pour la suivante';
}

function getPitchAicha() {
    return getSongAnswerAdvice() +
        '*Là encore, deux chansons en français avec un twist particulier !*\n\n';
}


function getPitchStartJokers() {
    return getSongAnswerAdvice() +
        '*Girl band des années 90/2000, elles sont 5, anglaises, et elles ont même fait l\'ouverture des JO récemment à Londres*\n\n\n\n' +
        getJokerAdvice();
}

function getPitchBritney() {
    return '[Fin de la chanson]\n' +
        'L\'artiste pop américaine la plus connue des années 2000. Elle est notamment sortie avec Justin Timberlake, avait promis qu\'elle resterait vierge jusqu\'au mariage, et a fini par se raser la tête...' +
        getLeaderboardAdvice();

}

export function computeTakimaDemoSetForum2() {
    return new DemoSet('takima-forum-set-2-18-30',
        'fr',
        'Jeune (18-30 ans) Set 2',
        getDefaultAsset(),
        [
            new DemoStep(2,
                [new WidgetText(getPitchInscription()), new WidgetVideoControl()],
                [
                    new ActionOnboardPlayers(),
                    new ActionNext()
                ],
                'Faire rejoindre la partie'),
            new DemoStep(3,
                [new WidgetText(getPitchStartGrandsClassiques()), new WidgetDemoPlayerCounter()],
                [
                    new ActionLaunchGame(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/576277053/rendition/1080p/02%20%281080p%29.mp4?loc=external&signature=0a4c6153e6c9ce0eb6c70895165479b0cca84d41237feb2727f6fbb6a58789a4', true),
                    new ActionNext()
                ],
                'Lancer la Partie'),
            new DemoStep(4,
                [new WidgetText(getPitchQueen()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/611773524/rendition/1080p/belinda_alpha%20%281080p%29.mp4?loc=external&signature=3292cb04a56ed403629852f817dc75f8dd3362e3d0a3ec50cef656b24310fc41', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(5,
                [new WidgetText(getPitchCloclo()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/576281463/rendition/1080p/charlie_-_wanabe%20%281080p%29.mp4?loc=external&signature=424db88d0c5461baad13847134968b0d6aae3044ec74f62f6475ed973325c2d6', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(6,
                [new WidgetText(getPitchStartJokers()), new WidgetQuizGame(), new WidgetVideoControl()],
                [
                    new ActionEnableJokers(),
                    new ActionNextItem(),
                    new ActionNext(),
                ],
                'Activer les jokers'),
            new DemoStep(7,
                [new WidgetText(getSectionMixology())],
                [
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/576281193/rendition/720p/03%20%28720p%29.mp4?loc=external&signature=1fa51ad8b22de87ff42ff5d86c0b0f35f93811bd3b2d324bbe673391d2ff994f', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),
            new DemoStep(8,
                [new WidgetText(getPitchStayingAlive()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionNextItem(),
                    new ActionLaunchVideo('https://player.vimeo.com/progressive_redirect/download/691473629/rendition/720p/set_2_-_03_aicha%20%28720p%29.mp4?loc=external&signature=e38a91f19243a04eb3910a9e1eec4eff32a8298621239e666c04eef19d50f15e', true),
                    new ActionNext(),
                ],
                'Chanson Suivante'),

            new DemoStep(9,
                [new WidgetText(getPitchAicha()), new WidgetVideoControl(), new WidgetQuizGame()],
                [
                    new ActionShowLeaderboard(),
                    new ActionNext(),
                ],
                'Montrer les scores'),
            new DemoStep(10,
                [new WidgetText(getPitchGagnant()), new WidgetLeaderboard()],
                [
                    new ActionUnsetGame(),
                    new ActionNext()
                ],
                'Closing'),
            new DemoStep(11,
                [new WidgetText(getPitchTakima())],
                [
                    new ActionTerminateDemo()
                ],
                'Terminer la démo'),
        ]
    );
}
