import {Component, Input} from '@angular/core';
import {BlindtestImportDescriptor, SetOfBlindtestImportDescriptor} from '@frogconnexion/blinding-common';


@Component({
  selector: 'app-blindtest-content',
  templateUrl: './blindtest-content.component.html',
  styleUrls: ['./blindtest-content.component.scss']
})
export class BlindtestContentComponent {

  @Input()
  bt: BlindtestImportDescriptor;

  constructor() {
  }

  getSongCount(set: SetOfBlindtestImportDescriptor): number {
    return set.sections.map(s => s.songs.length).reduce((a, b) => a + b);
  }
}
