import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate} from '@angular/router';

import {OrganizationService} from '../organization.service';
import {LoggerService} from '../../logger/logger.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationGuard implements CanActivate {

  constructor(private _blindingService: OrganizationService,
              private _logger: LoggerService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const organization = route.paramMap.get('organization');
    this._logger.debug(`Organization updated : ${organization}`);
    this._blindingService.updateOrganization(organization);
    return true;
  }
}
