import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AvatarService} from '../service/avatar.service';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-avatar-selector',
  templateUrl: './avatar-selector.component.html',
  styleUrls: ['./avatar-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarSelectorComponent),
      multi: true,
    }
  ]
})
export class AvatarSelectorComponent implements OnInit, ControlValueAccessor, OnDestroy {

  touched: boolean;
  avatars: string[];
  avatar: string;
  @Input() formControl: UntypedFormControl;
  propagateChange: (_: any) => {};
  propagateTouched: (_: any) => {};
  private _avatarsSubscription: Subscription;

  constructor(private _avatarService: AvatarService) {}

  ngOnInit() {
    this._avatarsSubscription = this._avatarService.getAvatars().subscribe(avatars => this.avatars = avatars);
  }

  ngOnDestroy() {
    if (this._avatarsSubscription) {
      this._avatarsSubscription.unsubscribe();
    }
  }

  onAvatarSelected(avatar: string) {

    let uri = `/assets/imgs/${avatar}.jpg`;

    if (!this.touched) {
      this.propagateTouched(this);
    }
    this.touched = true;
    this.avatar = avatar;
    if (this.formControl) {
      this.formControl.setValue(avatar);
    }
    this.propagateChange(uri);
  }

  writeValue(obj: any): void {
    if (obj) {
      this.avatar = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

}
