<div class="container" [ngClass]="{'green-screen': true}">
    <audio id="joinTheGameAudioFeed" #audio autoplay loop [controls]="false"></audio>
    <div class="qrcode-container">
        <img src="../../../../../assets/imgs/qrcode.png"/>
        <h1 *ngIf="gm?.lang === 'fr'">{{playerCount || 0}} équipes</h1>
        <h1 *ngIf="gm?.lang === 'en'">{{playerCount || 0}} teams</h1>
    </div>
    <div class="phone-container">
        <img src="../../../../../assets/imgs/phone-enter-game.png"/>
        <div class="overlay">{{gm?.pin | uppercase}}</div>
    </div>
    <div class="text-container" *ngIf="gm?.lang === 'fr'">
        <h1>Maintenant, c'est à toi :</h1>
        <ul>
            <li>Va sur <span class="bump-me">www.GoBlindTest.fr</span></li>
            <li>Entre le Game PIN : <span class="bump-me">{{gm?.pin | uppercase}}</span></li>
            <li>Entre ton nom de joueur</li>
            <li *ngIf="gm?.servicesEnabled">Sélectionne ton équipe</li>
            <li>Choisis ton avatar</li>
        </ul>
    </div>
    <div class="text-container" *ngIf="gm?.lang === 'en'">
        <h1>Now, it's on you:</h1>
        <ul>
            <li>Browse to <span class="bump-me">www.GoBlindTest.com</span></li>
            <li>Enter your Game PIN : <span class="bump-me">{{gm?.pin | uppercase}}</span></li>
            <li>Enter your Player name</li>
            <li *ngIf="gm?.servicesEnabled">Select your team</li>
            <li>Choose your avatar</li>
        </ul>
    </div>
</div>
