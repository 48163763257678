import {DemoStepAction, DemoStepActionType} from '../demoStepAction';

export class ActionLaunchVideo implements DemoStepAction {
    type: DemoStepActionType = DemoStepActionType.LAUNCH_VIDEO;
    keepAfterEnd: boolean;
    url: string;

    constructor(url: string, keepAfterEnd = false) {
        this.url = url;
        this.keepAfterEnd = keepAfterEnd;
    }
}
