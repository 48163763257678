import {Injectable} from '@angular/core';


import {map, mergeMap, take} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {BlindtestService} from '../blindtest/blindtest.service';
import {GameService} from '../game/game.service';
import {GameControls, Player, PlayerMetadata} from '@frogconnexion/blinding-common';
import {GameMetadata} from '@frogconnexion/blinding-common';
import {LoggerService} from '../logger/logger.service';
import {HttpClient} from '@angular/common/http';
import {ErrorHandler} from '../../handler/error-handler';
import {OrganizationService} from '../organization/organization.service';
import {Database, endAt, limitToLast, list, object, orderByChild, query, ref} from '@angular/fire/database';
import {environment} from '../../../../../environments/environment';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  private _currentGameStateObservable: Observable<GameControls>;
  private _currentGameState: GameControls;
  private _currentGameMetadataObservable: Observable<GameMetadata>;
  private _currentGameMetadata: GameMetadata;
  private organization: string;

  constructor(private database: Database,
              private organizationService: OrganizationService,
              private blindtestService: BlindtestService,
              private gameService: GameService,
              private authService: FirebaseAuthService,
              private logger: LoggerService,
              private http: HttpClient,
              private errorHandler: ErrorHandler) {

    this.organizationService.organizationTag().subscribe(o => {
      this.organization = o;
    });
    // Current Game metadata observable
    this._currentGameMetadataObservable = this.gameService.currentGameMetadata();
    this._currentGameMetadataObservable.subscribe(g => {
      // Replay all game-specific observables
      this._currentGameMetadata = g;
    });
    // Current Game state observable
    this._currentGameStateObservable = this.gameService.currentGameControls();
    this._currentGameStateObservable.subscribe(g => {
      // Replay all game-specific observables
      this._currentGameState = g;
    });
  }

  // Async methods


  // Read-Only Observables

  player(id: string): Observable<Player> {
    return this._currentGameMetadataObservable.pipe(mergeMap(g => {
      if (g == null || !id) {
        this.logger.debug('No current game');
        return of(null);
      }
      return object(ref(this.database, `/${environment.globalNamespace}/games/${this.organization}/state/players/byId/${id}`))
          .pipe(map(c => (id && c && c.snapshot.exists()) ? ({id: c.snapshot.key, ...c.snapshot.val()}) : null));
    }));
  }

  players(): Observable<Player[]> {
    return this._currentGameMetadataObservable.pipe(mergeMap(g => {
      if (g == null) {
        return of(null);
      }
      return list(ref(this.database, `/${environment.globalNamespace}/games/${this.organization}/state/players/byId`))
          .pipe(map(changes => changes ? changes.map(c => ({id: c.snapshot.key, ...c.snapshot.val()})) : null));
    }));
  }

  fetchPlayerPage(creationDate: string, limit: number = 20): Observable<PlayerMetadata[]> {
    let result = list(query(ref(this.database, `/${environment.globalNamespace}/games/${this.organization}/state/players/byId`),
        orderByChild('creationDate'), limitToLast(limit)));
    if (creationDate) {
      result = list(query(ref(this.database, `/${environment.globalNamespace}/games/${this.organization}/state/players/byId`),
          orderByChild('creationDate'), endAt(creationDate), limitToLast(limit)));
    }
    return result.pipe(take(1), map(r => {
      if (!r) {
        return [];
      }
      const players = r.values();
      return Array.from(players).map(qc => qc.snapshot.val()).sort((a, b) => a.creationDate > b.creationDate ? -1 : 1);
    }));
  }

  playerCount(): Observable<number> {
    return this._currentGameMetadataObservable.pipe(mergeMap(g => {
      if (g == null) {
        return of(null);
      }
      return object(ref(this.database, `/${environment.globalNamespace}/games/${this.organization}/state/players/count`))
          .pipe(map(sn => {
            return sn.snapshot.val();
          }));
    }));
  }

}
