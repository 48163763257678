import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from '../../core/services/navigation/navigation.service';
import {DemoService} from '../../core/services/demo/demo.service';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {ValidationDialogComponent} from '../../core/components/dialog/validation-dialog/validation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {OrganizationService} from '../../core/services/organization/organization.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-demo',
    templateUrl: './demo.component.html',
    styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit, OnDestroy {
  private demoSubscription: Subscription;
  private stopDemoDialogSubscription: Subscription;

    constructor(
        private navigationService: NavigationService,
        private organizationService: OrganizationService,
        private dialog: MatDialog,
        private router: Router,
        private demoService: DemoService,
    ) {
    }

    ngOnInit(): void {
      this.demoSubscription = this.demoService.currentDemoMetadata().subscribe(d => {
        if (!d) {
          this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
          return;
        }
        this.navigationService.updateNavHeader('Démo ' + d.company);
      });
    }

  ngOnDestroy(): void {
      unsubscribe(this.demoSubscription, this.stopDemoDialogSubscription);
  }

  stopDemo() {
    unsubscribe(this.stopDemoDialogSubscription);
    const dialogRef = this.dialog.open(ValidationDialogComponent, {
      width: '300px',
      data: {subtitle: 'Vous allez arrêter la démo en cours pour votre équipe. Êtes-vous certain ?'}
    });
    this.stopDemoDialogSubscription = dialogRef.afterClosed().subscribe(activate => {
      if (activate) {
        this.demoService.deactivateDemo().subscribe(() => {
          this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/dashboard`]);
        });
      }
    });
  }
}
