import {Component, Input, OnInit} from '@angular/core';
import {LeaderboardEntry} from '../../../../../../../core/models/leaderboard/leaderboardentry';

@Component({
    selector: 'app-leaderboard-entry',
    templateUrl: './player-widget.component.html',
    styleUrls: ['./player-widget.component.scss']
})
export class PlayerWidgetComponent implements OnInit {

    @Input() entry: LeaderboardEntry;
    @Input() ranking: number;

    constructor() {
    }

    ngOnInit() {
    }

}
