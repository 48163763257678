import {Component, Input, OnDestroy, OnInit} from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  @Input() loading: boolean;
  text: string;
  private _texts: string[] = [
    "Test des réacteurs",
    "Souquage des artémuses",
    "Pose des néons UV",
    "Evacuation du gaz carbonique",
    "Décrassage des bougies",
    "Test du train d'atterrissage",
    "Déploiement des aérofreins"
  ];
  private interval: any;

  constructor() { }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.text = this._getText();
    }, 200);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  private _getText() {
    let i = Math.floor(Math.random() * this._texts.length);
    return this._texts[i];
  }


}
