import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {BlindtestService} from '../blindtest/blindtest.service';
import {GameService} from '../game/game.service';
import {Song} from '@frogconnexion/blinding-common';
import {GameControls} from '@frogconnexion/blinding-common';
import {HttpClient} from '@angular/common/http';
import {OrganizationService} from '../organization/organization.service';

@Injectable({
    providedIn: 'root'
})
export class GamePublicControlsService {

    private currentGameStateObservable: Observable<GameControls>;
    private currentGameState: GameControls;
    private currentSongObservable: Observable<Song>;
    private currentSong: Song;
    private organization: string;

    constructor(private blindingService: OrganizationService,
                private blindtestService: BlindtestService,
                private http: HttpClient,
                private gameService: GameService
    ) {

        this.blindingService.organizationTag().subscribe(o => {
            this.organization = o;
        });
        // Current Game State observable
        this.currentGameStateObservable = this.gameService.currentGameControls();
        this.currentGameStateObservable.subscribe(g => {
            this.currentGameState = g;
        });
        // Current Song observable
        this.currentSongObservable = this.gameService.currentSong();
        this.currentSongObservable.subscribe(s => {
            this.currentSong = s;
        });

    }

    // Observables

    showLeaderboard(): Observable<boolean> {
        return this.currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.showLeaderboard : false)).pipe(distinctUntilChanged());
    }

    enableJokers(): Observable<boolean> {
        return this.currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.enableJokers : false)).pipe(distinctUntilChanged());
    }

    lockPlayers(): Observable<boolean> {
        return this.currentGameStateObservable.pipe(map(gs => gs ? gs.blindtestControl.lockPlayers : false)).pipe(distinctUntilChanged());
    }

}
