<div>
    <div class="quote-header">
        <div class="quote-index-title" (click)="toggleExpand()">
            <ng-container *ngIf="!expanded"> +</ng-container>
            <ng-container *ngIf="expanded"> -</ng-container>
            Devis N° {{index + 1}}
        </div>
        <div class="price">{{q.price | currency:'EUR':'':'3.0-0'}} € HT</div>
        <div class="edit">
            <button mat-button [matMenuTriggerFor]="menuItem" class="edit">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuItem="matMenu">
                <button mat-menu-item (click)="duplicateQuote(q)" [disabled]="!canEdit()">
                    <mat-icon>file_copy</mat-icon>
                    Dupliquer
                </button>
                <button mat-menu-item (click)="editQuote(q)" [disabled]="!canEdit()">
                    <mat-icon>edit</mat-icon>
                    Modifier
                </button>
                <button mat-menu-item (click)="deleteQuote(q)" [disabled]="!canEdit()">
                    <mat-icon>delete</mat-icon>
                    Supprimer
                </button>
            </mat-menu>
        </div>
    </div>
    <ng-container *ngIf="expanded">
        <div class="bundle">
            <span class="bundle-title">Animation :</span>
            <br/>{{xpName}}
            <br/>
            <span class="bundle-title">Formule :</span>
            <br/>{{bundleName}}
        </div>

        <br/>
        <div *ngFor="let article of q.articles" class="article">
            <div>{{article.ref}}</div>
            <div>{{article.name}}</div>
        </div>
        <br/>
    </ng-container>
    <hr *ngIf="!isLast"/>
</div>
