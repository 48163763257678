import {Component, OnDestroy, OnInit} from '@angular/core';
import {BlindtestService} from '../../core/services/blindtest/blindtest.service';
import {GameService} from '../../core/services/game/game.service';
import {NavigationService} from '../../core/services/navigation/navigation.service';
import {OrganizationService} from '../../core/services/organization/organization.service';

import {UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {DemoService} from '../../core/services/demo/demo.service';
import {DemoContent} from '../../core/models/demo/demoContent';
import {DemoCreationDescriptor} from '../../core/models/demo/dtos/demoCreationDescriptor';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {unsubscribe} from '../../core/handler/subscription-handler';
import {FirebaseAuthService} from '@frogconnexion/angular-auth';
import {Claims, Organization, RoleClaim, User} from '@frogconnexion/core-common';


/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss']
})
export class SetupComponent implements OnInit, OnDestroy {

    validationMessages = {
        'company': [
            {type: 'required', message: 'Nom d\'entreprise requis.'},
            {type: 'minlength', message: 'Merci d\'entrer un nom d\'entreprise valide (plus de 2 lettres)'}
        ],
        'lang': [
            {type: 'required', message: 'Langue de la démo requise.'},
        ],
        'experience': [
            {type: 'required', message: 'Choix de l\'expérience obligatoire.'},
        ]
    };

    dc: DemoContent;

    setupForm = new UntypedFormGroup({
        'company': new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
        'lang': new UntypedFormControl('fr', [Validators.required]),
        'experience': new UntypedFormControl('', [Validators.required])
    });

    matcher = new MyErrorStateMatcher();
    private demoSubscription: Subscription;
    private userSubscription: Subscription;
    private user: User;
    pin: string;
    pinAlreadyTaken: boolean;
    private org: Organization;
    private orgSubscription: Subscription;

    constructor(private authService: FirebaseAuthService,
                private organizationService: OrganizationService,
                private blindtestService: BlindtestService,
                private gameService: GameService,
                private demoService: DemoService,
                private router: Router,
                private navigationService: NavigationService) {
    }

    ngOnInit() {
        this.userSubscription = this.authService.currentUserObservable().subscribe(u => {
            this.user = u;
        });
        this.navigationService.updateNavHeader('Créer une Démo');
        this.demoService.getAvailableDemoContentSingle().subscribe(dc => {
            this.dc = dc;
        });
        this.demoSubscription = this.demoService.currentDemoMetadata().subscribe(demo => {
            if (demo) {
                this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/demo`]);
            }
        });
        this.orgSubscription = this.organizationService.organization().subscribe(org => {
            this.org = org;
            if (org) {
                this.pin = org.organization;
            }
        });
    }

    ngOnDestroy() {
        unsubscribe(this.demoSubscription, this.userSubscription, this.orgSubscription);
    }

    get company() {
        return this.setupForm.get('company');
    }

    get lang() {
        return this.setupForm.get('lang');
    }

    get experience() {
        return this.setupForm.get('experience');
    }

    get experiences() {
        return this.dc?.experiences || [];
    }

    onSubmit() {
        const xp = this.dc.experiences.find(e => e.id === this.experience.value);
        const defaultAssets = xp.demoSets.map(ds => ds.defaultAsset);
        this.demoService.activateDemo(new DemoCreationDescriptor(this.company.value, this.lang.value, this.pin, this.experience.value,
            xp.preGameSteps[this.lang.value].length, defaultAssets))
            .subscribe(() => {
               this.router.navigate([`/org/${this.organizationService.getOrganizationTagSnapshot()}/demo`]);
            });
    }

    getLabel(labels: { [p: string]: string }) {
        return labels[this.lang.value];
    }

    shouldShowGoBlindtestPin() {
        return ((this.setupForm.get('experience').value || '') as string).startsWith('le-blindtest') && this.user?.hasAnyRole(
            [
                RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_OPERATOR),
            RoleClaim.parse(Claims.User.BLINDING_GLOBAL_ROLE_ADMIN),
            RoleClaim.parse(Claims.User.BLINDING_ORG_ROLE_MANAGER, this.organizationService.getOrganizationTagSnapshot())
            ]);
    }

    checkPinAvailable(pin: string) {
        this.organizationService.isPinAvailable(pin).subscribe(available => {
            this.pinAlreadyTaken = !available;
        });
    }
}
