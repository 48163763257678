<div class="container">
    <mat-card class="content">
        <mat-card-content>
            <div *ngFor="let w of (currentStep?.widgets || [])">
                <app-widget-text [hidden]="!showPitch" (onToggleHide)="toggleShowPitch()" [ctx]="ctx" [widget]="w"
                                 *ngIf="isTextWidget(w.type)"></app-widget-text>
                <app-widget-demo-set-picker [ctx]="ctx" [widget]="w" (changed)="updateDemoSetWidgetData($event)"
                                            *ngIf="isDemoSetPickerWidget(w.type)"></app-widget-demo-set-picker>
                <app-widget-quiz-game [ctx]="ctx" [widget]="w"
                                      (changed)="updateQuizGameWidgetData($event)"
                                      *ngIf="isQuizGameWidget(w.type)"></app-widget-quiz-game>
                <app-widget-demo-player-counter [ctx]="ctx" [widget]="w"
                                                *ngIf="isDemoPlayerCounterWidget(w.type)"></app-widget-demo-player-counter>
                <app-widget-leaderboard [ctx]="ctx" [widget]="w"
                                        *ngIf="isLeaderboardWidget(w.type)" [enableServices]="currentGameMetadata?.servicesEnabled"></app-widget-leaderboard>
                <app-widget-video-controls *ngIf="isVideoControlWidget(w.type)"></app-widget-video-controls>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <hr/>
            <button *ngIf="(currentStep?.actions.length > 0)" [disabled]="!isActionEnabled()" mat-raised-button
                    color="primary" (click)="processActions()">{{currentStep?.actionLabel}}</button>
        </mat-card-actions>
    </mat-card>
</div>
